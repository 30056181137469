import { PilotOperator, UpsertPilotOperatorRequestPayload } from "./pilot-operator.model";

export interface PilotOperatorResponseBody {
    name: string;
    number: string;
    favorite: boolean;
}
export function transformPilotOperatorResponseBodyToPilotOperator(response: PilotOperatorResponseBody): PilotOperator {
    return {
        name: response.name,
        number: response.number,
        isFavorite: response.favorite,
    };
}

export function transformPilotOperatorToPilotOperatorUpsertPilotOperatorRequestPayload(
    response: PilotOperator
): UpsertPilotOperatorRequestPayload {
    return {
        name: response.name,
        number: response.number,
    };
}
