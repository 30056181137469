import { ChangeDetectionStrategy, Component, inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalComponentStore, MILLISECONDS_IN_MINUTE } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { Store } from "@ngxs/store";
import { map, merge } from "rxjs";
import { AuthApiService } from "../../../../services/auth-api/auth-api.service";
import { IntroWalkthroughService } from "../../../../services/intro-walkthrough/intro-walkthrough.service";
import { DroneTowerMobileState } from "../../../../state/drone-tower-mobile.state";
import { DroneTowerFeatures } from "../../../../state/drone-tower-mobile.state.model";
import { REDIRECT_PARAM, REDIRECT_REGISTRATION, REDIRECT_RESET_PASSWORD } from "../../../../utils/defaults";
import { PANSA_UTM_REGISTER_TOKEN } from "../../login.tokens";
import { ErrorHandlingService } from "./../../../../services/error-handling-service/error-handling-service";
import { LoginFormModel } from "./login.model";

interface LoginComponentState {
    isTooltipOpen: boolean;
    retryCount: number;
    isTimeout: boolean;
}

const TIMEOUT = 1;
const MAX_RETRY_COUNT = 3;

@Component({
    selector: "drone-tower-mobile-lib-login",
    templateUrl: "./login.component.html",
    styleUrls: ["../../shared/onboarding-layout.scss", "./login.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class LoginComponent implements OnInit {
    private readonly store = inject(Store);
    private readonly localStore = inject(LocalComponentStore<LoginComponentState>);
    private readonly loginService = inject(AuthApiService);
    private readonly introWalkthroughService = inject(IntroWalkthroughService);
    private readonly router = inject(Router);
    private readonly route = inject(ActivatedRoute);
    private readonly translocoService = inject(TranslocoService);
    private readonly errorHandlingService = inject(ErrorHandlingService);

    protected readonly pansaUtmRegisterUrl = inject(PANSA_UTM_REGISTER_TOKEN);
    protected readonly loginForm: FormGroup<LoginFormModel> = new FormGroup({
        email: new FormControl<string>("", { validators: [Validators.required, Validators.email], nonNullable: true }),
        password: new FormControl<string>("", { validators: [Validators.required], nonNullable: true }),
    });
    private readonly isFormUnchanged$ = this.loginForm.valueChanges.pipe(map(() => false));
    protected readonly MAX_RETRY_COUNT = MAX_RETRY_COUNT;
    protected readonly REDIRECT_REGISTRATION = REDIRECT_REGISTRATION;
    protected readonly REDIRECT_RESET_PASSWORD = REDIRECT_RESET_PASSWORD;
    protected readonly isUnauthorized$ = merge(this.loginService.isUnauthorized$, this.isFormUnchanged$);
    protected readonly isLoading$ = this.loginService.isLoading$;
    protected readonly retryCount$ = this.localStore.selectByKey("retryCount");
    protected readonly isTooltipOpen$ = this.localStore.selectByKey("isTooltipOpen");
    protected readonly isEnrollmentFeatureAvailable$ = this.store.select(
        DroneTowerMobileState.isFeatureAvailable(DroneTowerFeatures.Enrollment)
    );
    protected readonly isResetPasswordFeatureAvailable$ = this.store.select(
        DroneTowerMobileState.isFeatureAvailable(DroneTowerFeatures.ResetPassword)
    );
    protected readonly redirectParam$ = this.route.queryParams.pipe(map((queryParam) => queryParam[REDIRECT_PARAM]));

    constructor() {
        this.localStore.setState({
            isTooltipOpen: false,
            retryCount: 0,
            isTimeout: false,
        });
    }

    protected submit(): void {
        if (this.loginForm.invalid) {
            this.loginForm.markAllAsTouched();

            return;
        }
        const retryCount = this.localStore.selectSnapshotByKey("retryCount");
        const isTimeout = this.localStore.selectSnapshotByKey("isTimeout");
        if (retryCount === MAX_RETRY_COUNT) {
            const errorMessage = this.translocoService.translate("droneTowerMobileLibLogin.tooManyRetriesMessage", { timeout: TIMEOUT });
            this.errorHandlingService.displayMessage({
                errorMessage,
            });

            if (!isTimeout) {
                this.localStore.patchState({
                    isTimeout: true,
                });
                setTimeout(() => {
                    this.localStore.patchState({
                        retryCount: 0,
                        isTimeout: false,
                    });
                }, TIMEOUT * MILLISECONDS_IN_MINUTE);
            }
        } else {
            this.loginService.login(this.loginForm.getRawValue());

            this.localStore.patchState((state) => ({
                retryCount: ++state.retryCount,
            }));
        }
    }

    public ngOnInit() {
        this.introWalkthroughService.getIsIntroDone().then((isIntroDone) => {
            if (!isIntroDone) {
                this.router.navigate(["/intro"], { replaceUrl: true });
            }
        });
    }

    protected toggleTooltip() {
        this.localStore.patchState(({ isTooltipOpen }) => ({ isTooltipOpen: !isTooltipOpen }));
    }
}
