import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IconDirective } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { LetModule } from "@ngrx/component";

interface GeozoneDateComponentState {
    date: Date;
    validDate: boolean;
}

@Component({
    selector: "drone-tower-mobile-lib-geozone-date",
    standalone: true,
    imports: [CommonModule, SharedI18nModule, IconDirective, LetModule],
    templateUrl: "./geozone-date.component.html",
    styleUrls: ["./geozone-date.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class GeozoneDateComponent {
    @Input() public set date(value: Date) {
        if (this.isValidDate(value)) {
            this.localStore.patchState({ validDate: true });
        }
        this.localStore.patchState({ date: value });
    }
    protected readonly date$ = this.localStore.selectByKey("date");
    protected readonly validDate$ = this.localStore.selectByKey("validDate");

    constructor(private readonly localStore: LocalComponentStore<GeozoneDateComponentState>) {
        this.localStore.setState({ date: new Date(), validDate: false });
    }

    private isValidDate(date: Date) {
        return new Date(date).toString() !== "Invalid Date";
    }
}
