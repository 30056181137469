import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import {
    ConfirmPasswordRequestPayload,
    ResetPasswordRequestPayload,
    ResetPasswordResponseBody,
    ValidateEmailRequestPayload,
} from "./reset-password.model";
import { RESET_PASSWORD_ENDPOINTS } from "./reset-password.tokens";

@Injectable({
    providedIn: "root",
})
export class ResetPasswordService {
    private readonly resetPasswordToken = inject(RESET_PASSWORD_ENDPOINTS);
    private readonly http = inject(HttpClient);

    public resetPassword(resetPasswordRequestPayload: ResetPasswordRequestPayload) {
        return this.http.post<ResetPasswordResponseBody>(this.resetPasswordToken.resetPassword, resetPasswordRequestPayload);
    }

    public validateEmail(id: string, validateEmailRequestPayload: ValidateEmailRequestPayload) {
        return this.http.post(StringUtils.replaceInTemplate(this.resetPasswordToken.validateEmail, { id }), validateEmailRequestPayload);
    }

    public confirmPassword(id: string, confirmPasswordRequestPayload: ConfirmPasswordRequestPayload) {
        return this.http.post(
            StringUtils.replaceInTemplate(this.resetPasswordToken.confirmPassword, { id }),
            confirmPasswordRequestPayload
        );
    }
}
