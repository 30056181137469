import { inject, Injectable } from "@angular/core";
import { DRONE_TOWER_DYNAMIC_CONFIG_TOKEN } from "./config.tokens";

export interface DroneTowerDynamicConfig {
    version: string;
    environment: string;
    apiDomain: string;
}

@Injectable({
    providedIn: "root",
})
export class ConfigService {
    private readonly dynamicConfig = inject(DRONE_TOWER_DYNAMIC_CONFIG_TOKEN);

    public get isProduction() {
        return this.dynamicConfig.environment === "prod";
    }

    public get config() {
        return this.dynamicConfig;
    }
}
