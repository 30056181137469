import { Directive, EventEmitter, Inject, Input, Output } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { BaseIconOptions, Icon } from "leaflet";
import { RotatedMarker } from "leaflet-marker-rotation";
import { SharedMapUtils } from "../../../shared/index";
import { Position } from "../../../shared/services/geolocation.service";
import { LEAFLET_MAP_PROVIDER, LeafletMapProvider } from "../../leaflet-map.tokens";

@UntilDestroy()
@Directive({ selector: "dtm-map-leaflet-marker[position]" })
export class LeafletMarkerDirective {
    @Input() public iconOptions: BaseIconOptions = {};
    @Input() public isDraggable = false;
    @Input() public isInteractive = true;
    @Input() public set position(value: Position | undefined) {
        if (!value) {
            return;
        }

        this.createOrUpdateMarker(value);
    }
    @Input() public set rotation(value: number | undefined) {
        if (!this.marker || value === undefined) {
            return;
        }

        this.marker.setRotationAngle(value);
    }

    @Output() private positionUpdate: EventEmitter<Position> = new EventEmitter<Position>();
    @Output() private initialize: EventEmitter<RotatedMarker> = new EventEmitter<RotatedMarker>();

    private marker!: RotatedMarker;

    constructor(@Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider) {}

    public setMarkerZIndexOffset(zIndexOffset: number): void {
        if (!this.marker) {
            return;
        }

        this.marker.setZIndexOffset(zIndexOffset);
    }

    private async createOrUpdateMarker(position: Position): Promise<void> {
        if (this.marker) {
            const markerPosition = this.getMarkerPosition();

            if (SharedMapUtils.arePositionsEqual(markerPosition, position)) {
                return;
            }

            this.marker.setLatLng([position.latitude, position.longitude]);
            this.positionUpdate.emit(this.getMarkerPosition());

            return;
        }

        const map = await this.mapProvider.getMap();
        const icon = new Icon(this.iconOptions);

        this.marker = new RotatedMarker([position.latitude, position.longitude], {
            icon,
            draggable: this.isDraggable,
            interactive: this.isInteractive,
        });
        this.marker.addTo(map);
        this.initialize.emit(this.marker);

        if (this.isDraggable) {
            this.marker.on("dragend", () => this.positionUpdate.emit(this.getMarkerPosition()));
        }
    }

    private getMarkerPosition(): Position {
        const markerPosition = this.marker.getLatLng();

        return { latitude: markerPosition.lat, longitude: markerPosition.lng };
    }
}
