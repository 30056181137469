import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { Event, NavigationEnd, Router } from "@angular/router";
import {
    AuthActions,
    ConfigService,
    DroneTowerFeatures,
    DroneTowerMobileState,
    LanguageStorageService,
} from "@dtm-frontend/drone-tower-mobile-lib";
import { UIActions, UIState } from "@dtm-frontend/shared/ui";
import { LANGUAGE_CONFIGURATION, LanguageDefinition, getDefaultLang } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { MenuController } from "@ionic/angular";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { Observable, filter, map } from "rxjs";
import { EasterEggActions } from "../easter-egg/easter-egg.component";

interface MainMenuComponentState {
    language: LanguageDefinition;
}

@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-main-menu",
    templateUrl: "./main-menu.component.html",
    styleUrls: ["./main-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MainMenuComponent {
    private readonly router = inject(Router);
    private readonly store = inject(Store);
    private readonly menu = inject(MenuController);
    private readonly languageStorage = inject(LanguageStorageService);
    private readonly configService = inject(ConfigService);

    protected readonly isMenuButtonVisible$: Observable<boolean> = this.router.events.pipe(
        filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url.includes("/map") || event.urlAfterRedirects.includes("/map"))
    );
    protected readonly defaultLanguage =
        LANGUAGE_CONFIGURATION.languageDefinitions.find((languageDefinition) => languageDefinition.alpha2Code === getDefaultLang()) ??
        LANGUAGE_CONFIGURATION.languageDefinitions[1];
    protected readonly language$ = this.localStore.selectByKey("language");
    protected readonly activeLanguage$ = this.store.select(UIState.activeLanguage);
    protected readonly isProduction = this.configService.isProduction;
    protected readonly isOperatorsFeatureAvailable$ = this.store.select(
        DroneTowerMobileState.isFeatureAvailable(DroneTowerFeatures.Operators)
    );

    constructor(private readonly localStore: LocalComponentStore<MainMenuComponentState>) {
        this.localStore.setState({
            language: this.defaultLanguage,
        });

        this.activeLanguage$.pipe(untilDestroyed(this)).subscribe((languageCode) => {
            const language = this.findLanguageByAlpha2Code(languageCode);
            if (!language) {
                return;
            }
            this.localStore.patchState({
                language,
            });
            this.languageStorage.setLanguage(language.alpha2Code);
        });
        this.languageStorage.getLanguage().then((languageCode) => {
            if (!languageCode) {
                return;
            }
            this.store.dispatch(new UIActions.SetActiveLanguage(languageCode));
        });
    }

    protected logout() {
        this.store.dispatch(AuthActions.Logout);
        this.menu.close();
    }

    protected toggleLanguage() {
        const language = this.localStore.selectSnapshotByKey("language");

        if (language.alpha2Code === this.defaultLanguage.alpha2Code) {
            this.store.dispatch(new UIActions.SetActiveLanguage("en"));

            return;
        }
        this.store.dispatch(new UIActions.SetActiveLanguage(this.defaultLanguage.alpha2Code));
    }

    private findLanguageByAlpha2Code(alpha2code: string) {
        return LANGUAGE_CONFIGURATION.languageDefinitions.find((languageDefinition) => languageDefinition.alpha2Code === alpha2code);
    }

    protected countLogoTap() {
        this.store.dispatch(EasterEggActions.CountLogoTap);
    }
    protected resetLogoTaps() {
        this.store.dispatch(EasterEggActions.ResetLogoTaps);
    }
}
