import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";

@NgModule({
    imports: [CommonModule, SharedI18nModule],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "droneTowerMobileLib",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [],
})
export class DroneTowerMobileLibModule {}
