<div class="container" *ngrxLet="{ isProcessing: isProcessing$ } as vm">
    <form [formGroup]="confirmNewPassword" class="form-container">
        <h2 class="form-container-item title">
            {{ "droneTowerMobileLibLogin.resetPasswordConfirm.title" | transloco }}
        </h2>
        <dtm-ui-input-field class="form-container-item form-container-item-password" [isClearable]="false">
            <label>{{ "droneTowerMobileLibLogin.passwordLabel" | transloco }}</label>
            <input formControlName="password" matInput type="password" autocomplete="new-password" #password />
            <dtm-ui-password-visibility-switch [inputParent]="password" class="field-suffix"> </dtm-ui-password-visibility-switch>
            <div class="field-error" *dtmUiFieldHasError="confirmNewPassword.controls.password; name: 'requiredTouched'">
                {{ "droneTowerMobileLibLogin.requiredErrorMessage" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="confirmNewPassword.controls.password; name: 'pattern'">
                {{ "droneTowerMobileLibLogin.passwordHint" | transloco }}
            </div>
        </dtm-ui-input-field>

        <dtm-ui-input-field class="form-container-item" [isClearable]="false">
            <label>{{ "droneTowerMobileLibLogin.confirmPasswordLabel" | transloco }}</label>
            <input formControlName="confirmPassword" matInput type="password" autocomplete="new-password" #confirmPassword />
            <dtm-ui-password-visibility-switch [inputParent]="confirmPassword" class="field-suffix"> </dtm-ui-password-visibility-switch>
            <div class="field-error" *dtmUiFieldHasError="confirmNewPassword.controls.confirmPassword; name: 'requiredTouched'">
                {{ "droneTowerMobileLibLogin.requiredErrorMessage" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="confirmNewPassword.controls.confirmPassword; name: 'mustMatch'">
                {{ "droneTowerMobileLibLogin.mustMatchErrorMessage" | transloco }}
            </div>
        </dtm-ui-input-field>
        <div class="form-container-item">
            <button type="button" class="button-primary" [disabled]="vm.isProcessing" (click)="onSubmit()">
                <mat-spinner *ngIf="vm.isProcessing" [diameter]="20" color="accent"></mat-spinner>
                <ng-container *ngIf="!vm.isProcessing">
                    {{ "droneTowerMobileLibLogin.resetPasswordConfirm.changePassword" | transloco }}
                </ng-container>
            </button>
        </div>
        <p class="form-container-item redirect">
            <a routerLink="/login">
                {{ "droneTowerMobileLibLogin.resetPasswordConfirm.backToLogin" | transloco }}
            </a>
        </p>
    </form>
</div>
