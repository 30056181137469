import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { RouterLink } from "@angular/router";
import { Clipboard } from "@capacitor/clipboard";
import { IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { DmsCoordinatesModule, DmsCoordinatesUtils, GeographicCoordinatesType } from "@dtm-frontend/shared/ui/dms-coordinates";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Platform } from "@ionic/angular";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetModule, PushModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { FlightStatus, ZoneType } from "../../models/flight-conditions.model";
import { AlertPipe } from "../../pipes/alert.pipe";
import { GeoZonePipe } from "../../pipes/geo-zone.pipe";
import { ErrorHandlingService } from "../../services/error-handling-service/error-handling-service";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { DroneTowerMobileActions } from "../../state/drone-tower-mobile.actions";
import { DroneTowerMobileState } from "../../state/drone-tower-mobile.state";
import { DroneTowerFeatures } from "../../state/drone-tower-mobile.state.model";
import { AirspaceDetailsComponent } from "../airspace-details/airspace-details.component";
import { GeozoneDateComponent } from "../geozone-date/geozone-date.component";
import { H24Component } from "../h24/h24.component";
import { LocationInfoMissionsComponent } from "../location-info-missions/location-info-missions.component";
import { LocationInfoZonesComponent } from "../location-info-zones/location-info-zones.component";

interface LocationInfoComponentState {
    isLoading: boolean;
}

@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-lib-location-info",
    standalone: true,
    imports: [
        CommonModule,
        HeaderComponent,
        SharedI18nModule,
        IconDirective,
        PushModule,
        LetModule,
        DmsCoordinatesModule,
        SharedUiModule,
        AirspaceDetailsComponent,
        RouterLink,
        AlertPipe,
        GeozoneDateComponent,
        GeoZonePipe,
        H24Component,
        LocationInfoMissionsComponent,
        LocationInfoZonesComponent,
    ],
    templateUrl: "./location-info.component.html",
    styleUrls: ["./location-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class LocationInfoComponent {
    private readonly store = inject(Store);
    private readonly toastrService = inject(ToastrService);
    private readonly translocoService = inject(TranslocoService);
    private readonly errorHandlingService = inject(ErrorHandlingService);
    private readonly platform = inject(Platform);

    protected readonly ZoneType = ZoneType;
    protected readonly FlightStatus = FlightStatus;
    protected readonly isBvlosMissionsFeatureAvailable$ = this.store.select(
        DroneTowerMobileState.isFeatureAvailable(DroneTowerFeatures.BvlosMissions)
    );
    protected readonly isInactiveZonesFeatureAvailable$ = this.store.select(
        DroneTowerMobileState.isFeatureAvailable(DroneTowerFeatures.InactiveZones)
    );
    protected readonly flightConditionsStatusDetails$ = this.store.select(DroneTowerMobileState.flightConditionStatusDetails);
    protected readonly markerPosition$ = this.store.select(DroneTowerMobileState.markerPosition);
    protected readonly isLoading$ = this.localStore.selectByKey("isLoading");

    constructor(private readonly localStore: LocalComponentStore<LocationInfoComponentState>) {
        this.localStore.setState({ isLoading: true });
        this.store
            .dispatch(new DroneTowerMobileActions.GetFlightConditionsStatusDetails())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.localStore.patchState({ isLoading: false });
                const httpErrorResponse = this.store.selectSnapshot(DroneTowerMobileState.flightConditionStatusDetailsError);
                if (httpErrorResponse) {
                    const errorMessage = this.translocoService.translate("droneTowerMobileLib.locationInfo.locationErrorMessage");
                    this.errorHandlingService.displayMessage({
                        httpErrorResponse,
                        errorMessage,
                    });
                }
            });
    }

    protected async copyToClipboard() {
        const markerPosition = this.store.selectSnapshot(DroneTowerMobileState.markerPosition);
        if (!markerPosition) {
            return;
        }

        await Clipboard.write({
            string: [
                DmsCoordinatesUtils.convertDmsWithDirectionToString(
                    DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinatesWithDirection(
                        markerPosition.latitude,
                        GeographicCoordinatesType.Latitude
                    )
                ),
                DmsCoordinatesUtils.convertDmsWithDirectionToString(
                    DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinatesWithDirection(
                        markerPosition.longitude,
                        GeographicCoordinatesType.Longitude
                    )
                ),
            ].join(),
        });

        if (!this.platform.is("android")) {
            const message = this.translocoService.translate("droneTowerMobileLib.locationInfo.coordsCopied");
            this.toastrService.info(message);
        }
    }
}
