import { InjectionToken } from "@angular/core";

export interface CheckinsEndpoints {
    getActive: string;
    createCheckIn: string;
    createMissionCheckIn: string;
    acknowledgeCheckInModification: string;
    acknowledgeLandNowCheckIn: string;
    acknowledgeCheckInAccepted: string;
    acknowledgeCheckInRejected: string;
    finishCheckIn: string;
    checkInLostControl: string;
    getCheckinMissions: string;
}

export const CHECKINS_ENDPOINTS_API_TOKENS = new InjectionToken<CheckinsEndpoints>("Checkins endpoints token");
