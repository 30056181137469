import { Type } from "@angular/core";
import {
    CapabilitiesEndpoints,
    CheckinsEndpoints,
    CheckinsHistoryEndpoints,
    DroneTowerDynamicConfig,
    EnrollmentEndpoints,
    FirebaseConfig,
    FlightConditionsEndpoints,
    PilotProfileEndpoints,
    ResetPasswordEndpoints,
} from "@dtm-frontend/drone-tower-mobile-lib";
import { GeolocationService } from "@dtm-frontend/shared/map";
import { LeafletMapConfig } from "@dtm-frontend/shared/map/leaflet";
import { TranslationEndpoints } from "@dtm-frontend/shared/ui/i18n";

export enum BuildType {
    Web = "web",
    Mobile = "mobile",
}

export interface DroneTowerMobileStaticConfiguration {
    buildType: BuildType;
    geolocationService: Type<GeolocationService>;
}

export interface DroneTowerMobileEnvironment extends DroneTowerMobileStaticConfiguration {
    production: boolean;
    version: string;
    name: string;
    authenticationEndpoint: string;
    enrollmentEndpoints: EnrollmentEndpoints;
    resetPasswordEndpoints: ResetPasswordEndpoints;
    pansaUtmRegisterUrl: string;
    pansaUtmLoginUrl: string;
    websocketEndpoint: string;
    alertTopicName: string;
    leafletMapConfig: LeafletMapConfig;
    azureMapsSubscriptionKey: string;
    flightConditionsEndpoints: FlightConditionsEndpoints;
    translationsEndpoint: TranslationEndpoints;
    checkinsEndpoints: CheckinsEndpoints;
    capabilitiesEndpoints: CapabilitiesEndpoints;
    pilotProfileEndpoints: PilotProfileEndpoints;
    checkinsHistoryEndpoints: CheckinsHistoryEndpoints;
    firebaseConfig: FirebaseConfig;
    dynamicConfig: DroneTowerDynamicConfig;
}
