import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthActions } from "@dtm-frontend/drone-tower-mobile-lib";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionDispatched } from "@ngxs/store";
import { tap } from "rxjs/operators";

@UntilDestroy()
@Injectable({
    providedIn: "root",
})
export class ActionsHandler {
    constructor(private readonly actions$: Actions, private readonly router: Router) {
        this.handleLogin();
        this.handleLogout();
    }

    private handleLogin() {
        this.actions$
            .pipe(
                ofActionDispatched(AuthActions.Login),
                tap(() => this.router.navigate(["/map"], { replaceUrl: true })),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private handleLogout() {
        this.actions$
            .pipe(
                ofActionDispatched(AuthActions.Logout),
                tap(() => this.router.navigate(["/login"], { replaceUrl: true })),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
