import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule, TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { TranslocoModule } from "@jsverse/transloco";
import { PushModule } from "@ngrx/component";

@Component({
    standalone: true,
    imports: [CommonModule, MatDialogModule, SharedI18nModule, SharedUiModule, TranslocoModule, PushModule],
    templateUrl: "./app-not-responding-dialog.component.html",
    styleUrls: ["../../../styles/simple-check-in-dialog.scss", "./app-not-responding-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppNotRespondingDialogComponent {
    private readonly translationHelperService = inject(TranslationHelperService);
    protected readonly translationsReady$ = this.translationHelperService.waitForTranslation(
        "droneTowerMobile.appNotResponding.headerLabel"
    );

    protected refreshApp() {
        location.reload();
    }
}
