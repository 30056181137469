import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class SchedulerService {
    private scheduledFunctions: Map<string, ReturnType<typeof setTimeout>> = new Map();

    public scheduleFunction(name: string, targetTime: Date, callback: () => void) {
        this.removeScheduledFunction(name);

        const now = new Date();
        const timeUntilTargetTime = targetTime.getTime() - now.getTime();

        const scheduledFunction = setTimeout(() => {
            callback();
            this.scheduledFunctions.delete(name);
        }, timeUntilTargetTime);

        this.scheduledFunctions.set(name, scheduledFunction);
    }

    public removeScheduledFunction(name: string) {
        const scheduledFunction = this.scheduledFunctions.get(name);
        if (scheduledFunction) {
            clearTimeout(scheduledFunction);
            this.scheduledFunctions.delete(name);
        }
    }
}
