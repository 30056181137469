import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";
import { Page } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { LatLng } from "leaflet";
import { ToastrService } from "ngx-toastr";
import { first, switchMap } from "rxjs";
import { CheckinsHistoryFilters, CheckinsHistoryUserNoteChange, Coordinates } from "../../models/checkins.model";
import { DroneTowerMobileActions } from "../../state/drone-tower-mobile.actions";
import { DroneTowerMobileState } from "../../state/drone-tower-mobile.state";

interface CheckinsHistoryComponentState {
    filters: CheckinsHistoryFilters | null;
}

@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-lib-checkins-history",
    templateUrl: "./checkins-history.component.html",
    styleUrls: ["./checkins-history.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ToastrService, LocalComponentStore],
})
export class CheckinsHistoryComponent {
    protected readonly checkinsHistory$ = this.store.select(DroneTowerMobileState.checkinsHistoryList);
    protected readonly checkinsHistoryListError$ = this.store.select(DroneTowerMobileState.checkinsHistoryListError);
    protected readonly checkinsHistoryListPages$ = this.store
        .select(DroneTowerMobileState.checkinsHistoryListPages)
        .pipe(RxjsUtils.filterFalsy());
    protected readonly isProcessing$ = this.store.select(DroneTowerMobileState.isCheckinsHistoryListProcessing);
    protected readonly filters$ = this.localStore.selectByKey("filters");

    constructor(
        private readonly store: Store,
        private readonly router: Router,
        private readonly toastrService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly localStore: LocalComponentStore<CheckinsHistoryComponentState>
    ) {
        localStore.setState({ filters: null });
        this.store.dispatch(DroneTowerMobileActions.ClearCheckinsHistory);
        this.getFirstPage();
    }

    protected getFirstPage() {
        this.store.dispatch(DroneTowerMobileActions.GetCheckinsHistory);
    }

    protected trackByIndex(index: number) {
        return index;
    }

    protected isNotLastPage(page: Page) {
        return page.pageNumber + 1 < Math.ceil(page.totalElements / page.pageSize);
    }

    protected getNextPage() {
        const page = this.store.selectSnapshot(DroneTowerMobileState.checkinsHistoryListPages);
        if (!page) {
            return;
        }

        if (page.pageNumber < Math.ceil(page.totalElements / page.pageSize)) {
            const filters = this.localStore.selectSnapshotByKey("filters");
            this.store.dispatch(
                new DroneTowerMobileActions.GetCheckinsHistory({
                    page: page.pageNumber + 1,
                    dateFilters: filters ?? undefined,
                })
            );
        }
    }

    protected redirectToMap(coordinates: Coordinates) {
        if (this.store.selectSnapshot(DroneTowerMobileState.userCheckin)) {
            this.toastrService.info(this.transloco.translate("droneTowerMobileLib.checkinsHistory.cannotUseWhileActiveCheckinWarning"));

            return;
        }
        this.router.navigate(["map"]);
        const latLang = new LatLng(coordinates.latitude, coordinates.longitude);
        this.store.dispatch(new DroneTowerMobileActions.SetMapRedirectPosition(latLang));
    }

    protected applyFilters(filters: CheckinsHistoryFilters | null) {
        const currentFilters = this.localStore.selectSnapshotByKey("filters");

        if (JSON.stringify(currentFilters) !== JSON.stringify(filters)) {
            this.localStore.patchState({ filters });
            this.store.dispatch(new DroneTowerMobileActions.GetCheckinsHistory({ dateFilters: filters ?? undefined }, true));
        }
    }

    protected deleteNote(checkinId: string) {
        this.store
            .dispatch(new DroneTowerMobileActions.DeleteCheckinHistoryUserNote(checkinId))
            .pipe(
                switchMap(() => this.store.select(DroneTowerMobileState.deleteCheckinHistoryUserNoteError)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((error) => {
                if (error) {
                    this.toastrService.error(this.transloco.translate("droneTowerMobileLib.checkinsHistory.deleteNoteError"));

                    return;
                }

                this.toastrService.success(this.transloco.translate("droneTowerMobileLib.checkinsHistory.deleteNoteSuccess"));
            });
    }

    protected addNote(note: CheckinsHistoryUserNoteChange) {
        this.store
            .dispatch(new DroneTowerMobileActions.AddCheckinHistoryUserNote(note))
            .pipe(
                switchMap(() => this.store.select(DroneTowerMobileState.addCheckinsHistoryUserNoteError)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((error) => {
                if (error) {
                    this.toastrService.error(
                        this.transloco.translate("droneTowerMobileLib.checkinsHistory.addCheckinsHistoryUserNoteError")
                    );

                    return;
                }

                this.toastrService.success(
                    this.transloco.translate("droneTowerMobileLib.checkinsHistory.addCheckinsHistoryUserNoteSuccess")
                );
            });
    }

    protected updateNote(note: CheckinsHistoryUserNoteChange) {
        this.store
            .dispatch(new DroneTowerMobileActions.UpdateCheckinHistoryUserNote(note))
            .pipe(
                switchMap(() => this.store.select(DroneTowerMobileState.updateCheckinsHistoryUserNoteError)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((error) => {
                if (error) {
                    this.toastrService.error(
                        this.transloco.translate("droneTowerMobileLib.checkinsHistory.updateCheckinsHistoryUserNoteError")
                    );

                    return;
                }
                this.toastrService.success(
                    this.transloco.translate("droneTowerMobileLib.checkinsHistory.updateCheckinsHistoryUserNoteSuccess")
                );
            });
    }
}
