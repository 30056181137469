import { inject, Injectable } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { Store } from "@ngxs/store";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { ErrorHandlingService } from "../services/error-handling-service/error-handling-service";
import { DroneTowerMobileActions } from "../state/drone-tower-mobile.actions";
import { DroneTowerMobileState } from "../state/drone-tower-mobile.state";

@Injectable({
    providedIn: "root",
})
export class CapabilitiesGuard {
    private readonly translocoService = inject(TranslocoService);
    private readonly store = inject(Store);
    private readonly errorHandlingService = inject(ErrorHandlingService);

    public canActivate(): Observable<boolean> {
        const capabilities = this.store.selectSnapshot(DroneTowerMobileState.capabilities);
        if (capabilities.categories.length) {
            return of(true);
        }

        return this.store.dispatch(new DroneTowerMobileActions.GetCapabilities()).pipe(
            switchMap(() => this.store.select(DroneTowerMobileState.capabilitiesError)),
            map((httpErrorResponse) => {
                if (httpErrorResponse) {
                    const errorMessage = this.translocoService.translate("droneTowerMobile.flightCategoriesErrorMessage");
                    this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });

                    return false;
                }

                return true;
            })
        );
    }
}
