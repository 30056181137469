import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule, inject } from "@angular/core";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { getMessaging, provideMessaging } from "@angular/fire/messaging";
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from "@angular/material/dialog";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import {
    ALERT_TOPIC_NAME,
    AppStateService,
    AuthState,
    BUILD_TYPE_PRODUCTION,
    CAPABILITIES_ENDPOINTS_API_TOKENS,
    CHECKINS_ENDPOINTS_API_TOKENS,
    CHECKINS_HISTORY_ENDPOINTS_API_TOKENS,
    DRONE_TOWER_DYNAMIC_CONFIG_TOKEN,
    DroneTowerInterceptor,
    DroneTowerMobileActions,
    DroneTowerMobileLibModule,
    DroneTowerMobileState,
    ENROLLMENT_ENDPOINTS,
    EnrollmentState,
    FIREBASE_CONFIG,
    FLIGHT_CONDITIONS_ENDPOINTS_API_TOKENS,
    FlightStatusBarComponent,
    IS_USER_POSITION_MARKER_MOVABLE,
    InfoComponent,
    LOGIN_ENDPOINT,
    LanguageDisplayComponent,
    PILOT_PROFILE_ENDPOINTS_API_TOKENS,
    PilotOperatorsState,
    PushNotificationsService,
    RESET_PASSWORD_ENDPOINTS,
    ResetPasswordState,
    UserDataService,
    VERSION,
} from "@dtm-frontend/drone-tower-mobile-lib";
import { LoginModule, PANSA_UTM_LOGIN_TOKEN, PANSA_UTM_REGISTER_TOKEN } from "@dtm-frontend/drone-tower-mobile-lib/login";
import { AZURE_MAPS_SUBSCRIPTION_KEY, GeolocationService } from "@dtm-frontend/shared/map";
import { LEAFLET_MAP_CONFIG } from "@dtm-frontend/shared/map/leaflet";
import { IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import {
    I18nRootModule,
    LANGUAGE_CONFIGURATION,
    LanguageCode,
    TRANSLATION_ENDPOINTS,
    TranslationHelperService,
    getTranslocoInlineLoader,
} from "@dtm-frontend/shared/ui/i18n";
import { SharedToastModule } from "@dtm-frontend/shared/ui/toast";
import { FunctionUtils, Logger, LoggerModule, MILLISECONDS_IN_SECOND } from "@dtm-frontend/shared/utils";
import { SharedWebsocketModule, SharedWebsocketModuleConfig, WEBSOCKET_CONFIGURATION } from "@dtm-frontend/shared/websocket";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage-angular";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { PushModule } from "@ngrx/component";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsModule, Store } from "@ngxs/store";
import { forkJoin, timer } from "rxjs";
import { first, takeUntil, tap } from "rxjs/operators";
import { DroneTowerMobileEnvironment } from "../environments/environment.model";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { EasterEggComponent } from "./components/easter-egg/easter-egg.component";
import { MainMenuComponent } from "./components/main-menu/main-menu.component";
import { ActionsHandler } from "./services/actions.handler";

Logger.initialize("https://759c772c602968101a7957cdafa2a58b@sentry.pansa.cloud/14");
const TIMEOUT = 15;

export function createAppModule(environment: DroneTowerMobileEnvironment) {
    @NgModule({
        declarations: [AppComponent, MainMenuComponent, EasterEggComponent],
        imports: [
            NgxsModule.forRoot([AuthState, EnrollmentState, ResetPasswordState, PilotOperatorsState, DroneTowerMobileState], {
                // NOTE: must be first because of https://github.com/ngxs/store/issues/375
                developmentMode: !environment.production,
            }),
            DroneTowerMobileLibModule,
            IonicStorageModule.forRoot(),
            AppRoutingModule,
            BrowserAnimationsModule,
            BrowserModule,
            HttpClientModule,
            I18nRootModule.forRoot({
                developmentMode: !environment.production,
                ...LANGUAGE_CONFIGURATION,
            }),
            NgxsReduxDevtoolsPluginModule.forRoot({
                disabled: environment.production,
                name: "DroneTowerMobile",
            }),
            IonicModule.forRoot(),
            LoginModule,
            PushModule,
            LoggerModule.forRoot(environment.name, !environment.production),
            IconDirective,
            SharedToastModule.forRoot({
                positionClass: "toast-top-full-width",
                closeButton: true,
                resetTimeoutOnDuplicate: true,
                preventDuplicates: true,
            }),
            SharedUiModule,
            InfoComponent,
            MatDialogModule,
            FlightStatusBarComponent,
            LanguageDisplayComponent,
            SharedWebsocketModule.forRoot(),
            provideMessaging(() => getMessaging()),
            provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        ],
        providers: [
            { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
            {
                provide: APP_INITIALIZER,
                useFactory: () => FunctionUtils.noop,
                deps: [ActionsHandler, PushNotificationsService, UserDataService, AppStateService],
                multi: true,
            },
            {
                provide: TRANSLOCO_SCOPE,
                multi: true,
                useValue: {
                    scope: "droneTowerMobile",
                    loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
                },
            },
            {
                provide: GeolocationService,
                useClass: environment.geolocationService,
            },
            {
                provide: PANSA_UTM_REGISTER_TOKEN,
                useValue: environment.pansaUtmRegisterUrl,
            },
            {
                provide: PANSA_UTM_LOGIN_TOKEN,
                useValue: environment.pansaUtmLoginUrl,
            },
            {
                provide: ENROLLMENT_ENDPOINTS,
                useValue: environment.enrollmentEndpoints,
            },
            {
                provide: RESET_PASSWORD_ENDPOINTS,
                useValue: environment.resetPasswordEndpoints,
            },
            {
                provide: LOGIN_ENDPOINT,
                useValue: environment.authenticationEndpoint,
            },
            {
                provide: LEAFLET_MAP_CONFIG,
                useValue: environment.leafletMapConfig,
            },
            {
                provide: AZURE_MAPS_SUBSCRIPTION_KEY,
                useValue: environment.azureMapsSubscriptionKey,
            },
            {
                provide: FLIGHT_CONDITIONS_ENDPOINTS_API_TOKENS,
                useValue: environment.flightConditionsEndpoints,
            },
            {
                provide: TRANSLATION_ENDPOINTS,
                useValue: environment.translationsEndpoint,
            },
            {
                provide: CHECKINS_ENDPOINTS_API_TOKENS,
                useValue: environment.checkinsEndpoints,
            },
            {
                provide: CHECKINS_HISTORY_ENDPOINTS_API_TOKENS,
                useValue: environment.checkinsHistoryEndpoints,
            },
            {
                provide: PILOT_PROFILE_ENDPOINTS_API_TOKENS,
                useValue: environment.pilotProfileEndpoints,
            },
            {
                provide: CAPABILITIES_ENDPOINTS_API_TOKENS,
                useValue: environment.capabilitiesEndpoints,
            },
            {
                provide: FIREBASE_CONFIG,
                useValue: environment.firebaseConfig,
            },
            {
                provide: ALERT_TOPIC_NAME,
                useValue: environment.alertTopicName,
            },
            {
                provide: DRONE_TOWER_DYNAMIC_CONFIG_TOKEN,
                useValue: environment.dynamicConfig,
            },
            {
                provide: VERSION,
                useValue: environment.version,
            },
            {
                provide: BUILD_TYPE_PRODUCTION,
                useValue: environment.production,
            },
            {
                provide: WEBSOCKET_CONFIGURATION,
                useFactory: (): SharedWebsocketModuleConfig => ({
                    endpoint: environment.websocketEndpoint,
                }),
            },
            { provide: HTTP_INTERCEPTORS, useClass: DroneTowerInterceptor, multi: true },
            {
                provide: IS_USER_POSITION_MARKER_MOVABLE,
                useValue: !environment.production || environment.name === "test",
            },
            { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { maxWidth: "calc(100vw - 32px)" } },
        ],
        bootstrap: [AppComponent],
    })
    class AppModule {
        private readonly store = inject(Store);
        constructor(translocoHelper: TranslationHelperService, titleService: Title) {
            const gotTitle$ = translocoHelper
                .waitForTranslation("droneTowerMobile.appTitle")
                .pipe(first(), tap(titleService.setTitle.bind(titleService)));
            const gotTranslations$ = translocoHelper.waitForTranslation("system.drone-tower.flight-category.open").pipe(first());

            const translationsReady$ = forkJoin([gotTitle$, gotTranslations$]).pipe(
                first(),
                tap(() => {
                    document.getElementById("loader")?.remove();
                })
            );

            timer(TIMEOUT * MILLISECONDS_IN_SECOND)
                .pipe(takeUntil(translationsReady$))
                .subscribe(() => {
                    this.store.dispatch(new DroneTowerMobileActions.AppNotResponding());
                });
        }
    }

    return AppModule;
}
