import { PilotOperator } from "../../services/pilot-profile-api/pilot-operator.model";

export namespace PilotOperatorsActions {
    export class GetPilotOperators {
        public static readonly type = "[Pilot Operators] Get Pilot Operators";
    }

    export class AddPilotOperator {
        public static readonly type = "[Pilot Operators] Add Pilot Operator";
        constructor(public pilotOperator: PilotOperator) {}
    }

    export class UpdatePilotOperator {
        public static readonly type = "[Pilot Operators] Update Pilot Operator";
        constructor(public operatorName: string, public pilotOperator: PilotOperator) {}
    }

    export class MarkPilotOperator {
        public static readonly type = "[Pilot Operators] Mark Pilot Operator";
        constructor(public operatorName: string) {}
    }

    export class UnmarkPilotOperator {
        public static readonly type = "[Pilot Operators] Unmark Pilot Operator";
        constructor(public operatorName: string) {}
    }

    export class DeletePilotOperator {
        public static readonly type = "[Pilot Operators] Delete Pilot Operator";
        constructor(public operatorName: string) {}
    }
}
