import { inject, Injectable } from "@angular/core";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { merge, Subject, tap } from "rxjs";
import { AlertEvent, NewAlertEvent } from "../../models/alerts.model";
import { SchedulerService } from "../../shared/services/scheduler.service";
import { ALERT_TOPIC_NAME } from "./alert-api.tokens";

@Injectable({
    providedIn: "root",
})
export class AlertApiService {
    private readonly alertTopicName = inject(ALERT_TOPIC_NAME);
    private readonly websocketService = inject(WebsocketService);
    private readonly schedulerService = inject(SchedulerService);
    private readonly scheduler$ = new Subject<string>();
    private readonly watchTopic$ = this.websocketService.watchTopic(this.alertTopicName).pipe(
        tap((message) => {
            const event = {
                type: message.headers["event-type"] as AlertEvent,
                body: JSON.parse(message.body),
            };
            if (event.type === AlertEvent.NewAlertEvent) {
                this.setRefreshScheduler(event.body);
            }
        })
    );

    public alertEvents$ = merge(this.watchTopic$, this.scheduler$);

    private setRefreshScheduler(event: NewAlertEvent) {
        this.schedulerService.scheduleFunction(event.alertId, new Date(event.endTime), () => this.scheduler$.next(event.alertId));
    }
}
