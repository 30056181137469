import { PhoneNumber } from "@dtm-frontend/shared/ui";
import { AirspaceElementResponseBody } from "../services/flight-conditions-api/flight-conditions-api.converter";

export const OPENED_FLIGHT_CATEGORY_QUERY_PARAM_NAME = "openedCategory";

export interface FlightCategories {
    [key: string]: FlightSubcategory[];
}

export interface FlightSubcategory {
    name: string;
    subcategory: string;
    takeOffMassRanges: MinMaxRange[];
    altitudeRange: MinMaxRange;
    maxWeight: number;
}

export interface MinMaxRange {
    min: number;
    max: number;
}

export interface FlightConditionsStatus {
    status: FlightStatus;
    kpIndexDetails: KpIndexDetails;
    elevationDetails: { elevation: MinMaxRange | null };
}

export enum FlightStatus {
    NoRestriction = "NO_RESTRICTION",
    NoRestrictionInfo = "NO_RESTRICTION_INFO",
    Conditional = "CONDITIONAL",
    Prohibited = "PROHIBITED",
}

export enum MissionStatus {
    Required = "Required",
    NotRequired = "NotRequired",
    NotApplicable = "NotApplicable",
}

export interface KpIndexDetails {
    kpIndex: number;
    isStale: boolean;
}

export enum FlightConditionCategory {
    UAVCategory = "UAVCategory",
    FlightAreaCategory = "FlightAreaCategory",
    TimeCategory = "TimeCategory",
}

export interface CollidingAirspaceElement {
    airspaceElementId: string;
    airspaceReservationId: string;
    status: FlightStatus;
    isAllowedToCheckin: boolean;
    isMissionRequired: boolean;
    geozone: string;
    designator: string;
    startDateTime: Date;
    endDateTime: Date;
    lowerLimit: number;
    upperLimit: number;
    isAlertZone: boolean;
    missionStatus: MissionStatus;
    isH24: boolean;
    plannedActivities: AirspaceElementPlannedActivity[];
    isActive: boolean;
    isStaticElement: boolean;
}

export interface FlightConditionsStatusDetails {
    status: FlightStatus;
    isAllowedToCheckin: boolean;
    isMissionRequired: boolean;
    kpIndexDetails: KpIndexDetails;
    weatherDetails: {
        totalCloudCover: number;
        mslPressureHpa: number;
        airTemperature: number;
        dewPointTemperature: number;
        windSpeed: number;
        windDirection: number;
    };
    elevationDetails: {
        elevation: {
            min: number;
            max: number;
        } | null;
    };
    collidingAirspaceElements: CollidingAirspaceElement[];
    collidingInactiveAirspaceElements: CollidingAirspaceElement[];
    missionStatus: MissionStatus;
    collidingMissions: Mission[];
}

export interface AirspaceElement
    extends Omit<AirspaceElementResponseBody, "h24" | "alertZone" | "plannedActivities" | "active" | "staticElement"> {
    isAlertZone: boolean;
    isH24: boolean;
    isActive: boolean;
    isStaticElement: boolean;
    plannedActivities: AirspaceElementPlannedActivity[];
}

export interface AirspaceElementPlannedActivity {
    lowerLimit: number;
    upperLimit: number;
    startDateTime: Date;
    endDateTime: Date;
    isActive: boolean;
    isInFlightRange?: boolean;
}

export interface Mission {
    id: string;
    name: string;
    flightType: MissionType;
    minAltitude: number;
    maxAltitude: number;
    startDateTime: Date;
    endDateTime: Date;
    pilotPhoneNumber: PhoneNumber;
    operatorNumber?: string;
}

export enum MissionType {
    BVLOS = "BVLOS",
    VLOS = "VLOS",
}

export enum ZoneType {
    Active = "Active",
    Inactive = "Inactive",
}
