import { HttpClient, HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { Store } from "@ngxs/store";
import { BehaviorSubject, first, map, switchMap, tap } from "rxjs";
import { AuthActions } from "../../state/auth/auth.actions";
import { LOGIN_ENDPOINT } from "../auth/auth.tokens";
import { DeviceInfoService } from "../device-info/device-info.service";
import { ErrorHandlingService } from "../error-handling-service/error-handling-service";
import { AuthApiRequestPayload, AuthApiResponseBody, Credentials } from "./auth-api.model";

@Injectable({
    providedIn: "root",
})
export class AuthApiService {
    private readonly loginEndpoint = inject(LOGIN_ENDPOINT);
    private readonly store = inject(Store);
    private readonly http = inject(HttpClient);
    private readonly translocoService = inject(TranslocoService);
    private readonly deviceService = inject(DeviceInfoService);
    private readonly errorHandlingService = inject(ErrorHandlingService);
    private readonly isUnauthorized = new BehaviorSubject<boolean>(false);
    private readonly isLoading = new BehaviorSubject<boolean>(false);

    public readonly isUnauthorized$ = this.isUnauthorized.asObservable();
    public readonly isLoading$ = this.isLoading.asObservable();

    public login(credentials: Credentials) {
        this.deviceService
            .createDeviceInfoRequest()
            .pipe(
                first(),
                tap(() => {
                    this.isUnauthorized.next(false);
                    this.isLoading.next(true);
                }),
                map((deviceInfo) => ({
                    ...credentials,
                    deviceInfo,
                })),
                switchMap((request: AuthApiRequestPayload) => this.http.post<AuthApiResponseBody>(this.loginEndpoint, request))
            )
            .subscribe({
                next: (response: AuthApiResponseBody) => {
                    this.store.dispatch(new AuthActions.Login(response));
                    this.isLoading.next(false);
                },
                error: (error: HttpErrorResponse) => {
                    if (error.status === HttpStatusCode.Unauthorized) {
                        this.store.dispatch(new AuthActions.Logout());
                        this.isUnauthorized.next(true);
                    } else {
                        const errorMessage = this.translocoService.translate("droneTowerMobileLibLogin.apiErrorMessage");
                        this.errorHandlingService.displayMessage({
                            httpErrorResponse: error,
                            errorMessage,
                        });
                    }
                    this.isLoading.next(false);
                },
            });
    }
}
