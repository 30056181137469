import { inject, Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { AuthState } from "../state/auth/auth.state";

@Injectable({
    providedIn: "root",
})
export class AuthGuard {
    private readonly store: Store = inject(Store);
    public canActivate(): Observable<boolean | undefined> {
        return this.store.select(AuthState.isLoggedIn);
    }
}
