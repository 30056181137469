import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { IconDirective } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetModule, PushModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { distinctUntilChanged } from "rxjs";
import { KpIndexDetails } from "../../models/flight-conditions.model";
import { DroneTowerMobileActions } from "../../state/drone-tower-mobile.actions";
import { DroneTowerMobileState } from "../../state/drone-tower-mobile.state";
import { KpIndexDialogComponent } from "../kp-index-dialog/kp-index-dialog.component";

export const KP_INDEX_WARNING_THRESHOLD = 4;
export const KP_INDEX_DANGER_THRESHOLD = 5;

@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-lib-kp-index",
    standalone: true,
    imports: [NgIf, PushModule, IconDirective, LetModule, SharedI18nModule],
    templateUrl: "./kp-index.component.html",
    styleUrls: ["./kp-index.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpIndexComponent implements OnInit {
    private readonly store = inject(Store);
    private readonly dialog = inject(MatDialog);
    private readonly toastrService = inject(ToastrService);
    private readonly translateService = inject(TranslocoService);
    protected readonly kpIndexConfirmationStatus$ = this.store.select(DroneTowerMobileState.kpIndexConfirmationStatus);
    protected readonly kpIndex$ = this.store.select(DroneTowerMobileState.kpIndex).pipe(
        RxjsUtils.filterFalsy(),
        distinctUntilChanged((previous, current) => previous.kpIndex === current.kpIndex)
    );

    public ngOnInit() {
        this.kpIndex$.pipe(untilDestroyed(this)).subscribe((kpIndex) => {
            const confirmationStatus = this.store.selectSnapshot(DroneTowerMobileState.kpIndexConfirmationStatus);
            if (kpIndex.kpIndex >= KP_INDEX_WARNING_THRESHOLD) {
                this.openDialog(kpIndex);
            }
            if (
                kpIndex.kpIndex < KP_INDEX_WARNING_THRESHOLD &&
                (confirmationStatus.isWarningConfirmed || confirmationStatus.isDangerConfirmed)
            ) {
                const toastMessage = this.translateService.translate("droneTowerMobileLib.kpIndex.toastMessage");
                this.store.dispatch(new DroneTowerMobileActions.ResetKpIndexConfirmation());
                this.toastrService.info(toastMessage);
            }
        });
    }

    protected openDialog(kpIndex: KpIndexDetails): void {
        this.store.dispatch(new DroneTowerMobileActions.ResetKpIndexConfirmation());
        const dialogRef = this.dialog.open(KpIndexDialogComponent, {
            data: kpIndex,
            maxWidth: "90vw",
            disableClose: true,
            autoFocus: false,
        });

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if (kpIndex.kpIndex >= KP_INDEX_WARNING_THRESHOLD && kpIndex.kpIndex < KP_INDEX_DANGER_THRESHOLD) {
                    this.store.dispatch(new DroneTowerMobileActions.SetKpIndexWarningConfirmation());
                }
                if (kpIndex.kpIndex >= KP_INDEX_DANGER_THRESHOLD) {
                    this.store.dispatch(new DroneTowerMobileActions.SetKpIndexDangerConfirmation());
                }
            });
    }
}
