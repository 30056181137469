import { InjectionToken } from "@angular/core";

export interface EnrollmentEndpoints {
    createEnrollment: string;
    checkUsernameAvailability: string;
    checkPhoneNumberAvailability: string;
    checkEmailAvailability: string;
    verifyEmail: string;
    repeatEmailVerification: string;
    verifyPhone: string;
    repeatPhoneVerification: string;
    enroll: string;
}

export const ENROLLMENT_ENDPOINTS = new InjectionToken<EnrollmentEndpoints>("Enrollment endpoints");
