import { Injectable } from "@angular/core";
import { FlightStatusParams } from "../../state/drone-tower-mobile.state.model";
import { StorageService } from "../storage/storage.service";

const FLIGHT_STATUS_PARAMS_DATA_STORAGE_KEY = "flightStatusParamsData";

@Injectable({
    providedIn: "root",
})
export class FlightStatusParamsStorageService {
    constructor(private readonly storageService: StorageService<FlightStatusParams>) {}

    public async patchFlightStatusParams(flightStatusParams: FlightStatusParams) {
        await this.storageService.setKey(FLIGHT_STATUS_PARAMS_DATA_STORAGE_KEY, flightStatusParams);
    }

    public async getFlightStatusParams(): Promise<FlightStatusParams | undefined> {
        return await this.storageService.getKey(FLIGHT_STATUS_PARAMS_DATA_STORAGE_KEY);
    }
}
