import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { PhoneNumber } from "@dtm-frontend/shared/ui";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { catchError, map, of } from "rxjs";
import { ERROR_MESSAGE_EMAIL_ALREADY_REGISTERED, ERROR_MESSAGE_PHONE_NUMBER_ALREADY_REGISTERED } from "../../utils/defaults";
import {
    CheckEmailAvailabilityRequestPayload,
    CheckPhoneNumberAvailabilityRequestPayload,
    EnrollmentRequestPayload,
    EnrollmentResponseBody,
    EnrollRequestPayload,
    VerifyEmailRequestPayload,
    VerifyPhoneRequestPayload,
} from "./enrollment.model";
import { ENROLLMENT_ENDPOINTS } from "./enrollment.tokens";

@Injectable({
    providedIn: "root",
})
export class EnrollmentService {
    private readonly enrollmentEndpoints = inject(ENROLLMENT_ENDPOINTS);
    private readonly http = inject(HttpClient);

    public createEnrollment(enrollmentRequestPayload: EnrollmentRequestPayload) {
        return this.http.post<EnrollmentResponseBody>(this.enrollmentEndpoints.createEnrollment, enrollmentRequestPayload);
    }

    public checkPhoneNumberAvailability(phoneNumber: PhoneNumber) {
        const checkPhoneNumberAvailabilityRequestPayload: CheckPhoneNumberAvailabilityRequestPayload = {
            phoneNumber,
        };

        return this.http.post(this.enrollmentEndpoints.checkPhoneNumberAvailability, checkPhoneNumberAvailabilityRequestPayload).pipe(
            map(() => ({ isUnique: true })),
            catchError((error) => {
                if (error.error.message === ERROR_MESSAGE_PHONE_NUMBER_ALREADY_REGISTERED) {
                    return of({ isUnique: false });
                }

                throw error;
            })
        );
    }

    public checkEmailAvailability(email: string) {
        const checkEmailAvailabilityRequestPayload: CheckEmailAvailabilityRequestPayload = {
            email,
        };

        return this.http.post(this.enrollmentEndpoints.checkEmailAvailability, checkEmailAvailabilityRequestPayload).pipe(
            map(() => ({ isUnique: true })),
            catchError((error) => {
                if (error.error.message === ERROR_MESSAGE_EMAIL_ALREADY_REGISTERED) {
                    return of({ isUnique: false });
                }

                throw error;
            })
        );
    }

    public verifyEmail(id: string, verifyEmailRequestPayload: VerifyEmailRequestPayload) {
        return this.http.post(StringUtils.replaceInTemplate(this.enrollmentEndpoints.verifyEmail, { id }), verifyEmailRequestPayload);
    }

    public repeatEmailVerification(id: string) {
        return this.http.post(StringUtils.replaceInTemplate(this.enrollmentEndpoints.repeatEmailVerification, { id }), {});
    }

    public verifyPhone(id: string, verifyPhoneRequestPayload: VerifyPhoneRequestPayload) {
        return this.http.post(StringUtils.replaceInTemplate(this.enrollmentEndpoints.verifyPhone, { id }), verifyPhoneRequestPayload);
    }

    public repeatPhoneVerification(id: string) {
        return this.http.post(StringUtils.replaceInTemplate(this.enrollmentEndpoints.repeatPhoneVerification, { id }), {});
    }

    public enroll(id: string, enrollRequestPayload: EnrollRequestPayload) {
        return this.http.post(StringUtils.replaceInTemplate(this.enrollmentEndpoints.enroll, { id }), enrollRequestPayload);
    }
}
