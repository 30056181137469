import { HttpErrorResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Action, createSelector, Selector, State, StateContext } from "@ngxs/store";
import { catchError, EMPTY, tap } from "rxjs";
import { PilotOperator } from "../../services/pilot-profile-api/pilot-operator.model";
import { PilotProfileApiService } from "../../services/pilot-profile-api/pilot-profile-api.service";
import { PilotOperatorsActions } from "./pilot-operators.actions";

export interface PilotOperatorsStateModel {
    isProcessing: boolean;
    pilotOperators: PilotOperator[];
    pilotOperatorsError: HttpErrorResponse | null;
    addPilotOperatorsError: HttpErrorResponse | null;
    updatePilotOperatorsError: HttpErrorResponse | null;
    deletePilotOperatorsError: HttpErrorResponse | null;
    markPilotOperatorsError: HttpErrorResponse | null;
}

const DEFAULT_STATE: PilotOperatorsStateModel = {
    isProcessing: false,
    pilotOperators: [],
    pilotOperatorsError: null,
    addPilotOperatorsError: null,
    updatePilotOperatorsError: null,
    deletePilotOperatorsError: null,
    markPilotOperatorsError: null,
};

@State<PilotOperatorsStateModel>({
    name: "pilotOperators",
    defaults: DEFAULT_STATE,
})
@Injectable()
export class PilotOperatorsState {
    private readonly pilotProfileApiService = inject(PilotProfileApiService);

    @Selector()
    public static isProcessing(state: PilotOperatorsStateModel) {
        return state.isProcessing;
    }

    @Selector()
    public static pilotOperators(state: PilotOperatorsStateModel) {
        return state.pilotOperators;
    }

    public static pilotOperatorsByName(name: string) {
        return createSelector([PilotOperatorsState], (state: PilotOperatorsStateModel) =>
            state.pilotOperators.find((item) => item.name === name)
        );
    }

    @Selector()
    public static pilotOperatorsError(state: PilotOperatorsStateModel) {
        return state.pilotOperatorsError;
    }

    @Selector()
    public static addPilotOperatorsError(state: PilotOperatorsStateModel) {
        return state.addPilotOperatorsError;
    }

    @Selector()
    public static updatePilotOperatorsError(state: PilotOperatorsStateModel) {
        return state.updatePilotOperatorsError;
    }

    @Selector()
    public static markPilotOperatorsError(state: PilotOperatorsStateModel) {
        return state.markPilotOperatorsError;
    }

    @Selector()
    public static deletePilotOperatorsError(state: PilotOperatorsStateModel) {
        return state.deletePilotOperatorsError;
    }

    @Action(PilotOperatorsActions.GetPilotOperators, { cancelUncompleted: true })
    public getPilotOperators(context: StateContext<PilotOperatorsStateModel>) {
        context.patchState({
            pilotOperatorsError: null,
            isProcessing: true,
        });

        return this.pilotProfileApiService.getPilotOperators().pipe(
            tap((pilotOperators) => {
                context.patchState({
                    pilotOperators,
                    isProcessing: false,
                });
            }),
            catchError((pilotOperatorsError) => {
                context.patchState({
                    pilotOperatorsError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(PilotOperatorsActions.AddPilotOperator, { cancelUncompleted: true })
    public addPilotOperator(context: StateContext<PilotOperatorsStateModel>, { pilotOperator }: PilotOperatorsActions.AddPilotOperator) {
        context.patchState({
            addPilotOperatorsError: null,
            isProcessing: true,
        });

        return this.pilotProfileApiService.addPilotOperator(pilotOperator).pipe(
            tap(() => {
                context.patchState({
                    isProcessing: false,
                });
            }),
            catchError((addPilotOperatorsError) => {
                context.patchState({
                    addPilotOperatorsError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(PilotOperatorsActions.UpdatePilotOperator, { cancelUncompleted: true })
    public updatePilotOperator(
        context: StateContext<PilotOperatorsStateModel>,
        { operatorName, pilotOperator }: PilotOperatorsActions.UpdatePilotOperator
    ) {
        context.patchState({
            updatePilotOperatorsError: null,
            isProcessing: true,
        });

        return this.pilotProfileApiService.updatePilotOperator(operatorName, pilotOperator).pipe(
            tap(() => {
                context.patchState({
                    isProcessing: false,
                });
            }),
            catchError((updatePilotOperatorsError) => {
                context.patchState({
                    updatePilotOperatorsError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(PilotOperatorsActions.MarkPilotOperator, { cancelUncompleted: true })
    public markPilotOperator(context: StateContext<PilotOperatorsStateModel>, { operatorName }: PilotOperatorsActions.MarkPilotOperator) {
        context.patchState({
            markPilotOperatorsError: null,
            isProcessing: true,
        });

        return this.pilotProfileApiService.markPilotOperator(operatorName).pipe(
            tap(() => {
                context.patchState({
                    isProcessing: false,
                });
            }),
            catchError((markPilotOperatorsError) => {
                context.patchState({
                    markPilotOperatorsError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(PilotOperatorsActions.UnmarkPilotOperator, { cancelUncompleted: true })
    public unmarkPilotOperator(
        context: StateContext<PilotOperatorsStateModel>,
        { operatorName }: PilotOperatorsActions.UnmarkPilotOperator
    ) {
        context.patchState({
            markPilotOperatorsError: null,
            isProcessing: true,
        });

        return this.pilotProfileApiService.unmarkPilotOperator(operatorName).pipe(
            tap(() => {
                context.patchState({
                    isProcessing: false,
                });
            }),
            catchError((markPilotOperatorsError) => {
                context.patchState({
                    markPilotOperatorsError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(PilotOperatorsActions.DeletePilotOperator, { cancelUncompleted: true })
    public deletePilotOperator(
        context: StateContext<PilotOperatorsStateModel>,
        { operatorName }: PilotOperatorsActions.DeletePilotOperator
    ) {
        context.patchState({
            deletePilotOperatorsError: null,
            isProcessing: true,
        });

        return this.pilotProfileApiService.deletePilotOperator(operatorName).pipe(
            tap(() => {
                context.patchState({
                    isProcessing: false,
                });
            }),
            catchError((deletePilotOperatorsError) => {
                context.patchState({
                    deletePilotOperatorsError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }
}
