import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { RouterLink } from "@angular/router";
import { IconDirective, IconName } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { PushModule } from "@ngrx/component";

interface HeaderComponentState {
    headerLabel: string;
    icon: IconName;
    link: string;
}

@Component({
    selector: "drone-tower-mobile-lib-header",
    standalone: true,
    imports: [IconDirective, RouterLink, PushModule],
    providers: [LocalComponentStore],
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    @Input() public set headerLabel(value: string) {
        this.localStore.patchState({
            headerLabel: value,
        });
    }
    @Input() public set icon(value: IconName) {
        this.localStore.patchState({
            icon: value,
        });
    }
    @Input() public set link(value: string) {
        this.localStore.patchState({
            link: value,
        });
    }
    protected readonly headerLabel$ = this.localStore.selectByKey("headerLabel");
    protected readonly icon$ = this.localStore.selectByKey("icon");
    protected readonly link$ = this.localStore.selectByKey("link");

    constructor(private readonly localStore: LocalComponentStore<HeaderComponentState>) {
        this.localStore.setState({ headerLabel: "", icon: "close", link: "/" });
    }
}
