<div class="container" *ngrxLet="{ kpIndexConfirmationStatus: kpIndexConfirmationStatus$, kpIndex: kpIndex$ } as vm">
    <ng-container *ngIf="vm.kpIndexConfirmationStatus.isDangerConfirmed || vm.kpIndexConfirmationStatus.isWarningConfirmed">
        <div
            class="index"
            [class.warning]="vm.kpIndexConfirmationStatus.isWarningConfirmed"
            [class.danger]="vm.kpIndexConfirmationStatus.isDangerConfirmed"
            (click)="openDialog(vm.kpIndex)"
        >
            <dtm-ui-icon name="error-warning"></dtm-ui-icon>
            <p>{{ "droneTowerMobileLib.kpIndex.kpIndexChipsLabel" | transloco }} {{ vm.kpIndex.kpIndex | localizeNumber }}</p>
        </div>
    </ng-container>
</div>
