import { AuthApiResponseBody } from "../../services/auth-api/auth-api.model";

export namespace AuthActions {
    export class Login {
        public static readonly type = "[Auth] Login";
        constructor(public credentials: AuthApiResponseBody) {}
    }

    export class Logout {
        public static readonly type = "[Auth] Logout";
    }
}
