import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { DroneTowerMobileActions } from "../../state/drone-tower-mobile.actions";
import { UserData } from "../../state/drone-tower-mobile.state.model";
import { StorageService } from "../storage/storage.service";

const USER_DATA_STORAGE_KEY = "userData";

@Injectable({
    providedIn: "root",
})
export class UserDataService {
    constructor(private readonly storageService: StorageService<Partial<UserData>>, private readonly store: Store) {
        this.initUserData();
    }

    public async patchUserData(userData: Partial<UserData>) {
        await this.storageService.setKey(USER_DATA_STORAGE_KEY, userData);
        this.store.dispatch(new DroneTowerMobileActions.PatchUserData(userData));
    }

    public async initUserData() {
        const userData = await this.storageService.getKey(USER_DATA_STORAGE_KEY);
        if (userData) {
            this.store.dispatch(new DroneTowerMobileActions.PatchUserData(userData));
        }
    }
}
