import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { LetModule } from "@ngrx/component";

interface NameInputFieldComponentState {
    label: string;
    control: FormControl | undefined;
    isClearable: boolean;
}
@Component({
    selector: "drone-tower-mobile-lib-name-input-field[control]",
    standalone: true,
    imports: [CommonModule, SharedI18nModule, SharedUiModule, TranslocoModule, LetModule, ReactiveFormsModule],
    templateUrl: "./name-input-field.component.html",
    styleUrls: ["./name-input-field.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class NameInputFieldComponent {
    @Input() public set label(value: string) {
        this.localStore.patchState({ label: value });
    }

    @Input() public set control(value: FormControl) {
        this.localStore.patchState({ control: value });
    }

    @Input() public set isClearable(value: BooleanInput) {
        this.localStore.patchState({ isClearable: coerceBooleanProperty(value) });
    }

    protected readonly label$ = this.localStore.selectByKey("label");
    protected readonly isClearable$ = this.localStore.selectByKey("isClearable");
    protected readonly control$ = this.localStore.selectByKey("control").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<NameInputFieldComponentState>) {
        localStore.setState({ label: "", control: undefined, isClearable: false });
    }
}
