import { PhoneNumber } from "@dtm-frontend/shared/ui";
import { FlightCategories, FlightStatus } from "../models/flight-conditions.model";

export enum DroneTowerFeatures {
    Checkin = "CHECKIN",
    Login = "LOGIN",
    FlightConditions = "FLIGHT_CONDITIONS",
    BvlosMissions = "BVLOS_MISSIONS",
    Airspace = "AIRSPACE",
    Enrollment = "ENROLLMENT",
    PilotData = "PILOT_DATA",
    ResetPassword = "RESET_PASSWORD",
    TilesetStyle = "TILESET_STYLE",
    Operators = "OPERATORS",
    InactiveZones = "INACTIVE_ZONES",
}

export interface Capabilities {
    categories: FlightCategory[];
    subcategories: FlightCategories;
    features: DroneTowerFeatures[];
    geoserverUrl: string;
    mobileAppVersion?: MobileAppVersion;
}

export interface FlightCategory {
    name: string;
    category: FlightCategoriesValues;
}

export interface MarkerPosition {
    latitude: number;
    longitude: number;
    isUserPosition: boolean;
}

export interface UserData {
    name: string;
    surname: string;
    pilotNumber: string;
    operatorNumber: string;
    phoneNumber: PhoneNumber;
    operatorDataCanBePublished: boolean;
    isPhoneNumberVerified: boolean;
    flightStatusParams?: FlightStatusParams;
}

export interface FlightStatusParams {
    flightCategory: FlightCategoriesValues;
    flightSubcategory: string;
    flightCategoryTranslationKey: string;
    flightSubcategoryTranslationKey: string;
    flightHeight: number;
    uavWeight: Weight;
    radius: number;
    startTime: number;
    duration: number;
    areBvlosMissionsVisible: boolean;
}

export interface CheckInParams {
    operatorNumber: string;
    operatorName: string;
    isOneTimeOperatorNumber: boolean;
}

export enum FlightStatusExtended {
    Error = "ERROR",
    ErrorOutsideSupportedArea = "ERROR_OUTSIDE_SUPPORTED_AREA",
    Loading = "LOADING",
}

export interface KpIndexConfirmationState {
    isWarningConfirmed: boolean;
    isDangerConfirmed: boolean;
}

export interface MobileAppVersion {
    minimalVersion: string;
    latestVersion: string;
}

export type FlightStatusState = FlightStatus | FlightStatusExtended;

/* eslint-disable no-magic-numbers */
export enum Weight {
    Light = 0.9,
    MediumLight = 4,
    Medium = 25,
    Heavy = 150,
}
/* eslint-enable */

export enum FlightCategoriesValues {
    OPEN = "OPEN",
    SPECIFIC = "SPECIFIC",
    CERTIFIED = "CERTIFIED",
}

export enum FlightSubcategoriesValues {
    A1 = "A1",
}

export enum FlightCategoriesTranslationKeysValues {
    OPEN = "drone-tower.flight-category.open",
    SPECIFIC = "drone-tower.flight-category.specific",
    CERTIFIED = "drone-tower.flight-category.cerified",
}

export enum FlightSubcategoriesTranslationKeysValues {
    A1 = "drone-tower.flight-subcategory.a1",
}
