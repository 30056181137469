import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule, TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { PushModule } from "@ngrx/component";
import { first } from "rxjs";
import { KpIndexDetails } from "../../models/flight-conditions.model";
import { KP_INDEX_DANGER_THRESHOLD } from "../kp-index/kp-index.component";

@Component({
    selector: "drone-tower-mobile-lib-kp-index-dialog",
    standalone: true,
    imports: [CommonModule, MatDialogModule, SharedI18nModule, SharedUiModule, PushModule],
    templateUrl: "./kp-index-dialog.component.html",
    styleUrls: ["./kp-index-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpIndexDialogComponent {
    protected readonly KP_INDEX_DANGER_THRESHOLD = KP_INDEX_DANGER_THRESHOLD;
    protected readonly kpIndexDetails: KpIndexDetails = inject(MAT_DIALOG_DATA);
    private readonly translationHelperService = inject(TranslationHelperService);
    protected readonly translationsReady$ = this.translationHelperService
        .waitForTranslation("droneTowerMobileLib.kpIndex.kpIndexHeaderLabel")
        .pipe(first());
}
