<ion-header class="main-menu ion-no-border" *ngIf="isMenuButtonVisible$ | ngrxPush">
    <ion-menu-toggle>
        <button type="button" class="menu-button">
            <dtm-ui-icon slot="start" name="menu"></dtm-ui-icon>
        </button>
    </ion-menu-toggle>
    <drone-tower-mobile-lib-flight-status-bar routerLink="location-info"></drone-tower-mobile-lib-flight-status-bar>
</ion-header>

<ion-menu #ionMenu side="start" swipe-gesture="false" content-id="main" class="menu-drawer" (ionDidOpen)="resetLogoTaps()">
    <ion-content>
        <div class="menu-drawer-header">
            <img class="logo" src="/assets/images/logo.svg" alt="logo" (click)="countLogoTap()" />
            <ion-menu-toggle>
                <button type="button" class="close-button button-icon">
                    <dtm-ui-icon slot="start" name="close"></dtm-ui-icon>
                </button>
            </ion-menu-toggle>
        </div>
        <ion-list class="menu-items">
            <!-- TODO DTOWER-313 revert in the next app version
            <button type="button" class="button-secondary menu-item" routerLinkActive="menu-item-active" (click)="ionMenu.close()">
                <span>{{ "droneTowerMobile.mainMenu.weatherLabel" | transloco }}</span>
            </button> -->
            <button
                type="button"
                class="button-secondary menu-item"
                routerLink="checkins-history"
                routerLinkActive="menu-item-active"
                (click)="ionMenu.close()"
            >
                <span>{{ "droneTowerMobile.mainMenu.checkinsHistory" | transloco }}</span>
            </button>
            <button
                type="button"
                class="button-secondary menu-item"
                routerLink="user-data"
                routerLinkActive="menu-item-active"
                (click)="ionMenu.close()"
            >
                <span>{{ "droneTowerMobile.mainMenu.userDataLabel" | transloco }}</span>
            </button>
            <button
                type="button"
                class="button-secondary menu-item"
                routerLink="operators"
                routerLinkActive="menu-item-active"
                (click)="ionMenu.close()"
                *ngIf="isOperatorsFeatureAvailable$ | ngrxPush"
            >
                <span>{{ "droneTowerMobile.mainMenu.operatorsLabel" | transloco }}</span>
            </button>
            <hr />
            <button
                type="button"
                class="button-secondary menu-item"
                routerLink="help"
                routerLinkActive="menu-item-active"
                (click)="ionMenu.close()"
            >
                <span>{{ "droneTowerMobile.mainMenu.helpLabel" | transloco }}</span>
            </button>
            <button
                type="button"
                class="button-secondary menu-item"
                routerLinkActive="menu-item-active"
                routerLink="rules"
                (click)="ionMenu.close()"
            >
                <span>{{ "droneTowerMobile.mainMenu.aboutLabel" | transloco }}</span>
            </button>
            <button
                type="button"
                class="button-secondary menu-item"
                routerLinkActive="menu-item-active"
                routerLink="info"
                (click)="ionMenu.close()"
                *ngIf="!isProduction"
            >
                <span>{{ "droneTowerMobile.mainMenu.infoLabel" | transloco }}</span>
            </button>
        </ion-list>
    </ion-content>
    <ion-footer class="bar-stable">
        <ion-list class="menu-items">
            <button type="button" class="button-secondary menu-item" (click)="logout()">
                <dtm-ui-icon slot="start" name="logout-rectangle"></dtm-ui-icon
                ><span>{{ "droneTowerMobile.mainMenu.logoutLabel" | transloco }}</span>
            </button>
            <button type="button" class="button-secondary menu-item" (click)="toggleLanguage()">
                <drone-tower-mobile-lib-language-display [language]="language$ | ngrxPush"></drone-tower-mobile-lib-language-display>
            </button>
        </ion-list>
    </ion-footer>
</ion-menu>
