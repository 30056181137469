import { inject, Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { AuthActions } from "../../state/auth/auth.actions";
import { AuthApiResponseBody } from "../auth-api/auth-api.model";
import { StorageAuthKeys } from "../storage/storage-auth-keys.enum";
import { StorageService } from "../storage/storage.service";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    private readonly storageService = inject(StorageService);
    private readonly store = inject(Store);

    public async init() {
        const accessToken: string = await this.storageService.getKey(StorageAuthKeys.ACCESS_TOKEN);
        const userId: string = await this.storageService.getKey(StorageAuthKeys.USER_ID);
        const ttl: number = await this.storageService.getKey(StorageAuthKeys.TTL);
        const createAt: string = await this.storageService.getKey(StorageAuthKeys.CREATE_AT);

        if (accessToken && userId && ttl && createAt) {
            this.store.dispatch(new AuthActions.Login({ accessToken, userId, ttl, createAt }));

            return;
        }

        this.store.dispatch(new AuthActions.Logout());
    }

    public async login(credentials: AuthApiResponseBody) {
        await this.storageService.setKey(StorageAuthKeys.ACCESS_TOKEN, credentials.accessToken);
        await this.storageService.setKey(StorageAuthKeys.USER_ID, credentials.userId);
        await this.storageService.setKey(StorageAuthKeys.TTL, credentials.ttl);
        await this.storageService.setKey(StorageAuthKeys.CREATE_AT, credentials.createAt);
    }

    public async logout() {
        await this.storageService.removeKey(StorageAuthKeys.ACCESS_TOKEN);
        await this.storageService.removeKey(StorageAuthKeys.USER_ID);
        await this.storageService.removeKey(StorageAuthKeys.TTL);
        await this.storageService.removeKey(StorageAuthKeys.CREATE_AT);
    }
}
