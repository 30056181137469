import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionDispatched } from "@ngxs/store";

interface EasterEggComponentState {
    easterEggLogoTaps: number;
    isEasterEggActive: boolean;
}

const LOGO_TAPS_TO_EASTER_EGG = 5;
const EASTER_EGG_DURATION = 3000;

export namespace EasterEggActions {
    export class CountLogoTap {
        public static readonly type = "[EasterEgg] Count logo tap";
    }

    export class ResetLogoTaps {
        public static readonly type = "[EasterEgg] Reset logo taps";
    }
}

@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-easter-egg",
    templateUrl: "./easter-egg.component.html",
    styleUrls: ["./easter-egg.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class EasterEggComponent {
    protected readonly isEasterEggActive$ = this.localStore.selectByKey("isEasterEggActive");

    constructor(private readonly localStore: LocalComponentStore<EasterEggComponentState>, actions$: Actions) {
        this.localStore.setState({
            easterEggLogoTaps: 0,
            isEasterEggActive: false,
        });

        actions$.pipe(ofActionDispatched(EasterEggActions.CountLogoTap), untilDestroyed(this)).subscribe(() => this.countLogoTap());
        actions$.pipe(ofActionDispatched(EasterEggActions.ResetLogoTaps), untilDestroyed(this)).subscribe(() => this.resetLogoTaps());
    }

    protected countLogoTap() {
        if (this.localStore.selectSnapshotByKey("isEasterEggActive")) {
            return;
        }

        this.localStore.patchState(({ easterEggLogoTaps }) => ({ easterEggLogoTaps: easterEggLogoTaps + 1 }));

        if (this.localStore.selectSnapshotByKey("easterEggLogoTaps") % LOGO_TAPS_TO_EASTER_EGG === 0) {
            this.localStore.patchState({
                isEasterEggActive: true,
            });
            setTimeout(() => {
                this.localStore.patchState({ isEasterEggActive: false });
            }, EASTER_EGG_DURATION);
        }
    }

    protected resetLogoTaps() {
        this.localStore.patchState({ easterEggLogoTaps: 0 });
    }
}
