import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { ArrayUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetModule, PushModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { map } from "rxjs";
import { CheckinMission } from "../../models/checkins.model";
import { MissionType } from "../../models/flight-conditions.model";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { DroneTowerMobileActions } from "../../state/drone-tower-mobile.actions";
import { DroneTowerMobileState } from "../../state/drone-tower-mobile.state";

interface CheckInMissionsListComponentState {
    selectedMission: CheckinMission | null;
    todayMissions: CheckinMission[];
    tomorrowMissions: CheckinMission[];
    otherMissions: CheckinMission[];
}

@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-lib-check-in-missions-list",
    standalone: true,
    imports: [
        CommonModule,
        HeaderComponent,
        TranslocoModule,
        IconDirective,
        SharedUiModule,
        PushModule,
        RouterLink,
        SharedI18nModule,
        LetModule,
    ],
    templateUrl: "./check-in-missions-list.component.html",
    styleUrls: ["./check-in-missions-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CheckInMissionsListComponent {
    private readonly store = inject(Store);
    private readonly router = inject(Router);

    protected readonly missions$ = this.store.select(DroneTowerMobileState.checkinMissions);
    protected readonly todayMissions$ = this.localStore.selectByKey("todayMissions");
    protected readonly tomorrowMissions$ = this.localStore.selectByKey("tomorrowMissions");
    protected readonly otherMissions$ = this.localStore.selectByKey("otherMissions");
    protected readonly selectedMissionId$ = this.localStore.selectByKey("selectedMission").pipe(map((mission) => mission?.id));

    protected readonly MissionType = MissionType;

    constructor(private readonly localStore: LocalComponentStore<CheckInMissionsListComponentState>) {
        this.localStore.setState({
            selectedMission: this.store.selectSnapshot(DroneTowerMobileState.selectedCheckinMission),
            todayMissions: [],
            tomorrowMissions: [],
            otherMissions: [],
        });

        this.missions$.pipe(untilDestroyed(this)).subscribe((missions) => {
            this.localStore.patchState(this.segregateMissions(missions));
        });
    }

    protected selectMission(selectedMission: CheckinMission) {
        this.localStore.patchState({ selectedMission });
    }

    protected saveMission() {
        const selectedMission = this.localStore.selectSnapshot((state) => state.selectedMission);

        this.store
            .dispatch(new DroneTowerMobileActions.SelectCheckinMission(selectedMission))
            .pipe(untilDestroyed(this))
            .subscribe(() => this.router.navigateByUrl("/"));
    }

    private segregateMissions(missions: CheckinMission[]) {
        const sortedMissions = [...missions].sort(
            (left: CheckinMission, right: CheckinMission) =>
                new Date(left.startDateTime).getTime() - new Date(right.startDateTime).getTime()
        );

        const tomorrow = new Date();
        tomorrow.setHours(0, 0, 0, 0);
        tomorrow.setDate(tomorrow.getDate() + 1);
        const tomorrowTime = tomorrow.getTime();

        const [todayMissions, remainingMissions] = ArrayUtils.partition(sortedMissions, (mission) => {
            const missionStartDate = new Date(mission.startDateTime);
            missionStartDate.setHours(0, 0, 0, 0);

            return missionStartDate.getTime() < tomorrowTime;
        });

        const [tomorrowMissions, otherMissions] = ArrayUtils.partition(remainingMissions, (mission) => {
            const missionStartDate = new Date(mission.startDateTime);
            missionStartDate.setHours(0, 0, 0, 0);

            return missionStartDate.getTime() === tomorrowTime;
        });

        return {
            todayMissions,
            tomorrowMissions,
            otherMissions,
        };
    }
}
