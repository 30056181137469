import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { TranslocoModule } from "@jsverse/transloco";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { RulesContentComponent } from "../rules-content/rules-content.component";

@Component({
    selector: "drone-tower-mobile-lib-rules",
    standalone: true,
    imports: [CommonModule, HeaderComponent, TranslocoModule, RulesContentComponent],
    templateUrl: "./rules.component.html",
    styleUrls: ["./rules.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RulesComponent {}
