import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { TranslocoModule } from "@jsverse/transloco";
import { HeaderComponent } from "../../shared/components/header/header.component";

@Component({
    selector: "drone-tower-mobile-lib-edit-checkin",
    standalone: true,
    imports: [CommonModule, HeaderComponent, TranslocoModule, SharedI18nModule, SharedUiModule],
    templateUrl: "./checkin-details.component.html",
    styleUrls: ["./checkin-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckinDetailsComponent {}
