import { Directive, Inject, Input } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Map, TileLayer } from "leaflet";
import { Subject, scan } from "rxjs";
import { LEAFLET_MAP_PROVIDER, LeafletMapProvider } from "../../leaflet-map.tokens";
import { LeafletMapLayerConfig } from "../../models/leaflet-map.models";
import { AuthorizedTileLayerWMS } from "./authorized-wms-layer";

@UntilDestroy()
@Directive({ selector: "dtm-map-leaflet-map-layer-smooth-transition[layerConfig]" })
export class LeafletMapLayerSmoothTransitionDirective {
    @Input() public set layerConfig(value: LeafletMapLayerConfig | undefined) {
        if (!value) {
            return;
        }
        this.addMapLayer(value);
    }

    private map!: Map;

    private currentLayer = new Subject<TileLayer | TileLayer.WMS>();

    private currentLayer$ = this.currentLayer.pipe(
        scan((currentLayer, newLayer) => {
            this.map.addLayer(newLayer);

            const currentLayerElement = currentLayer.getContainer();
            currentLayerElement?.classList.add("layer-smooth-transition", "hidden");

            currentLayerElement?.addEventListener("transitionend", () => {
                this.map.removeLayer(currentLayer);
            });

            return newLayer;
        })
    );

    constructor(@Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider) {
        this.currentLayer$.pipe(untilDestroyed(this)).subscribe();
    }

    private async addMapLayer(config: LeafletMapLayerConfig) {
        if (!this.map) {
            this.map = await this.mapProvider.getMap();
        }

        this.currentLayer.next(this.getLayer(config));
    }

    private getLayer({ type, baseUrl, options, authorizationHeader }: LeafletMapLayerConfig) {
        if (authorizationHeader) {
            return new AuthorizedTileLayerWMS(baseUrl, options, authorizationHeader);
        }

        return type === "TileLayer" ? new TileLayer(baseUrl, options) : new TileLayer.WMS(baseUrl, options);
    }
}
