import { InjectionToken } from "@angular/core";

export interface PilotProfileEndpoints {
    getPilotData: string;
    setPilotDetails: string;
    verifyPhoneNumber: string;
    requestPhoneNumberChange: string;
    missingInfoUpdate: string;
    confirmMissingInfoUpdate: string;
    pilotOperators: string;
    managePilotOperators: string;
    unmarkPilotOperators: string;
    markPilotOperators: string;
}

export const PILOT_PROFILE_ENDPOINTS_API_TOKENS = new InjectionToken<PilotProfileEndpoints>("Pilot profile endpoints token");
