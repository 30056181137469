import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule, TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { TranslocoModule } from "@jsverse/transloco";
import { PushModule } from "@ngrx/component";
import { first } from "rxjs";
import { AppStateService } from "../../services/app-state/app-state.service";

export const GO_TO_APP = "GO_TO_APP";
export const GO_TO_APP_STORE = "GO_TO_APP_STORE";

@Component({
    standalone: true,
    imports: [CommonModule, MatDialogModule, SharedI18nModule, SharedUiModule, TranslocoModule, PushModule],
    templateUrl: "./update-dialog.component.html",
    styleUrls: ["../../../styles/simple-check-in-dialog.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateDialogComponent {
    private readonly dialogRef = inject(MatDialogRef<UpdateDialogComponent>);
    private readonly translationHelperService = inject(TranslationHelperService);
    private readonly appStateService = inject(AppStateService);
    protected readonly matDialogData = inject(MAT_DIALOG_DATA);
    protected readonly translationsReady$ = this.translationHelperService
        .waitForTranslation("droneTowerMobileLib.updateDialog.headerLabel")
        .pipe(first());

    protected goToApp() {
        this.dialogRef.close(GO_TO_APP);
    }

    protected goToAppStore() {
        this.appStateService.openAppStore();
    }
}
