import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { Router } from "@angular/router";
import { IconDirective } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { LetModule, PushModule } from "@ngrx/component";

interface FloatingMenuComponentState {
    isOpen: boolean;
    isCheckinActive: boolean;
    isFloatingMenuHidden: boolean;
    isLoading: boolean;
}

export enum FloatingMenuActionName {
    Checkin = "checkin",
    FinishCheckin = "finish-checkin",
    LostControl = "lost-control",
    OpenStatusForm = "open-status-form",
}

@Component({
    selector: "drone-tower-mobile-lib-floating-menu",
    standalone: true,
    imports: [CommonModule, IconDirective, LetModule, PushModule, MatProgressSpinnerModule],
    providers: [LocalComponentStore],
    templateUrl: "./floating-menu.component.html",
    styleUrls: ["./floating-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatingMenuComponent {
    @Input() public set isCheckinActive(value: BooleanInput) {
        this.localStore.patchState({
            isCheckinActive: coerceBooleanProperty(value),
        });
    }

    @Input() public set isFloatingMenuHidden(value: BooleanInput) {
        this.localStore.patchState({
            isFloatingMenuHidden: coerceBooleanProperty(value),
        });
    }

    @Input() public set isLoading(value: BooleanInput) {
        this.localStore.patchState({
            isLoading: coerceBooleanProperty(value),
        });
    }

    @Output() public readonly menuOptionActionClicked = new EventEmitter<FloatingMenuActionName>();

    protected readonly isOpen$ = this.localStore.selectByKey("isOpen");
    protected readonly isCheckinActive$ = this.localStore.selectByKey("isCheckinActive");
    protected readonly isFloatingMenuHidden$ = this.localStore.selectByKey("isFloatingMenuHidden");
    protected readonly isLoading$ = this.localStore.selectByKey("isLoading");
    protected readonly FloatingMenuActionName = FloatingMenuActionName;

    constructor(private readonly localStore: LocalComponentStore<FloatingMenuComponentState>, private readonly router: Router) {
        localStore.setState({
            isOpen: false,
            isCheckinActive: false,
            isFloatingMenuHidden: false,
            isLoading: false,
        });
    }

    protected toggleOpen() {
        this.localStore.patchState({
            isOpen: !this.localStore.selectSnapshotByKey("isOpen"),
        });
    }

    protected navigateTo(navigateUrl: string) {
        this.toggleOpen();
        this.router.navigate([navigateUrl]);
    }

    protected menuOptionActionClickedHandler(action: FloatingMenuActionName) {
        this.toggleOpen();
        if (action === FloatingMenuActionName.OpenStatusForm) {
            this.router.navigate(["/flight-status-form"]);

            return;
        }
        this.menuOptionActionClicked.emit(action);
    }
}
