import { Injectable } from "@angular/core";
import { LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { StorageService } from "../storage/storage.service";

const LANGUAGE_STORAGE_KEY = "language";

@Injectable({
    providedIn: "root",
})
export class LanguageStorageService {
    constructor(private readonly storageService: StorageService<string>) {}

    public async setLanguage(value: LanguageCode) {
        return await this.storageService.setKey(LANGUAGE_STORAGE_KEY, value);
    }

    public async getLanguage() {
        return await this.storageService.getKey(LANGUAGE_STORAGE_KEY);
    }
}
