<ng-container
    *ngrxLet="{
        activePlannedActivities: activePlannedActivities$,
        inactivePlannedActivities: inactivePlannedActivities$,
        today: today$,
        tomorrow: tomorrow$
    } as vm"
>
    <dtm-ui-label-value [label]="'droneTowerMobileLib.airspaceDetails.orderedActivityLabel' | transloco">
        <span class="time-range">
            <drone-tower-mobile-lib-geozone-date [date]="vm.today"></drone-tower-mobile-lib-geozone-date>
            -
            <drone-tower-mobile-lib-geozone-date [date]="vm.tomorrow"></drone-tower-mobile-lib-geozone-date>
        </span>
    </dtm-ui-label-value>
    <drone-tower-mobile-lib-planned-activities
        [plannedActivities]="vm.activePlannedActivities"
        *ngIf="vm.activePlannedActivities?.length; else noActivitiesInTimeRange"
    ></drone-tower-mobile-lib-planned-activities>
    <dtm-ui-label-value
        [label]="'droneTowerMobileLib.airspaceDetails.possibleMaxActivityRangeLabel' | transloco"
        [labelHint]="'droneTowerMobileLib.airspaceDetails.possibleMaxActivityRangeHint' | transloco"
    >
        <span class="activity-date">
            {{ "droneTowerMobileLib.airspaceDetails.afterLabel" | transloco }}
            <drone-tower-mobile-lib-geozone-date [date]="vm.tomorrow"></drone-tower-mobile-lib-geozone-date>
        </span>
    </dtm-ui-label-value>
    <drone-tower-mobile-lib-planned-activities
        [plannedActivities]="vm.inactivePlannedActivities"
        *ngIf="vm.inactivePlannedActivities?.length; else noActivitiesInTimeRange"
    ></drone-tower-mobile-lib-planned-activities>

    <p class="label">{{ "droneTowerMobileLib.airspaceDetails.nextTimeRangeInfoMissingLabel" | transloco }}</p>
</ng-container>
<ng-template #noActivitiesInTimeRange>
    <dtm-ui-info-message type="info">
        {{ "droneTowerMobileLib.airspaceDetails.noActivitiesInTimeRange" | transloco }}
    </dtm-ui-info-message>
</ng-template>
