import { inject, Injectable } from "@angular/core";
import { Action, NgxsOnInit, Selector, State, StateContext } from "@ngxs/store";
import { AuthService } from "../../services/auth/auth.service";
import { DroneTowerMobileActions } from "../drone-tower-mobile.actions";
import { AuthActions } from "./auth.actions";
import { AuthStateModel } from "./auth.state.model";

export const DEFAULT_STATE: AuthStateModel = {
    isLoggedIn: false,
    userId: undefined,
    accessToken: undefined,
    ttl: undefined,
    createAt: undefined,
};

@State<AuthStateModel>({
    name: "auth",
    defaults: DEFAULT_STATE,
})
@Injectable()
export class AuthState implements NgxsOnInit {
    private readonly authService = inject(AuthService);

    @Selector()
    public static isLoggedIn(state: AuthStateModel): boolean | undefined {
        return state.isLoggedIn;
    }

    @Selector()
    public static accessToken(state: AuthStateModel): string | undefined {
        return state.accessToken;
    }

    public async ngxsOnInit() {
        await this.authService.init();
    }

    @Action(AuthActions.Login, { cancelUncompleted: true })
    public login(context: StateContext<AuthStateModel>, action: AuthActions.Login) {
        this.authService.login(action.credentials);

        context.patchState({
            isLoggedIn: true,
            ...action.credentials,
        });

        context.dispatch(new DroneTowerMobileActions.RefreshAppState());
    }

    @Action(AuthActions.Logout)
    public logout(context: StateContext<AuthStateModel>) {
        this.authService.logout();

        context.patchState(DEFAULT_STATE);
    }
}
