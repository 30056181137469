import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    Output,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BaseIconOptions, DomUtil, LeafletMouseEvent, Map, ZoomPanOptions } from "leaflet";
import { RotatedMarker } from "leaflet-marker-rotation";
import { distinctUntilChanged, tap } from "rxjs";
import { GeolocationService, SharedMapUtils } from "../../../shared/index";
import { DeviceOrientationService, OrientationDetails, OrientationType } from "../../../shared/services/device-orientation.service";
import { Position } from "../../../shared/services/geolocation.service";
import { LEAFLET_MAP_CONFIG, LEAFLET_MAP_PROVIDER, LeafletMapConfig, LeafletMapProvider } from "../../leaflet-map.tokens";
import { LeafletMarkerDirective } from "../leaflet-marker/leaflet-marker.directive";
import { LeafletTooltipContentDirective } from "../leaflet-tooltip-content/leaflet-tooltip-content.directive";

interface LeafletUserPositionComponentState {
    userPosition: Position | undefined;
    markerInitialized: boolean;
    userDeviceOrientation: number | undefined;
}

const ICON_OPTIONS: BaseIconOptions = {
    iconUrl: "assets/images/map/marker.png",
    /* eslint-disable no-magic-numbers */
    iconSize: [32, 32],
    iconAnchor: [16, 14],
    /* eslint-enable */
};
const POINT_POSITION_CLASS = "point-position";

@UntilDestroy()
@Component({
    selector: "dtm-map-leaflet-user-position",
    templateUrl: "leaflet-user-position.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class LeafletUserPositionComponent implements OnDestroy {
    @ViewChild("moveToUserPositionButton", { static: true }) public moveToUserPositionButton!: TemplateRef<HTMLElement>;
    @ViewChild(LeafletMarkerDirective, { static: true }) public userPositionMarker!: LeafletMarkerDirective;

    @ContentChild(LeafletTooltipContentDirective, { static: true }) public tooltipContent!: LeafletTooltipContentDirective;

    @Input() public arePositionChangesEnabled = false;
    @Input() public isDeviceOrientationEnabled = true;
    @Input() public isGeoLocationEnabled = true;
    @Input() public isInitialZoomAnimationEnabled = false;
    @Input() public arePanAnimationsEnabled = false;
    @Input() public isMarkerInteractive = true;
    @Input() public set userPosition(value: Position | undefined) {
        this.localStore.patchState({ userPosition: value });
    }

    @Output() private geoLocationDeny: EventEmitter<void> = new EventEmitter<void>();
    @Output() private userPositionIndicate: EventEmitter<void> = new EventEmitter<void>();
    @Output() private userPositionUpdate: EventEmitter<Position> = new EventEmitter<Position>();

    public readonly userPosition$ = this.localStore.selectByKey("userPosition");
    public readonly userDeviceOrientation$ = this.localStore.selectByKey("userDeviceOrientation");
    public readonly iconOptions = ICON_OPTIONS;

    private map!: Map;

    constructor(
        @Inject(LEAFLET_MAP_CONFIG) public readonly config: LeafletMapConfig,
        private readonly deviceOrientationService: DeviceOrientationService,
        private readonly geolocationService: GeolocationService,
        private readonly localStore: LocalComponentStore<LeafletUserPositionComponentState>,
        @Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider
    ) {
        this.localStore.setState({
            userPosition: undefined,
            userDeviceOrientation: undefined,
            markerInitialized: false,
        });

        this.mapProvider.getMap().then((map) => {
            this.map = map;
            this.getUserGeoLocation();
            this.getUsersDeviceOrientation();
            this.onUserPositionUpdate();
            this.addPointerClassIfUserPositionIsNotSet();

            this.map.on("click", this.setUserPositionOnClick, this);
        });
    }

    public ngOnDestroy() {
        this.map.off("click", this.setUserPositionOnClick, this);
    }

    public moveToPosition(position: Position | undefined, zoom?: number, zoomPanOptions?: ZoomPanOptions): void {
        if (position) {
            this.map?.flyTo([position.latitude, position.longitude], zoom, zoomPanOptions);
        }
    }

    public onMarkerInitialized(marker: RotatedMarker): void {
        this.localStore.patchState({ markerInitialized: true });
        this.moveToPosition(this.localStore.selectSnapshotByKey("userPosition"), this.config.zoom.userPosition, {
            animate: this.isInitialZoomAnimationEnabled,
        });

        if (DomUtil.hasClass(this.map.getContainer(), POINT_POSITION_CLASS)) {
            DomUtil.removeClass(this.map.getContainer(), POINT_POSITION_CLASS);
        }

        if (this.tooltipContent) {
            marker.bindTooltip(this.tooltipContent.elem.nativeElement, this.tooltipContent.tooltipOptions);
        }

        // NOTE: Required to keep user position marker always on top of other map elements
        this.userPositionMarker.setMarkerZIndexOffset(1000);
    }

    public onMarkerPositionUpdated(position: Position): void {
        this.moveToPosition(position, undefined, { animate: this.arePanAnimationsEnabled });
        this.localStore.patchState({ userPosition: position });
    }

    private onUserPositionUpdate(): void {
        this.userPosition$
            .pipe(
                RxjsUtils.filterFalsy(),
                distinctUntilChanged((previous, current) => SharedMapUtils.arePositionsEqual(previous, current)),
                tap((userPosition) => this.userPositionUpdate.emit(userPosition)),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private getUserGeoLocation(): void {
        if (!this.isGeoLocationEnabled) {
            return;
        }

        this.geolocationService
            .getUserPosition()
            .then((position: Position) => {
                if (this.localStore.selectSnapshotByKey("userPosition")) {
                    return;
                }

                this.localStore.patchState({ userPosition: position });
            })
            .catch(() => this.geoLocationDeny.emit());
    }

    private getUsersDeviceOrientation(): void {
        if (!this.isDeviceOrientationEnabled) {
            return;
        }

        this.deviceOrientationService
            .getDeviceOrientation()
            .pipe(
                tap((orientation) =>
                    this.localStore.patchState({ userDeviceOrientation: this.transformOrientationToCompassHeading(orientation) })
                ),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private addPointerClassIfUserPositionIsNotSet(): void {
        if (this.localStore.selectSnapshotByKey("userPosition") === undefined) {
            DomUtil.addClass(this.map.getContainer(), POINT_POSITION_CLASS);
        }
    }

    private transformOrientationToCompassHeading({ value, type }: OrientationDetails): number {
        const iconOffset = 200;

        return (type === OrientationType.Clockwise ? value : value * -1) + iconOffset;
    }

    private setUserPositionOnClick(event: LeafletMouseEvent): void {
        if (this.localStore.selectSnapshotByKey("markerInitialized")) {
            return;
        }

        this.localStore.patchState({ userPosition: { latitude: event.latlng.lat, longitude: event.latlng.lng } });
        this.userPositionIndicate.emit();
    }
}
