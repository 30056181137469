import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MatSlideToggleChange, MatSlideToggleModule } from "@angular/material/slide-toggle";
import { TilesetStyle } from "@dtm-frontend/shared/map/leaflet";
import { TranslocoModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { map } from "rxjs";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { DroneTowerMobileActions } from "../../state/drone-tower-mobile.actions";
import { DroneTowerMobileState } from "../../state/drone-tower-mobile.state";
import { DroneTowerFeatures } from "../../state/drone-tower-mobile.state.model";

@Component({
    selector: "drone-tower-mobile-lib-checkins-history",
    standalone: true,
    imports: [CommonModule, TranslocoModule, HeaderComponent, MatSlideToggleModule, PushModule, LetModule],
    templateUrl: "./airspace.component.html",
    styleUrls: ["./airspace.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AirspaceComponent {
    private readonly store = inject(Store);

    protected readonly TilesetStyle = TilesetStyle;
    protected readonly tilesetStyle$ = this.store.select(DroneTowerMobileState.tilesetStyle);
    protected readonly areOtherCheckinsVisible$ = this.store.select(DroneTowerMobileState.areOtherCheckinsVisible);
    protected readonly areBvlosMissionsVisible$ = this.store
        .select(DroneTowerMobileState.flightStatusParams)
        .pipe(map((flightStatusParams) => flightStatusParams.areBvlosMissionsVisible));
    protected readonly areActiveMissionAreasVisible$ = this.store.select(DroneTowerMobileState.areActiveMissionAreasVisible);
    protected readonly isBvlosMissionsFeatureAvailable$ = this.store.select(
        DroneTowerMobileState.isFeatureAvailable(DroneTowerFeatures.BvlosMissions)
    );
    protected readonly isTileStyleFeatureAvailable$ = this.store.select(
        DroneTowerMobileState.isFeatureAvailable(DroneTowerFeatures.TilesetStyle)
    );

    protected toggleOtherCheckinsVisibility(value: MatSlideToggleChange) {
        this.store.dispatch(new DroneTowerMobileActions.SetOtherCheckinsVisibility(value.checked));
    }

    protected toggleBvlosMissionsVisibility(value: MatSlideToggleChange) {
        this.store.dispatch(new DroneTowerMobileActions.SetFlightStatusParams({ areBvlosMissionsVisible: value.checked }));
    }

    protected toggleOtherCheckinAreasVisibility(value: MatSlideToggleChange) {
        this.store.dispatch(new DroneTowerMobileActions.SetActiveMissionAreasVisibility(value.checked));
    }

    protected setTilesetStyle(tilesetStyle: TilesetStyle) {
        this.store.dispatch(new DroneTowerMobileActions.SetTilesetStyle(tilesetStyle));
    }
}
