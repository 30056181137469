<ng-container *ngIf="activeCheckinDetails$ | ngrxPush as activeCheckinDetails">
    <h2>{{ headerLabel$ | ngrxPush }}</h2>
    <p class="status">
        {{ statusTextLabel$ | ngrxPush }}:
        <span [ngClass]="activeCheckinDetails.status">
            {{ statusLabel$ | ngrxPush }}
        </span>
    </p>
    <div class="grid-container">
        <div class="flex-container">
            <dtm-ui-icon name="flight-takeoff"></dtm-ui-icon>
            <span>{{ activeCheckinDetails.startDate | localizeDate : { hour: "numeric", minute: "numeric" } }}</span>
        </div>
        <div class="flex-container">
            <dtm-ui-icon name="time"></dtm-ui-icon>
            <span>{{ activeCheckinDetails.startDate | datesDiff : activeCheckinDetails.stopDate }}</span>
        </div>
        <div class="flex-container">
            <dtm-ui-icon name="complex-top-height"></dtm-ui-icon>
            <span>
                {{ activeCheckinDetails.maxHeight }}
                {{ metersAgl$ | ngrxPush }}
            </span>
        </div>
        <div class="flex-container" *ngIf="activeCheckinDetails.radius">
            <dtm-ui-icon name="complex-radius"></dtm-ui-icon>
            <span>
                {{ activeCheckinDetails.radius }}
                {{ meters$ | ngrxPush }}
            </span>
        </div>
    </div>
    <hr />
    <div class="flex-container">
        <dtm-ui-icon name="treasure-map"></dtm-ui-icon>
        <span>
            {{ activeCheckinDetails.latitudeDegreesMinutesSeconds }}
            {{ activeCheckinDetails.longitudeDegreesMinutesSeconds }}
        </span>
        <button
            class="button-icon"
            type="button"
            (click)="
                copyToClipboard([activeCheckinDetails.latitudeDegreesMinutesSeconds, activeCheckinDetails.longitudeDegreesMinutesSeconds])
            "
        >
            <dtm-ui-icon name="file-copy"></dtm-ui-icon>
        </button>
    </div>
    <div class="flex-container" *ngIf="activeCheckinDetails.phoneNumber | formatPhoneNumber as phoneNumber">
        <dtm-ui-icon name="phone"></dtm-ui-icon>
        <span>{{ phoneNumber }}</span>
        <button class="button-icon" type="button" (click)="copyToClipboard([phoneNumber])">
            <dtm-ui-icon name="file-copy"></dtm-ui-icon>
        </button>
    </div>
</ng-container>
