<div class="container" *ngrxLet="{ isProcessing: isProcessing$, currentStep: currentStep$, isTooltipOpen: isTooltipOpen$ } as vm">
    <img class="logo" src="assets/images/logo.svg" alt="logo" />
    <form [formGroup]="pilotDataForm" class="form-container" (ngSubmit)="onSubmit()" *ngIf="vm.currentStep === PilotDataSteps.Form">
        <div class="form-container-item title">
            <h2>
                {{ "droneTowerMobileLibLogin.pilotData.title" | transloco }}
            </h2>
            <dtm-ui-icon class="tooltip-icon" name="information" (click)="toggleTooltip()"></dtm-ui-icon>
        </div>
        <div *ngIf="isTooltipOpen$ | ngrxPush" class="tooltip">
            <div class="tooltip-item">
                {{ "droneTowerMobileLibLogin.pilotData.tooltip" | transloco }}
            </div>
        </div>

        <drone-tower-mobile-lib-name-input-field
            class="form-container-item"
            [label]="'droneTowerMobileLibLogin.firstNameLabel' | transloco"
            [control]="pilotDataForm.controls.firstName"
        ></drone-tower-mobile-lib-name-input-field>
        <drone-tower-mobile-lib-name-input-field
            class="form-container-item"
            [label]="'droneTowerMobileLibLogin.lastNameLabel' | transloco"
            [control]="pilotDataForm.controls.lastName"
        ></drone-tower-mobile-lib-name-input-field>

        <dtm-ui-phone-number-field [formControl]="pilotDataForm.controls.phoneNumber" class="form-container-item">
            <div class="field-error" *dtmUiFieldHasError="pilotDataForm.controls.phoneNumber; name: 'required'">
                {{ "droneTowerMobileLibLogin.requiredErrorMessage" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="pilotDataForm.controls.phoneNumber; name: 'uniqueServerError'">
                {{ "droneTowerMobileLibLogin.genericErrorMessage" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="pilotDataForm.controls.phoneNumber; name: 'invalidNumber'">
                {{ "droneTowerMobileLibLogin.invalidPhoneNumberErrorMessage" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="pilotDataForm.controls.phoneNumber; name: 'invalidNumberType'">
                {{ "droneTowerMobileLibLogin.invalidNumberTypeErrorMessage" | transloco }}
            </div>
        </dtm-ui-phone-number-field>
        <div *ngrxLet="isProcessing$ as isProcessing" class="form-container-item">
            <button type="submit" class="button-primary" [disabled]="isProcessing">
                <mat-spinner *ngIf="isProcessing" [diameter]="20" color="accent"></mat-spinner>
                <ng-container *ngIf="!isProcessing">
                    {{ "droneTowerMobileLibLogin.pilotData.nextLabel" | transloco }}
                </ng-container>
            </button>
        </div>
        <p class="form-container-item redirect">
            <a routerLink="/login">
                {{ "droneTowerMobileLibLogin.pilotData.backToLogin" | transloco }}
            </a>
        </p>
    </form>
    <drone-tower-mobile-lib-pilot-data-phone-verification
        [phoneNumber]="pilotDataForm.controls.phoneNumber.value"
        *ngIf="vm.currentStep === PilotDataSteps.PhoneVerification"
        (backRedirect)="redirectToStep(PilotDataSteps.Form)"
        (verify)="verify($event)"
    ></drone-tower-mobile-lib-pilot-data-phone-verification>
</div>
