import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { TranslocoModule } from "@jsverse/transloco";
import { PushModule } from "@ngrx/component";
import { first } from "rxjs";

@Component({
    selector: "drone-tower-mobile-lib-check-in-ends-soon-dialog",
    standalone: true,
    imports: [CommonModule, MatDialogModule, TranslocoModule, PushModule],
    templateUrl: "./check-in-ends-soon-dialog.component.html",
    styleUrls: ["../../../styles/simple-check-in-dialog.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckInEndsSoonDialogComponent {
    private readonly translationHelperService = inject(TranslationHelperService);
    protected readonly translationsReady$ = this.translationHelperService
        .waitForTranslation("droneTowerMobileLib.checkInEndsSoonDialog.headerLabel")
        .pipe(first());
}
