import { Inject, inject, Injectable, Injector } from "@angular/core";
import { isSupported, Messaging } from "@angular/fire/messaging";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { getToken, onMessage } from "firebase/messaging";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom, Subject } from "rxjs";
import { AppInfoService } from "../app-info/app-info.service";
import { FIREBASE_CONFIG, FirebaseConfig } from "../firebase/firebase-config.model";
import { NotificationEventName, PushNotificationHandlerService } from "./push-notifications-handler.service";
import { PushNotificationPermissions } from "./push-notifications.service";

@UntilDestroy()
@Injectable({
    providedIn: "root",
})
export class WebNotificationsService {
    private readonly toastrService = inject(ToastrService);
    private readonly translationHelperService = inject(TranslationHelperService);
    private readonly token = new Subject<string>();
    private readonly appInfoService = inject(AppInfoService);
    public readonly token$ = this.token.asObservable();
    private messaging?: Messaging;

    constructor(
        @Inject(FIREBASE_CONFIG) private readonly firebaseConfig: FirebaseConfig,
        private injector: Injector,
        private readonly pushNotificationHandlerService: PushNotificationHandlerService
    ) {}

    public async initWebNotifications() {
        if (!(await isSupported())) {
            return;
        }

        this.messaging = this.injector.get(Messaging);
        if (!this.messaging) {
            return;
        }
        this.registerNotifications();
    }

    private async registerNotifications() {
        const permStatus = await Notification.requestPermission();
        if (permStatus !== PushNotificationPermissions.Granted) {
            const deniedMessage = await firstValueFrom(
                this.translationHelperService.waitForTranslation("droneTowerMobileLib.pushNotificationDeniedErrorMessage")
            );
            this.toastrService
                .error(deniedMessage)
                .onTap.pipe(untilDestroyed(this))
                .subscribe(() => this.appInfoService.openAppSettings());

            return;
        }

        this.getFcmToken();
        this.addWebListener();
    }

    private getFcmToken() {
        navigator.serviceWorker
            .register("firebase-messaging-sw.js", {
                type: "module",
                scope: "__",
            })
            .then(async (serviceWorkerRegistration) => {
                if (!this.messaging) {
                    return;
                }

                this.token.next(
                    await getToken(this.messaging, {
                        serviceWorkerRegistration,
                        vapidKey: this.firebaseConfig.vapidKey,
                    })
                );
            });
    }

    private addWebListener() {
        if (!this.messaging) {
            return;
        }
        onMessage(this.messaging, (payload) => {
            const data = payload.data;
            if (!data) {
                return;
            }
            const status = this.pushNotificationHandlerService.convertMessageDataToStatus(payload.data);
            const eventName = data.eventName as NotificationEventName;
            const properties = this.pushNotificationHandlerService.convertNotificationToActiveCheckinProperties(payload.notification);
            this.pushNotificationHandlerService.handleNotification(status, properties, eventName);
        });
    }
}
