import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IconDirective } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { PushModule } from "@ngrx/component";

interface H24ComponentState {
    isH24: boolean;
}

@Component({
    selector: "drone-tower-mobile-lib-h24",
    standalone: true,
    imports: [CommonModule, PushModule, TranslocoModule, IconDirective],
    templateUrl: "./h24.component.html",
    styleUrls: ["./h24.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class H24Component {
    @Input() public set isH24(value: BooleanInput) {
        this.localStore.patchState({ isH24: coerceBooleanProperty(value) });
    }
    protected readonly isH24$ = this.localStore.selectByKey("isH24");

    constructor(private readonly localStore: LocalComponentStore<H24ComponentState>) {
        this.localStore.setState({ isH24: false });
    }
}
