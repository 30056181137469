import {
    ConfirmPasswordRequestPayload,
    ResetPasswordRequestPayload,
    ValidateEmailRequestPayload,
} from "../../services/reset-password/reset-password.model";

export namespace ResetPasswordActions {
    export class ResetPassword {
        public static readonly type = "[Reset Password] Reset password";
        constructor(public resetPasswordRequestPayload: ResetPasswordRequestPayload) {}
    }

    export class ValidateEmail {
        public static readonly type = "[Reset Password] Validate Email";
        constructor(public validateEmailRequestPayload: ValidateEmailRequestPayload) {}
    }

    export class ConfirmPassword {
        public static readonly type = "[Reset Password] Confirm Password";
        constructor(public confirmPasswordRequestPayload: ConfirmPasswordRequestPayload) {}
    }
}
