import { HttpClient, HttpParams } from "@angular/common/http";

import { inject, Injectable } from "@angular/core";

import { StringUtils } from "@dtm-frontend/shared/utils";

import { catchError, map, Observable, throwError } from "rxjs";

import {
    CheckinHistory,
    CheckinHistoryErrorType,
    CheckinHistoryListWithPageable,
    CheckinsHistoryParams,
    CheckinsHistoryUserNoteChange,
} from "../../models/checkins.model";

import {
    CheckinHistoryResponseBody,
    CheckinHistoryResponseBodyWithPages,
    convertGetCheckinHistoryResponseBodyToCheckinHistory,
    convertGetCheckinHistoryResponseBodyToCheckinHistoryListWithPageable,
} from "./checkins-hisotry.converters";

import { CHECKINS_HISTORY_ENDPOINTS_API_TOKENS } from "./checkins-history-api.tokens";

@Injectable({
    providedIn: "root",
})
export class CheckinsHistoryService {
    private readonly http = inject(HttpClient);
    private readonly apiToken = inject(CHECKINS_HISTORY_ENDPOINTS_API_TOKENS);

    public getCheckinsHistory(params: CheckinsHistoryParams): Observable<CheckinHistoryListWithPageable> {
        let httpParams = new HttpParams()
            .set("size", "10")
            .set("sort", "startDateTime,desc")
            .set("page", params?.page?.toString() ?? "0");
        if (params?.dateFilters?.dateFrom) {
            httpParams = httpParams.append("startDateTimeFrom", `${params.dateFilters.dateFrom.toISOString()}`);
        }
        if (params?.dateFilters?.dateTo) {
            // eslint-disable-next-line no-magic-numbers
            const midnightDateTo = new Date(params?.dateFilters?.dateTo.setHours(23, 59, 59, 999));
            httpParams = httpParams.append("endDateTimeTo", `${midnightDateTo.toISOString()}`);
        }

        return this.http.get<CheckinHistoryResponseBodyWithPages>(this.apiToken.getCheckins, { params: httpParams }).pipe(
            map((response) => convertGetCheckinHistoryResponseBodyToCheckinHistoryListWithPageable(response)),
            catchError(() => throwError(() => ({ type: CheckinHistoryErrorType.Unknown })))
        );
    }

    public deleteCheckinHistoryUserNote(checkinId: string): Observable<void> {
        return this.http
            .delete<void>(StringUtils.replaceInTemplate(this.apiToken.manageCheckinHistoryNote, { checkinId }))
            .pipe(catchError(() => throwError(() => ({ type: CheckinHistoryErrorType.Unknown }))));
    }

    public addCheckinHistoryUserNote(note: CheckinsHistoryUserNoteChange): Observable<CheckinHistory> {
        return this.http
            .post<CheckinHistoryResponseBody>(
                StringUtils.replaceInTemplate(this.apiToken.manageCheckinHistoryNote, { checkinId: note.checkinId }),
                {
                    text: note.noteValue,
                }
            )
            .pipe(
                map((response: CheckinHistoryResponseBody) => convertGetCheckinHistoryResponseBodyToCheckinHistory(response)),
                catchError(() => throwError(() => ({ type: CheckinHistoryErrorType.Unknown })))
            );
    }

    public updateCheckinHistoryUserNote(note: CheckinsHistoryUserNoteChange): Observable<CheckinHistory> {
        return this.http
            .put<CheckinHistoryResponseBody>(
                StringUtils.replaceInTemplate(this.apiToken.manageCheckinHistoryNote, { checkinId: note.checkinId }),
                {
                    text: note.noteValue,
                }
            )
            .pipe(
                map((response: CheckinHistoryResponseBody) => convertGetCheckinHistoryResponseBodyToCheckinHistory(response)),
                catchError(() => throwError(() => ({ type: CheckinHistoryErrorType.Unknown })))
            );
    }
}
