import { Pipe, PipeTransform } from "@angular/core";
/*
 * Removes suffix -6KM from geo zone, if present.
 * In the context of the user interface it is unnecessary information.
 */
@Pipe({
    name: "geoZone",
    standalone: true,
})
export class GeoZonePipe implements PipeTransform {
    public transform(value: string): string {
        return value.replace(/-6KM/gi, "");
    }
}
