import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";
import { Clipboard } from "@capacitor/clipboard";
import { IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule, TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { PushModule } from "@ngrx/component";
import { switchMap } from "rxjs";
import { ActiveCheckinProperties } from "../../models/checkins.model";

interface ActiveCheckinDetailsPopupComponentState {
    activeCheckinDetails: ActiveCheckinProperties | null;
}

@Component({
    selector: "drone-tower-mobile-lib-info",
    standalone: true,
    imports: [CommonModule, SharedI18nModule, IconDirective, SharedUiModule, PushModule],
    templateUrl: "./active-checkin-details-popup.component.html",
    styleUrls: ["./active-checkin-details-popup.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ActiveCheckinDetailsPopupComponent {
    private readonly translationHelperService = inject(TranslationHelperService);

    @Input() public set activeCheckinDetails(value: ActiveCheckinProperties | null) {
        this.localStore.patchState({
            activeCheckinDetails: value,
        });
    }

    public get activeCheckinDetails() {
        return this.localStore.selectSnapshotByKey("activeCheckinDetails");
    }

    protected readonly activeCheckinDetails$ = this.localStore.selectByKey("activeCheckinDetails");

    // TODO: DTOWER-383 Global Translation Issue
    protected readonly headerLabel$ = this.translationHelperService.waitForTranslation(
        "droneTowerMobileLib.activeCheckinDetails.headerLabel"
    );
    protected readonly statusTextLabel$ = this.translationHelperService.waitForTranslation(
        "droneTowerMobileLib.activeCheckinDetails.statusTextLabel"
    );
    protected readonly statusLabel$ = this.activeCheckinDetails$.pipe(
        switchMap((checkin) =>
            this.translationHelperService.waitForTranslation("droneTowerMobileLib.activeCheckinDetails.statusLabel", {
                value: checkin?.status,
            })
        )
    );
    protected readonly metersAgl$ = this.translationHelperService.waitForTranslation(
        "droneTowerMobileLib.flightStatusForm.units.metersAgl"
    );
    protected readonly meters$ = this.translationHelperService.waitForTranslation("droneTowerMobileLib.flightStatusForm.units.meters");

    constructor(private readonly localStore: LocalComponentStore<ActiveCheckinDetailsPopupComponentState>) {
        this.localStore.setState({
            activeCheckinDetails: null,
        });
    }

    protected async copyToClipboard(valueToCopy: string[]) {
        await Clipboard.write({ string: valueToCopy.join() });
    }
}
