<drone-tower-mobile-lib-header [headerLabel]="'droneTowerMobileLib.help.title' | transloco"></drone-tower-mobile-lib-header>

<div class="wrapper scroll-shadows">
    <p>{{ "droneTowerMobileLib.help.description" | transloco }}</p>
    <p class="highlight">{{ "droneTowerMobileLib.help.localizationNotificationPermissions" | transloco }}</p>
    <div class="divider"></div>
    <dtm-ui-label-value class="label" [label]="'droneTowerMobileLib.help.downloadLabel' | transloco">
        <a target="_blank" [href]="DRONE_TOWER_USER_GUIDE_URL" class="clickable pdf">
            {{ "droneTowerMobileLib.help.download" | transloco }}
            <dtm-ui-icon name="download"></dtm-ui-icon> </a
    ></dtm-ui-label-value>
    <dtm-ui-label-value [label]="'droneTowerMobileLib.help.usefulLinksLabel' | transloco">
        <div>
            <a target="_blank" href="https://www.pansa.pl/zasady-wykonywania-lotow-bsp-w-kategorii-otwartej/" class="link">
                {{ "droneTowerMobileLib.help.openCategoryRules" | transloco }}
            </a>
        </div>
        <div>
            <a target="_blank" href="https://www.pansa.pl/zasady-wykonywania-lotow-bsp-w-kategorii-szczegolnej/" class="link">
                {{ "droneTowerMobileLib.help.specificCategoryRules" | transloco }}
            </a>
        </div>
        <div>
            <a target="_blank" href="https://www.pansa.pl/strefy-geograficzne/" class="link">
                {{ "droneTowerMobileLib.help.geoZonesInfo" | transloco }}
            </a>
        </div>
    </dtm-ui-label-value>
    <dtm-ui-label-value [label]="'droneTowerMobileLib.help.contactInfoLabel' | transloco">
        <div class="contact">
            <p [innerHTML]="'droneTowerMobileLib.help.contactInfo' | transloco"></p>
            <div>
                <p>{{ "droneTowerMobileLib.help.emergencyPhoneLabel" | transloco }}</p>
                <a href="tel:(22) 574 57 75" class="clickable">(22) 574 57 75 <dtm-ui-icon name="phone"></dtm-ui-icon></a>
            </div>
            <div>
                <p>{{ "droneTowerMobileLib.help.otherPhonesLabel" | transloco }}</p>
                <div>
                    <a href="tel:(22) 574 57 70" class="clickable">(22) 574 57 70 <dtm-ui-icon name="phone"></dtm-ui-icon></a>
                </div>
                <div>
                    <a href="tel:(22) 574 75 53" class="clickable">(22) 574 75 53 <dtm-ui-icon name="phone"></dtm-ui-icon></a>
                </div>
                <div>
                    <a href="tel:(22) 574 57 23" class="clickable">(22) 574 57 23 <dtm-ui-icon name="phone"></dtm-ui-icon></a>
                </div>
                <div>
                    <a href="tel:(22) 574 58 48" class="clickable">(22) 574 58 48 <dtm-ui-icon name="phone"></dtm-ui-icon></a>
                </div>
                <div>
                    <a href="tel:(22) 574 57 49" class="clickable">(22) 574 57 49 <dtm-ui-icon name="phone"></dtm-ui-icon></a>
                </div>
            </div>
            <span [innerHTML]="'droneTowerMobileLib.help.lawContactLabel' | transloco"></span>
        </div>
    </dtm-ui-label-value>
</div>
