import { Injectable } from "@angular/core";
import { App, AppInfo } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { AndroidSettings, IOSSettings, NativeSettings } from "capacitor-native-settings";
import { Observable, from, of } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class AppInfoService {
    public getAppInfo(): Observable<AppInfo> {
        const browserAppInfo: AppInfo = {
            name: "WebApp",
            id: "1",
            build: "1",
            version: "1",
        };

        if (Capacitor.isNativePlatform()) {
            return from(App.getInfo());
        }

        // NOTE: workaround for web development. App info api it's not available on browser
        return of(browserAppInfo);
    }

    public openAppSettings() {
        if (Capacitor.isNativePlatform()) {
            NativeSettings.open({
                optionAndroid: AndroidSettings.ApplicationDetails,
                optionIOS: IOSSettings.App,
            });
        }
    }

    public openLocationSettings() {
        if (Capacitor.isNativePlatform()) {
            NativeSettings.open({ optionAndroid: AndroidSettings.Location, optionIOS: IOSSettings.LocationServices });
        }
    }
}
