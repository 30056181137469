import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { Router, RouterModule } from "@angular/router";
import {
    InvalidFormScrollableDirective,
    PhoneNumber,
    requiredValidForSmsPhoneNumberValidator,
    SharedUiModule,
} from "@dtm-frontend/shared/ui";
import { DEFAULT_LANG, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { DEFAULT_PHONE_COUNTRY_CODE, FormUtils, LocalComponentStore, MILLISECONDS_IN_MINUTE } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetModule, PushModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { combineLatest, first, map, startWith, switchMap } from "rxjs";
import { EnrollmentService } from "../../../../services/enrollment/enrollment.service";
import { ErrorHandlingService } from "../../../../services/error-handling-service/error-handling-service";
import { NameInputFieldComponent } from "../../../../shared/components/name-input-field/name-input-field.component";
import { EnrollmentActions } from "../../../../state/enrollment/enrollment.actions";
import { EnrollmentState } from "../../../../state/enrollment/enrollment.state";
import {
    NAME_MAX_LENGTH_VALIDATION,
    NAME_MIN_LENGTH_VALIDATION,
    NAME_PATTERN_VALIDATION,
    PANSA_UTM_PASSWORD_PATTERN,
    REDIRECT_PARAM,
    REDIRECT_REGISTRATION,
} from "../../../../utils/defaults";
import { EmailVerificationComponent } from "../email-verification/email-verification.component";
import { PhoneVerificationComponent } from "../phone-verification/phone-verification.component";

const TIMEOUT = 1;
const MAX_RETRY_COUNT = 3;
enum RegisterSteps {
    Form = "Form",
    EmailVerification = "EmailVerification",
    PhoneVerification = "PhoneVerification",
}

export interface RegisterForm {
    email: FormControl<string>;
    password: FormControl<string>;
    confirmPassword: FormControl<string>;
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    phoneNumber: FormControl<PhoneNumber>;
}

interface RegisterComponentState {
    retryCount: number;
    isTimeout: boolean;
    currentStep: RegisterSteps;
}

@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-lib-register",
    standalone: true,
    imports: [
        CommonModule,
        SharedUiModule,
        SharedI18nModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        LetModule,
        RouterModule,
        EmailVerificationComponent,
        PhoneVerificationComponent,
        PushModule,
        NameInputFieldComponent,
    ],
    templateUrl: "./register.component.html",
    styleUrls: ["../../shared/onboarding-layout.scss", "./register.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class RegisterComponent {
    private readonly store = inject(Store);
    private readonly localStore = inject(LocalComponentStore<RegisterComponentState>);
    private readonly translocoService = inject(TranslocoService);
    private readonly errorHandlingService = inject(ErrorHandlingService);
    private readonly enrollmentService = inject(EnrollmentService);
    private readonly router = inject(Router);

    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly RegisterSteps = RegisterSteps;
    protected readonly DEFAULT_LANG = DEFAULT_LANG;
    protected readonly DEFAULT_PHONE_COUNTRY_CODE = DEFAULT_PHONE_COUNTRY_CODE;
    protected readonly retryCount$ = this.localStore.selectByKey("retryCount");
    protected readonly currentStep$ = this.localStore.selectByKey("currentStep");
    protected readonly registrationForm = new FormGroup<RegisterForm>(
        {
            email: new FormControl<string>("", {
                validators: [Validators.required, Validators.email],
                asyncValidators: [FormUtils.uniqueValidator(this.enrollmentService.checkEmailAvailability.bind(this.enrollmentService))],
                nonNullable: true,
                updateOn: "blur",
            }),
            password: new FormControl<string>("", {
                validators: [Validators.required, Validators.pattern(PANSA_UTM_PASSWORD_PATTERN)],
                nonNullable: true,
            }),
            confirmPassword: new FormControl<string>("", { validators: Validators.required, nonNullable: true }),
            firstName: new FormControl<string>("", {
                validators: [
                    Validators.required,
                    Validators.minLength(NAME_MIN_LENGTH_VALIDATION),
                    Validators.maxLength(NAME_MAX_LENGTH_VALIDATION),
                    Validators.pattern(NAME_PATTERN_VALIDATION),
                ],
                nonNullable: true,
            }),
            lastName: new FormControl<string>("", {
                validators: [
                    Validators.required,
                    Validators.minLength(NAME_MIN_LENGTH_VALIDATION),
                    Validators.maxLength(NAME_MAX_LENGTH_VALIDATION),
                    Validators.pattern(NAME_PATTERN_VALIDATION),
                ],
                nonNullable: true,
            }),
            phoneNumber: new FormControl<PhoneNumber>(
                {
                    countryCode: DEFAULT_PHONE_COUNTRY_CODE,
                    number: "",
                },
                {
                    validators: requiredValidForSmsPhoneNumberValidator,
                    asyncValidators: [
                        FormUtils.uniqueValidator(this.enrollmentService.checkPhoneNumberAvailability.bind(this.enrollmentService)),
                    ],
                    nonNullable: true,
                }
            ),
        },
        {
            validators: FormUtils.mustMatchValidator("password", "confirmPassword"),
        }
    );
    protected readonly isProcessing$ = combineLatest([
        this.store.select(EnrollmentState.isProcessing),
        this.registrationForm.controls.email.statusChanges.pipe(startWith("INVALID")),
        this.registrationForm.controls.phoneNumber.statusChanges.pipe(startWith("INVALID")),
    ]).pipe(map(([isLoading, emailStatus, phoneStatus]) => isLoading || emailStatus === "PENDING" || phoneStatus === "PENDING"));

    constructor() {
        this.localStore.setState({
            retryCount: 0,
            isTimeout: false,
            currentStep: RegisterSteps.Form,
        });
    }

    protected onSubmit() {
        if (!this.registrationForm.valid) {
            this.registrationForm.markAllAsTouched();
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }
        const retryCount = this.localStore.selectSnapshotByKey("retryCount");
        const isTimeout = this.localStore.selectSnapshotByKey("isTimeout");
        if (retryCount === MAX_RETRY_COUNT) {
            const errorMessage = this.translocoService.translate("droneTowerMobileLibLogin.register.tooManyRetriesMessage", {
                timeout: TIMEOUT,
            });
            this.errorHandlingService.displayMessage({
                errorMessage,
            });

            if (!isTimeout) {
                this.localStore.patchState({
                    isTimeout: true,
                });
                setTimeout(() => {
                    this.localStore.patchState({
                        retryCount: 0,
                        isTimeout: false,
                    });
                }, TIMEOUT * MILLISECONDS_IN_MINUTE);
            }
        } else {
            const controls = this.registrationForm.controls;
            this.store
                .dispatch(
                    new EnrollmentActions.CreateEnrollment({
                        email: controls.email.value,
                        firstName: controls.firstName.value,
                        lastName: controls.lastName.value,
                        phone: controls.phoneNumber.value,
                    })
                )
                .pipe(
                    switchMap(() => this.store.select(EnrollmentState.enrollmentError)),
                    first(),
                    untilDestroyed(this)
                )
                .subscribe((httpErrorResponse) => {
                    if (httpErrorResponse) {
                        const errorMessage = this.translocoService.translate("droneTowerMobileLibLogin.register.genericErrorMessage");
                        this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
                    } else {
                        this.redirectToStep(RegisterSteps.EmailVerification);
                    }
                });
        }
    }

    protected redirectToStep(step: RegisterSteps) {
        this.localStore.patchState({ currentStep: step });
    }

    protected enroll() {
        this.store
            .dispatch(
                new EnrollmentActions.Enroll({
                    password: this.registrationForm.controls.password.value,
                })
            )
            .pipe(
                switchMap(() => this.store.select(EnrollmentState.enrollmentError)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((httpErrorResponse) => {
                if (httpErrorResponse) {
                    const errorMessage = this.translocoService.translate("droneTowerMobileLibLogin.register.genericErrorMessage");
                    this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
                } else {
                    this.router.navigate(["/login"], { queryParams: { [REDIRECT_PARAM]: REDIRECT_REGISTRATION } });
                }
            });
    }
}
