import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RouterModule } from "@angular/router";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetModule, PushModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { first, switchMap } from "rxjs";
import { ErrorHandlingService } from "../../../../services/error-handling-service/error-handling-service";
import { ResetPasswordActions } from "../../../../state/reset-password/reset-password.actions";
import { ResetPasswordState } from "../../../../state/reset-password/reset-password.state";

const CODE_LENGTH_VALIDATION = 6;

interface ResetPasswordEmailVerificationComponentState {
    isTimeout: boolean;
    email: string;
}

@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-lib-reset-password-email-verification[email]",
    standalone: true,
    imports: [
        CommonModule,
        SharedUiModule,
        SharedI18nModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        LetModule,
        PushModule,
        RouterModule,
    ],
    templateUrl: "./reset-password-email-verification.component.html",
    styleUrls: ["../../shared/onboarding-layout.scss", "./reset-password-email-verification.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ResetPasswordEmailVerificationComponent {
    private readonly store = inject(Store);
    private readonly localStore = inject(LocalComponentStore<ResetPasswordEmailVerificationComponentState>);
    private readonly translocoService = inject(TranslocoService);
    private readonly errorHandlingService = inject(ErrorHandlingService);

    @Input() public set email(value: string) {
        this.localStore.patchState({ email: value });
    }

    protected readonly isProcessing$ = this.store.select(ResetPasswordState.isProcessing);
    protected readonly email$ = this.localStore.selectByKey("email");
    protected readonly verificationCodeFormControl = new FormControl("", {
        validators: [Validators.required, Validators.minLength(CODE_LENGTH_VALIDATION)],
        nonNullable: true,
    });

    @Output() public readonly validate = new EventEmitter<string>();

    constructor() {
        this.localStore.setState({
            isTimeout: false,
            email: "",
        });
    }

    protected onSubmit() {
        if (this.verificationCodeFormControl.invalid) {
            this.verificationCodeFormControl.markAsTouched();

            return;
        }
        this.store
            .dispatch(new ResetPasswordActions.ValidateEmail({ code: this.verificationCodeFormControl.value }))
            .pipe(
                switchMap(() => this.store.select(ResetPasswordState.validationError)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((httpErrorResponse) => {
                if (httpErrorResponse) {
                    const errorMessage = this.translocoService.translate("droneTowerMobileLibLogin.apiErrorMessage");
                    this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
                } else {
                    this.validate.emit(this.verificationCodeFormControl.value);
                }
            });
    }

    protected resendCode() {
        this.store
            .dispatch(new ResetPasswordActions.ResetPassword({ email: this.localStore.selectSnapshotByKey("email") }))
            .pipe(
                switchMap(() => this.store.select(ResetPasswordState.emailError)),
                first(),
                RxjsUtils.filterFalsy(),
                untilDestroyed(this)
            )
            .subscribe((httpErrorResponse) => {
                const errorMessage = this.translocoService.translate("droneTowerMobileLibLogin.apiErrorMessage");
                this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
            });
    }
}
