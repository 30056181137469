import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { PhoneNumber, SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { DEFAULT_PHONE_COUNTRY_CODE, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetModule, PushModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { switchMap } from "rxjs";
import { ErrorHandlingService } from "../../../../services/error-handling-service/error-handling-service";
import { EnrollmentActions } from "../../../../state/enrollment/enrollment.actions";
import { EnrollmentState } from "../../../../state/enrollment/enrollment.state";

const CODE_LENGTH_VALIDATION = 6;

interface PilotDataPhoneVerificationComponentState {
    phoneNumber: PhoneNumber | undefined;
}
@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-lib-pilot-data-phone-verification[phoneNumber]",
    standalone: true,
    imports: [CommonModule, SharedUiModule, SharedI18nModule, ReactiveFormsModule, MatProgressSpinnerModule, LetModule, PushModule],
    templateUrl: "./pilot-data-phone-verification.component.html",
    styleUrls: ["../../shared/onboarding-layout.scss", "./pilot-data-phone-verification.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PilotDataPhoneVerificationComponent {
    private readonly store = inject(Store);
    private readonly localStore = inject(LocalComponentStore<PilotDataPhoneVerificationComponentState>);
    private readonly translocoService = inject(TranslocoService);
    private readonly errorHandlingService = inject(ErrorHandlingService);

    @Input() public set phoneNumber(value: PhoneNumber | undefined) {
        this.localStore.patchState({ phoneNumber: value });
    }

    protected readonly DEFAULT_PHONE_COUNTRY_CODE = DEFAULT_PHONE_COUNTRY_CODE;
    protected readonly isProcessing$ = this.store.select(EnrollmentState.isProcessing);
    protected readonly phoneNumber$ = this.localStore.selectByKey("phoneNumber");
    protected readonly verificationCodeFormControl = new FormControl("", {
        validators: [Validators.required, Validators.minLength(CODE_LENGTH_VALIDATION)],
        nonNullable: true,
    });

    @Output() public readonly verify = new EventEmitter<string>();
    @Output() public readonly backRedirect = new EventEmitter<void>();

    constructor() {
        this.localStore.setState({
            phoneNumber: undefined,
        });
    }

    protected onSubmit() {
        if (this.verificationCodeFormControl.invalid) {
            this.verificationCodeFormControl.markAsTouched();

            return;
        }

        this.verify.emit(this.verificationCodeFormControl.value);
    }

    protected resendCode() {
        const phoneNumber = this.localStore.selectSnapshotByKey("phoneNumber");
        if (!phoneNumber) {
            return;
        }

        this.store
            .dispatch(
                new EnrollmentActions.SetPilotData({
                    phoneNumber,
                })
            )
            .pipe(
                switchMap(() => this.store.select(EnrollmentState.setPilotDataError)),
                untilDestroyed(this)
            )
            .subscribe((httpErrorResponse) => {
                if (httpErrorResponse) {
                    const errorMessage = this.translocoService.translate("droneTowerMobileLibLogin.genericErrorMessage");
                    this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
                }
            });
    }
}
