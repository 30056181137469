import { CommonModule } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from "@angular/core";
import { TranslocoModule } from "@jsverse/transloco";
import { HeaderComponent } from "../../shared/components/header/header.component";

@Component({
    selector: "drone-tower-mobile-lib-rules-content",
    standalone: true,
    imports: [CommonModule, HeaderComponent, TranslocoModule],
    templateUrl: "./rules-content.component.html",
    styleUrls: ["./rules-content.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RulesContentComponent implements AfterViewInit {
    @ViewChild("content", { static: false }) public elementRef?: ElementRef;
    public nativeElement?: HTMLElement;

    public ngAfterViewInit() {
        if (this.elementRef) {
            this.nativeElement = this.elementRef?.nativeElement.parentElement;
        }
    }
}
