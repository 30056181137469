<ng-container>
    <h2 mat-dialog-title>{{ data.error | systemTranslation }}</h2>
    <div class="content-container">
        <div mat-dialog-actions>
            <button [mat-dialog-close]="true" type="button" class="button-primary">
                {{ "droneTowerMobileLib.acknowledgeCheckInErrorDialog.confirmButton" | transloco }}
            </button>
        </div>
    </div>
</ng-container>
