import { HttpParams } from "@angular/common/http";
import { Directive, EventEmitter, Inject, Input, OnDestroy, Output } from "@angular/core";
import { LeafletMouseEvent, Map, TileLayer } from "leaflet";
import { LEAFLET_MAP_PROVIDER, LeafletMapProvider } from "../../leaflet-map.tokens";
import { CustomWMSOptions, LeafletMapLayerConfig } from "../../models/leaflet-map.models";
import WMS = TileLayer.WMS;

@Directive({ selector: "dtm-map-leaflet-map-layer[layerConfig]" })
export class LeafletMapLayerDirective implements OnDestroy {
    @Input() public set layerConfig(value: LeafletMapLayerConfig | undefined) {
        if (!value) {
            return;
        }

        this.addMapLayer(value);
    }

    @Output() protected readonly pointSelect = new EventEmitter<HttpParams>();

    private map: Map | undefined;
    private layer!: TileLayer | TileLayer.WMS;

    private prepareGetFeatureInfoParamsOnPointClick = (event: LeafletMouseEvent) => {
        if (!this.map || !this.layer) {
            return;
        }

        const layer = this.layer as WMS;
        const point = this.map.latLngToContainerPoint(event.latlng);
        const size = this.map.getSize();
        const version = layer.wmsParams.version;

        const params: HttpParams = new HttpParams().appendAll({
            request: "GetFeatureInfo",
            service: "WMS",
            srs: "EPSG:4326",
            styles: layer.wmsParams.styles ?? "",
            transparent: layer.wmsParams.transparent ?? false,
            version: version ?? "",
            format: layer.wmsParams.format ?? "",
            bbox: this.map?.getBounds().toBBoxString(),
            height: size.y,
            width: size.x,
            layers: layer.wmsParams.layers,
            query_layers: layer.wmsParams.layers,
            info_format: "application/json",
            CQL_FILTER: (layer.wmsParams as CustomWMSOptions)["CQL_FILTER"] ?? "",
            [version === "1.3.0" ? "i" : "x"]: Math.round(point.x),
            [version === "1.3.0" ? "j" : "y"]: Math.round(point.y),
            feature_count: 50,
        });

        this.pointSelect.emit(params);
    };

    constructor(@Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider) {}

    public ngOnDestroy() {
        this.removeExistingLayer();
    }

    private async addMapLayer(config: LeafletMapLayerConfig) {
        if (!this.map) {
            this.map = await this.mapProvider.getMap();
        }

        this.removeExistingLayer();

        const { type, baseUrl, options } = config;
        if (type === "WMS") {
            this.layer = new WMS(baseUrl, options);
            this.map.on("click", this.prepareGetFeatureInfoParamsOnPointClick);
        } else {
            this.layer = new TileLayer(baseUrl, options);
        }

        this.map.addLayer(this.layer);
    }

    private removeExistingLayer(): void {
        if (this.layer) {
            this.mapProvider.getMap().then((map) => {
                map.removeLayer(this.layer);
                map.off("click", this.prepareGetFeatureInfoParamsOnPointClick);
            });
        }
    }
}
