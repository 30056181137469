import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { IndividualConfig, ToastrService } from "ngx-toastr";

export interface ErrorToDisplay {
    httpErrorResponse?: HttpErrorResponse;
    errorMessage?: string;
    options?: Partial<IndividualConfig>;
}

@Injectable({
    providedIn: "root",
})
export class ErrorHandlingService {
    private readonly toastrService = inject(ToastrService);
    private readonly translationHelperService = inject(TranslationHelperService);

    public async displayMessage({ httpErrorResponse, errorMessage, options }: ErrorToDisplay) {
        if (httpErrorResponse?.status === HttpStatusCode.Unauthorized || httpErrorResponse?.status === HttpStatusCode.TooManyRequests) {
            return;
        }
        const errorKey = httpErrorResponse?.error?.message;
        const toastrMessage = errorKey ? this.translationHelperService.selectSystemTranslation(errorKey) : errorMessage;

        if (toastrMessage) {
            this.toastrService.error(toastrMessage, undefined, options);
        }
    }
}
