import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { PhoneNumber } from "@dtm-frontend/shared/ui";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { map, Observable } from "rxjs";
import {
    PilotOperatorResponseBody,
    transformPilotOperatorResponseBodyToPilotOperator,
    transformPilotOperatorToPilotOperatorUpsertPilotOperatorRequestPayload,
} from "./pilot-operator.converter";
import { PilotOperator } from "./pilot-operator.model";
import { PilotDataResponseBody, transformPilotDataResponseBodyToPilotData } from "./pilot-profile-api.converter";
import {
    ConfirmPilotDataRequestPayload,
    PilotDetailsRequestPayload,
    SetPilotDataRequestPayload,
    SetPilotDataResponseBody,
} from "./pilot-profile-api.model";
import { PILOT_PROFILE_ENDPOINTS_API_TOKENS } from "./pilot-profile-api.tokens";

@Injectable({
    providedIn: "root",
})
export class PilotProfileApiService {
    private readonly http = inject(HttpClient);
    private readonly apiToken = inject(PILOT_PROFILE_ENDPOINTS_API_TOKENS);

    public getPilotData() {
        return this.http
            .get<PilotDataResponseBody>(this.apiToken.getPilotData)
            .pipe(map((response) => transformPilotDataResponseBodyToPilotData(response)));
    }

    public setPilotDetails(setPilotDetailsRequestPayload: PilotDetailsRequestPayload) {
        return this.http.put(this.apiToken.setPilotDetails, setPilotDetailsRequestPayload);
    }

    public requestPhoneNumberChange(phoneNumber: PhoneNumber): Observable<void> {
        return this.http.put<void>(this.apiToken.requestPhoneNumberChange, {
            phoneNumber,
        });
    }

    public verifyPhoneNumber(verificationCode: string): Observable<void> {
        return this.http.post<void>(this.apiToken.verifyPhoneNumber, {
            code: verificationCode,
        });
    }

    public setPilotData(setPilotDataRequestPayload: SetPilotDataRequestPayload) {
        return this.http.post<SetPilotDataResponseBody>(this.apiToken.missingInfoUpdate, setPilotDataRequestPayload);
    }

    public confirmPilotData(id: string, confirmPilotDataRequestPayload: ConfirmPilotDataRequestPayload) {
        return this.http.post(
            StringUtils.replaceInTemplate(this.apiToken.confirmMissingInfoUpdate, { id }),
            confirmPilotDataRequestPayload
        );
    }

    public getPilotOperators() {
        return this.http
            .get<{ pilotOperators: PilotOperatorResponseBody[] }>(this.apiToken.pilotOperators)
            .pipe(
                map((response) =>
                    response.pilotOperators.map((pilotOperator) => transformPilotOperatorResponseBodyToPilotOperator(pilotOperator))
                )
            );
    }

    public addPilotOperator(pilotOperator: PilotOperator) {
        const payload = transformPilotOperatorToPilotOperatorUpsertPilotOperatorRequestPayload(pilotOperator);

        return this.http.post(this.apiToken.pilotOperators, payload);
    }

    public updatePilotOperator(operatorName: string, pilotOperator: PilotOperator) {
        const payload = transformPilotOperatorToPilotOperatorUpsertPilotOperatorRequestPayload(pilotOperator);

        return this.http.put(StringUtils.replaceInTemplate(this.apiToken.managePilotOperators, { operatorName }), payload);
    }

    public markPilotOperator(operatorName: string) {
        return this.http.put(StringUtils.replaceInTemplate(this.apiToken.markPilotOperators, { operatorName }), {});
    }

    public unmarkPilotOperator(operatorName: string) {
        return this.http.put(StringUtils.replaceInTemplate(this.apiToken.unmarkPilotOperators, { operatorName }), {});
    }

    public deletePilotOperator(operatorName: string) {
        return this.http.delete(StringUtils.replaceInTemplate(this.apiToken.managePilotOperators, { operatorName }));
    }
}
