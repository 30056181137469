import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { FilterType, FiltersMap, IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule, TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { CheckinsHistoryFilters } from "../../models/checkins.model";

interface CheckinHistoryFilterComponentState {
    checkinsHistoryItemsNumber: number;
    isProcessing: boolean;
    appliedFiltersLength: number | undefined;
}

const FILTERS_MAP: FiltersMap[] = [
    {
        key: "dateFrom",
        filterLabel: "droneTowerMobileLib.checkinsHistoryFilters.fromDateLabel",
        type: FilterType.Date,
    },
    {
        key: "dateTo",
        filterLabel: "droneTowerMobileLib.checkinsHistoryFilters.toDateLabel",
        type: FilterType.Date,
    },
];

@Component({
    selector: "drone-tower-mobile-lib-checkin-history-filter",
    standalone: true,
    imports: [
        CommonModule,
        IconDirective,
        TranslocoModule,
        ReactiveFormsModule,
        PushModule,
        LetModule,
        SharedUiModule,
        MatDatepickerModule,
        MatLegacyInputModule,
        SharedI18nModule,
    ],
    templateUrl: "./checkin-history-filter.component.html",
    styleUrls: ["./checkin-history-filter.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CheckinHistoryFilterComponent {
    @Input() public set checkinsHistoryItemsNumber(value: number | undefined) {
        this.localStore.patchState({ checkinsHistoryItemsNumber: value ?? 0 });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set initialFilters(value: CheckinsHistoryFilters | null) {
        if (value) {
            this.filtersForm.patchValue(value);
        }
    }
    @Output() public readonly filtersClose = new EventEmitter<void>();
    @Output() public readonly filtersChange = new EventEmitter<CheckinsHistoryFilters | null>();
    protected readonly filtersForm = new FormGroup({
        dateFrom: new FormControl<Date | null>(null),
        dateTo: new FormControl<Date | null>(null),
    });

    protected readonly checkinsHistoryItemsNumber$ = this.localStore.selectByKey("checkinsHistoryItemsNumber");
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly appliedFiltersLength$ = this.localStore.selectByKey("appliedFiltersLength");
    protected readonly maxFromDate = new Date();
    protected readonly FILTERS_MAP = FILTERS_MAP;

    constructor(
        private readonly localStore: LocalComponentStore<CheckinHistoryFilterComponentState>,
        private readonly translocoHelper: TranslationHelperService
    ) {
        localStore.setState({ checkinsHistoryItemsNumber: 0, isProcessing: false, appliedFiltersLength: 0 });
    }

    protected clearFilters() {
        this.filtersForm.reset();
        this.applyFilter();
    }

    protected applyFilter() {
        this.filtersChange.emit(this.filtersForm.getRawValue());
        this.setActiveFiltersCount();
    }

    private setActiveFiltersCount(): void {
        this.localStore.patchState({
            appliedFiltersLength: Object.values(this.filtersForm.value).flat().filter(FunctionUtils.isTruthy).length,
        });
    }
}
