import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, inject, OnInit, Output } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { Router, RouterLink } from "@angular/router";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { Logger } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetModule, PushModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { filter, first, firstValueFrom, map, switchMap, tap } from "rxjs";
import { CreateCheckinErrorType } from "../../models/checkins.model";
import { FlightConditionCategory, OPENED_FLIGHT_CATEGORY_QUERY_PARAM_NAME } from "../../models/flight-conditions.model";
import { AppInfoService } from "../../services/app-info/app-info.service";
import { ErrorHandlingService } from "../../services/error-handling-service/error-handling-service";
import { VERSION } from "../../services/mobile-app-version/mobile-app-version.tokens";
import {
    DEFAULT_TOKEN,
    PushNotificationPermissions,
    PushNotificationsService,
} from "../../services/push-notifications/push-notifications.service";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { DroneTowerMobileActions } from "../../state/drone-tower-mobile.actions";
import { DroneTowerMobileState } from "../../state/drone-tower-mobile.state";
import { DroneTowerFeatures, FlightStatusExtended, Weight } from "../../state/drone-tower-mobile.state.model";
import { AcknowledgeCheckInErrorDialogComponent } from "../acknowledge-check-in-error-dialog/acknowledge-check-in-error-dialog.component";
import { CheckinDialogComponent, CheckinDialogOptions } from "../checkin-dialog/checkin-dialog.component";
import { CheckinDetailsComponent } from "../edit-checkin/checkin-details.component";

@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-lib-check-in",
    standalone: true,
    imports: [
        CommonModule,
        RouterLink,
        HeaderComponent,
        SharedI18nModule,
        SharedUiModule,
        LetModule,
        CheckinDetailsComponent,
        PushModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
    ],
    templateUrl: "./check-in.component.html",
    styleUrls: ["./check-in.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckInComponent implements OnInit {
    private readonly store = inject(Store);
    private readonly router = inject(Router);
    private readonly toastrService = inject(ToastrService);
    private readonly translocoService = inject(TranslocoService);
    private readonly dialog = inject(MatDialog);
    private readonly pushNotificationsService = inject(PushNotificationsService);
    private readonly appInfoService = inject(AppInfoService);
    private readonly errorHandlingService = inject(ErrorHandlingService);
    private readonly version = inject(VERSION);

    protected readonly flightStatusParams$ = this.store.select(DroneTowerMobileState.flightStatusParams);
    protected readonly checkinParams$ = this.store.select(DroneTowerMobileState.checkInParams);
    protected readonly selectedMissionName$ = this.store
        .select(DroneTowerMobileState.selectedCheckinMission)
        .pipe(map((mission) => mission?.name));

    protected readonly Weight = Weight;
    protected readonly FlightConditionCategory = FlightConditionCategory;

    protected readonly operatorNumberFormControl = new FormControl(
        this.store.selectSnapshot(DroneTowerMobileState.userData).operatorNumber
    );
    protected readonly isOperatorsFeatureAvailable$ = this.store.select(
        DroneTowerMobileState.isFeatureAvailable(DroneTowerFeatures.Operators)
    );
    protected readonly isUserPosition$ = this.store
        .select(DroneTowerMobileState.markerPosition)
        .pipe(map((markerPosition) => markerPosition?.isUserPosition));

    @Output() public checkInClose = new EventEmitter<void>();

    public ngOnInit() {
        this.store.dispatch(
            new DroneTowerMobileActions.SetCheckinParams({
                operatorNumber: this.store.selectSnapshot(DroneTowerMobileState.userData).operatorNumber,
                operatorName: "",
                isOneTimeOperatorNumber: false,
            })
        );
    }

    protected async createCheckIn() {
        const isUserPosition = this.store.selectSnapshot(DroneTowerMobileState.markerPosition)?.isUserPosition;
        if (!isUserPosition) {
            this.appInfoService.openAppSettings();
            this.closeCheckIn();

            return;
        }

        const flightStatus = this.store.selectSnapshot(DroneTowerMobileState.flightStatus);
        if (flightStatus === FlightStatusExtended.Error || flightStatus === FlightStatusExtended.ErrorOutsideSupportedArea) {
            const errorKey =
                flightStatus === FlightStatusExtended.ErrorOutsideSupportedArea
                    ? "droneTowerMobile.flightStatusOutsideSupportedAreaMessage"
                    : "droneTowerMobile.flightStatusErrorMessage";
            const errorMessage = this.translocoService.translate(errorKey);
            this.errorHandlingService.displayMessage({
                errorMessage,
                options: { positionClass: "toast-bottom-full-width", closeButton: true },
            });

            return;
        }

        const areNotificationsReady = await this.checkNotificationReadiness();
        if (!areNotificationsReady) {
            return;
        }
        const isMissionSelected = this.store.selectSnapshot(DroneTowerMobileState.selectedCheckinMission);
        const checkInAction = isMissionSelected ? DroneTowerMobileActions.CreateMissionCheckIn : DroneTowerMobileActions.CreateCheckIn;
        const isOperatorsFeatureAvailable = this.store.selectSnapshot(
            DroneTowerMobileState.isFeatureAvailable(DroneTowerFeatures.Operators)
        );

        this.dialog
            .open(CheckinDialogComponent, {
                data: isMissionSelected ? CheckinDialogOptions.MissionCheckin : CheckinDialogOptions.Checkin,
                maxWidth: "95vw",
                autoFocus: false,
            })
            .afterClosed()
            .pipe(
                first(),
                filter((value) => typeof value === "string"),
                tap(() => this.checkInClose.emit()),
                switchMap((anspMessage) => {
                    const operatorNumber = isOperatorsFeatureAvailable
                        ? this.store.selectSnapshot(DroneTowerMobileState.checkInParams).operatorNumber
                        : this.operatorNumberFormControl.value ?? "";

                    return this.store.dispatch(new checkInAction(operatorNumber, anspMessage));
                })
                /* NOTE: untilDestroyed is missing, because we want to handle error after checkin component is destroyed,
                first() is handling unsubscribe*/
            )
            .subscribe(() => {
                const createCheckinError = this.store.selectSnapshot(DroneTowerMobileState.createCheckinError);
                const createCheckinErrorMessageKey = createCheckinError?.error?.message;
                if (createCheckinErrorMessageKey === CreateCheckinErrorType.NotAllowedInAlertZone) {
                    this.dialog.open(AcknowledgeCheckInErrorDialogComponent, {
                        data: { error: createCheckinErrorMessageKey },
                    });
                } else if (createCheckinError) {
                    const errorMessage = this.translocoService.translate("droneTowerMobile.genericErrorMessage");
                    this.errorHandlingService.displayMessage({
                        httpErrorResponse: createCheckinError,
                        errorMessage,
                    });
                }
            });
    }

    protected navigateToFlightConditions(category: FlightConditionCategory) {
        this.router.navigate(["flight-status-form"], { queryParams: { [OPENED_FLIGHT_CATEGORY_QUERY_PARAM_NAME]: category } });
    }

    protected navigateToMissionsList() {
        this.store
            .dispatch(new DroneTowerMobileActions.SetCheckinMissionList())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const missionsError = this.store.selectSnapshot(DroneTowerMobileState.checkinMissionsError);
                if (missionsError) {
                    const errorMessage = this.translocoService.translate("droneTowerMobileLib.checkIn.missionListErrorMessage");
                    this.errorHandlingService.displayMessage({
                        httpErrorResponse: missionsError,
                        errorMessage,
                    });

                    return;
                }

                const missions = this.store.selectSnapshot(DroneTowerMobileState.checkinMissions);
                if (missions.length) {
                    this.router.navigate(["missions-list"]);
                } else {
                    const message = this.translocoService.translate("droneTowerMobileLib.checkIn.noMissionsMessage");
                    this.toastrService.info(message);
                }
            });
    }

    protected resetSelectedMission() {
        this.store.dispatch(new DroneTowerMobileActions.SelectCheckinMission(null));
    }

    protected closeCheckIn() {
        this.checkInClose.emit();
        this.resetSelectedMission();
    }

    private async checkNotificationReadiness() {
        const token = await firstValueFrom(this.pushNotificationsService.token$);
        const permission = await this.pushNotificationsService.checkPermissions();

        if (permission !== PushNotificationPermissions.Granted) {
            Logger.captureMessage("permissionNotGranted", {
                extra: { version: this.version, entity: "CheckInComponent", permission, token },
            });
            const errorMessage = this.translocoService.translate("droneTowerMobileLib.checkIn.pushNotificationDeniedErrorMessage");
            this.toastrService
                .error(errorMessage, undefined, {
                    positionClass: "toast-bottom-center",
                    enableHtml: true,
                    messageClass: "toast-with-actions",
                })
                .onTap.pipe(untilDestroyed(this))
                .subscribe(() => this.appInfoService.openAppSettings());

            return false;
        }

        if (token === DEFAULT_TOKEN && permission === PushNotificationPermissions.Granted) {
            Logger.captureMessage("tokenDefault", { extra: { version: this.version, entity: "CheckInComponent", permission } });
            const errorMessage = this.translocoService.translate("droneTowerMobileLib.checkIn.tokenDefaultErrorMessage");
            this.toastrService.error(errorMessage, undefined, { positionClass: "toast-bottom-center" });
            this.pushNotificationsService.initializePushNotifications();

            return false;
        }

        return true;
    }
}
