<!-- TODO: DTOWER-383 Global Translation Issue-->
<ng-container *ngIf="('droneTowerMobileLib.updateDialog.headerLabel' | transloco) && translationsReady$ | ngrxPush as headerLabel">
    <h2 mat-dialog-title>{{ headerLabel }}</h2>
    <div class="content-container">
        <div mat-dialog-actions class="actions">
            <button type="button" class="button-primary" (click)="goToAppStore()">
                {{ "droneTowerMobileLib.updateDialog.appStoreLabel" | transloco }}
            </button>
            <button type="button" class="button-secondary" (click)="goToApp()" *ngIf="!matDialogData.isUpdateRequired">
                {{ "droneTowerMobileLib.updateDialog.appLabel" | transloco }}
            </button>
        </div>
    </div>
</ng-container>
