import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AirspaceDetailsComponent } from "../../components/airspace-details/airspace-details.component";
import { CheckInMissionsListComponent } from "../../components/check-in-missions-list/check-in-missions-list.component";
import { CheckinDetailsComponent } from "../../components/edit-checkin/checkin-details.component";
import { FlightStatusFormComponent } from "../../components/flight-status-form/flight-status-form.component";
import { LocationInfoComponent } from "../../components/location-info/location-info.component";
import { UserDataComponent } from "../../components/user-data/user-data.component";
import { AuthGuard } from "../../guards/auth.guard";
import { CapabilitiesGuard } from "../../guards/capabilities.guard";
import { MapComponent } from "./components/map/map.component";

const routes: Routes = [
    {
        path: "map",
        component: MapComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "flight-status-form",
        component: FlightStatusFormComponent,
        canActivate: [AuthGuard, CapabilitiesGuard],
    },
    {
        path: "location-info",
        component: LocationInfoComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "airspace-details",
        component: AirspaceDetailsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "checkin-details",
        component: CheckinDetailsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "user-data",
        component: UserDataComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "missions-list",
        component: CheckInMissionsListComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MapRoutingModule {}
