<ng-container
    *ngrxLet="{
        pilotOperators: pilotOperators$,
        pilotOperatorsError: pilotOperatorsError$,
        isProcessing: isProcessing$,
        userDataOperatorNumber: userDataOperatorNumber$,
        operatorNumber: operatorNumber$,
        isNumberAlreadyExists: isNumberAlreadyExists$,
        isNameAlreadyExists: isNameAlreadyExists$
    } as vm"
>
    <drone-tower-mobile-lib-header
        [headerLabel]="'droneTowerMobileLib.operators.operatorsListLabel' | transloco"
        icon="arrow-go-back"
        link="/map"
    ></drone-tower-mobile-lib-header>
    <form class="wrapper" [formGroup]="operatorForm">
        <dtm-ui-input-field class="form-container-item">
            <label>{{ "droneTowerMobileLib.operators.oneTimeOperatorNumber" | transloco }}</label>
            <input formControlName="number" matInput type="text" />
            <div class="field-error" *dtmUiFieldHasError="operatorForm.controls.number; name: 'requiredTouched'">
                {{ "droneTowerMobileLib.shared.form.requiredErrorMessage" | transloco }}
            </div>
            <div class="field-error" *ngIf="vm.isNumberAlreadyExists">
                {{ "droneTowerMobileLib.operators.numberAlreadyExistsErrorMessage" | transloco }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-checkbox-field formControlName="addOperator">
            {{ "droneTowerMobileLib.operators.addOperatorToListLabel" | transloco }}
        </dtm-ui-checkbox-field>
        <dtm-ui-input-field
            class="form-container-item"
            *ngIf="operatorForm.controls.addOperator.value && operatorForm.controls.name.enabled"
        >
            <label>{{ "droneTowerMobileLib.operators.operatorName" | transloco }}</label>
            <input formControlName="name" matInput type="text" />
            <div class="field-error" *dtmUiFieldHasError="operatorForm.controls.name; name: 'requiredTouched'">
                {{ "droneTowerMobileLib.shared.form.requiredErrorMessage" | transloco }}
            </div>
            <div class="field-error" *ngIf="vm.isNameAlreadyExists">
                {{ "droneTowerMobileLib.operators.nameAlreadyExistsErrorMessage" | transloco }}
            </div>
        </dtm-ui-input-field>
    </form>
    <hr class="separator" *ngIf="vm.userDataOperatorNumber || vm.pilotOperators.length" />

    <dtm-ui-loader-container [isShown]="vm.isProcessing">
        <ul>
            <li
                class="row"
                [class.selected]="vm.userDataOperatorNumber && vm.userDataOperatorNumber === vm.operatorNumber"
                (click)="toggleOperatorNumber(vm.userDataOperatorNumber)"
            >
                <div class="details">
                    <h2 class="name">
                        {{ "droneTowerMobileLib.operators.myOperatorNumber" | transloco }}
                    </h2>
                    <p>
                        <span *ngIf="vm.userDataOperatorNumber; else fillDataInUserData">{{ vm.userDataOperatorNumber }}</span>
                        <ng-template #fillDataInUserData>{{ "droneTowerMobileLib.operators.fillDataInUserData" | transloco }}</ng-template>
                    </p>
                </div>
                <dtm-ui-icon name="user"></dtm-ui-icon>
            </li>
            <li
                *ngFor="let pilotOperator of vm.pilotOperators"
                class="row"
                [class.selected]="pilotOperator.number === vm.operatorNumber"
                (click)="toggleOperatorNumber(pilotOperator.number, pilotOperator.name)"
            >
                <div class="details">
                    <h2 class="name">
                        {{ pilotOperator.name }}
                    </h2>
                    <p>
                        {{ pilotOperator.number }}
                    </p>
                </div>
                <dtm-ui-icon name="star-fill" *ngIf="pilotOperator.isFavorite"></dtm-ui-icon>
            </li>
        </ul>
    </dtm-ui-loader-container>

    <div class="action-wrapper">
        <button type="button" class="button-secondary" routerLink="/map">
            {{ "droneTowerMobileLib.operators.cancelLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" (click)="submitOperator()" [disabled]="vm.isProcessing">
            {{ "droneTowerMobileLib.operators.saveLabel" | transloco }}
        </button>
    </div>
</ng-container>
