<drone-tower-mobile-lib-header [headerLabel]="'droneTowerMobileLib.locationInfo.headerLabel' | transloco"></drone-tower-mobile-lib-header>
<dtm-ui-loader-container
    *ngrxLet="{
        flightConditionsStatusDetails: flightConditionsStatusDetails$,
        markerPosition: markerPosition$,
        isInactiveZonesFeatureAvailable: isInactiveZonesFeatureAvailable$
    } as vm"
    [isShown]="isLoading$ | ngrxPush"
    class="scroll-shadows"
>
    <ng-container *ngIf="vm.flightConditionsStatusDetails as flightConditionsStatusDetails">
        <section class="checkin-info">
            <div class="flight-allowed-status" [ngSwitch]="flightConditionsStatusDetails.status">
                <ng-container *ngSwitchCase="FlightStatus.NoRestriction">
                    <dtm-ui-icon name="checkbox-circle-fill" class="success"></dtm-ui-icon>
                    <span class="success">{{ "droneTowerMobileLib.locationInfo.flightAllowedLabel" | transloco }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="FlightStatus.NoRestrictionInfo">
                    <dtm-ui-icon name="error-warning-fill" class="success"></dtm-ui-icon>
                    <span class="success">{{ "droneTowerMobileLib.locationInfo.flightAllowedLabel" | transloco }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="FlightStatus.Conditional">
                    <dtm-ui-icon name="error-warning-fill" class="warning"></dtm-ui-icon>
                    <span class="warning">{{ "droneTowerMobileLib.locationInfo.flightRestrictedLabel" | transloco }}</span>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <dtm-ui-icon name="sign-prohibition" class="error"></dtm-ui-icon>
                    <span class="error">{{ "droneTowerMobileLib.locationInfo.flightForbiddenLabel" | transloco }}</span>
                </ng-container>
            </div>
            {{
                "droneTowerMobileLib.locationInfo.missionRequiredLabel" | transloco : { value: flightConditionsStatusDetails.missionStatus }
            }}
        </section>
        <drone-tower-mobile-lib-location-info-zones
            [collidingAirspaceElements]="flightConditionsStatusDetails.collidingAirspaceElements"
        ></drone-tower-mobile-lib-location-info-zones>

        <drone-tower-mobile-lib-location-info-zones
            [collidingAirspaceElements]="flightConditionsStatusDetails.collidingInactiveAirspaceElements"
            [type]="ZoneType.Inactive"
            *ngIf="vm.isInactiveZonesFeatureAvailable"
        ></drone-tower-mobile-lib-location-info-zones>

        <drone-tower-mobile-lib-location-info-missions
            *ngIf="isBvlosMissionsFeatureAvailable$ | ngrxPush"
        ></drone-tower-mobile-lib-location-info-missions>

        <dtm-ui-expandable-panel [hasHeaderSeparator]="false" [isExpanded]="true">
            <div class="section-header" headerSlot>{{ "droneTowerMobileLib.locationInfo.locationParametersLabel" | transloco }}</div>
            <div class="location-info-tiles">
                <div class="tile clickable" (click)="copyToClipboard()">
                    <div class="tile-label">{{ "droneTowerMobileLib.locationInfo.coordinatesLabel" | transloco }}</div>
                    <dtm-ui-dms-display
                        class="tile-value"
                        [decimalLatitude]="vm.markerPosition?.latitude"
                        [decimalLongitude]="vm.markerPosition?.longitude"
                    ></dtm-ui-dms-display>
                </div>
                <div class="tile">
                    <div class="tile-label">{{ "droneTowerMobileLib.locationInfo.heightLabel" | transloco }}</div>
                    <div class="tile-value">
                        {{
                            "droneTowerMobileLib.locationInfo.maxElevationLabel"
                                | transloco : { value: flightConditionsStatusDetails.elevationDetails.elevation?.max || 0 }
                        }}
                    </div>
                </div>
                <div class="tile">
                    <div class="tile-label">{{ "droneTowerMobileLib.locationInfo.KpiLabel" | transloco }}</div>
                    <div class="tile-value">{{ flightConditionsStatusDetails.kpIndexDetails.kpIndex }}</div>
                </div>
            </div>
        </dtm-ui-expandable-panel>
    </ng-container>
</dtm-ui-loader-container>
