<!-- TODO: DTOWER-383 Global Translation Issue-->
<ng-container
    *ngIf="('droneTowerMobileLib.checkInTimeExceededDialog.headerLabel' | transloco) && translationsReady$ | ngrxPush as headerLabel"
>
    <h2 mat-dialog-title>{{ headerLabel }}</h2>
    <div class="content-container">
        <span [innerHTML]="'droneTowerMobileLib.checkInTimeExceededDialog.informationText' | transloco"></span>
        <div mat-dialog-actions>
            <button [mat-dialog-close]="true" type="button" class="button-warn">
                {{ "droneTowerMobileLib.checkInTimeExceededDialog.confirmButton" | transloco }}
            </button>
        </div>
    </div>
</ng-container>
