import { HttpErrorResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { catchError, EMPTY, tap } from "rxjs";
import { ResetPasswordService } from "../../services/reset-password/reset-password.service";
import { ResetPasswordActions } from "./reset-password.actions";

export interface ResetPasswordStateModel {
    recoveryId: string | undefined;
    isProcessing: boolean;
    emailError: HttpErrorResponse | null;
    validationError: HttpErrorResponse | null;
    confirmPasswordError: HttpErrorResponse | null;
}

const DEFAULT_STATE: ResetPasswordStateModel = {
    recoveryId: undefined,
    isProcessing: false,
    emailError: null,
    validationError: null,
    confirmPasswordError: null,
};

@State<ResetPasswordStateModel>({
    name: "resetPassword",
    defaults: DEFAULT_STATE,
})
@Injectable()
export class ResetPasswordState {
    private readonly resetPasswordService = inject(ResetPasswordService);

    @Selector()
    public static isProcessing(state: ResetPasswordStateModel) {
        return state.isProcessing;
    }

    @Selector()
    public static emailError(state: ResetPasswordStateModel) {
        return state.emailError;
    }

    @Selector()
    public static validationError(state: ResetPasswordStateModel) {
        return state.validationError;
    }

    @Selector()
    public static confirmPasswordError(state: ResetPasswordStateModel) {
        return state.confirmPasswordError;
    }

    @Action(ResetPasswordActions.ResetPassword, { cancelUncompleted: true })
    public resetPassword(context: StateContext<ResetPasswordStateModel>, action: ResetPasswordActions.ResetPassword) {
        context.patchState({
            isProcessing: true,
            emailError: null,
        });

        return this.resetPasswordService.resetPassword(action.resetPasswordRequestPayload).pipe(
            tap(({ recoveryId }) => {
                context.patchState({
                    isProcessing: false,
                    recoveryId,
                });
            }),
            catchError((emailError) => {
                context.patchState({
                    emailError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(ResetPasswordActions.ValidateEmail, { cancelUncompleted: true })
    public verifyEmail(context: StateContext<ResetPasswordStateModel>, action: ResetPasswordActions.ValidateEmail) {
        const recoveryId = context.getState().recoveryId;
        if (!recoveryId) {
            return EMPTY;
        }

        context.patchState({
            isProcessing: true,
            validationError: null,
        });

        return this.resetPasswordService.validateEmail(recoveryId, action.validateEmailRequestPayload).pipe(
            tap(() => {
                context.patchState({
                    isProcessing: false,
                });
            }),
            catchError((validationError) => {
                context.patchState({
                    validationError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(ResetPasswordActions.ConfirmPassword, { cancelUncompleted: true })
    public confirmPassword(context: StateContext<ResetPasswordStateModel>, action: ResetPasswordActions.ConfirmPassword) {
        const recoveryId = context.getState().recoveryId;
        if (!recoveryId) {
            return EMPTY;
        }

        context.patchState({
            isProcessing: true,
            confirmPasswordError: null,
        });

        return this.resetPasswordService.confirmPassword(recoveryId, action.confirmPasswordRequestPayload).pipe(
            tap(() => {
                context.patchState({
                    isProcessing: false,
                });
            }),
            catchError((confirmPasswordError) => {
                context.patchState({
                    confirmPasswordError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }
}
