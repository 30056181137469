<div class="form-container" *ngrxLet="{ isProcessing: isProcessing$ } as vm">
    <h2 class="form-container-item title">
        {{ "droneTowerMobileLibLogin.resetPasswordEmailVerification.title" | transloco : { email: email$ | ngrxPush } }}
    </h2>
    <p class="form-container-item">{{ "droneTowerMobileLibLogin.resetPasswordEmailVerification.description" | transloco }}</p>
    <div class="form-container-item">
        <dtm-ui-verification-code
            [formControl]="verificationCodeFormControl"
            (verificationCodeResend)="resendCode()"
        ></dtm-ui-verification-code>
    </div>
    <div class="form-container-item">
        <button type="button" class="button-primary" [disabled]="vm.isProcessing" (click)="onSubmit()">
            <mat-spinner *ngIf="vm.isProcessing" [diameter]="20" color="accent"></mat-spinner>
            <ng-container *ngIf="!vm.isProcessing">
                {{ "droneTowerMobileLibLogin.resetPasswordEmailVerification.verify" | transloco }}
            </ng-container>
        </button>
    </div>
    <p class="form-container-item redirect">
        <a routerLink="/login">
            {{ "droneTowerMobileLibLogin.resetPasswordEmailVerification.backToLogin" | transloco }}
        </a>
    </p>
</div>
