<!-- TODO: DTOWER-383 Global Translation Issue-->
<ng-container
    *ngIf="('droneTowerMobileLib.acknowledgeLandNowCheckInDialog.headerLabel' | transloco) && translationsReady$ | ngrxPush as headerLabel"
>
    <h2 mat-dialog-title>{{ headerLabel }}</h2>
    <div class="content-container">
        <div [innerHTML]="'droneTowerMobileLib.acknowledgeLandNowCheckInDialog.informationText' | transloco"></div>
        <div mat-dialog-actions>
            <button [mat-dialog-close]="true" type="button" class="button-warn">
                {{ "droneTowerMobileLib.acknowledgeLandNowCheckInDialog.confirmButton" | transloco }}
            </button>
        </div>
    </div>
</ng-container>
