import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { TranslocoModule } from "@jsverse/transloco";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { DRONE_TOWER_USER_GUIDE_URL } from "../../utils/defaults";

@Component({
    selector: "drone-tower-mobile-lib-help",
    standalone: true,
    imports: [CommonModule, HeaderComponent, TranslocoModule, IconDirective, SharedUiModule],
    templateUrl: "./help.component.html",
    styleUrls: ["./help.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpComponent {
    protected readonly DRONE_TOWER_USER_GUIDE_URL = DRONE_TOWER_USER_GUIDE_URL;
}
