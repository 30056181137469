import { FlightCategories, MinMaxRange } from "../../models/flight-conditions.model";
import { Capabilities, FlightCategory } from "../../state/drone-tower-mobile.state.model";
import { CapabilitiesResponseBody } from "./capabilities-api.model";

export function transformCapabilitiesResponseToState(capabilities: CapabilitiesResponseBody): Capabilities {
    const flightCategoriesState: FlightCategories = {};
    const categories: FlightCategory[] = [];
    capabilities.flightCategories.forEach((flightCategory) => {
        categories.push({
            name: flightCategory.name,
            category: flightCategory.category,
        });
        flightCategoriesState[flightCategory.category] = flightCategory.subcategories.map((subcategory) => {
            const subcategoryCopy = {
                ...subcategory,
                takeOffMassRanges: [...subcategory.takeOffMassRanges],
            };
            const { max } = subcategory.takeOffMassRanges.pop() as MinMaxRange;

            return { ...subcategoryCopy, maxWeight: max };
        });
    });

    return {
        categories,
        subcategories: flightCategoriesState,
        features: capabilities.features,
        geoserverUrl: capabilities.geoserverUrl,
        mobileAppVersion: capabilities.mobileAppVersion,
    };
}
