<div class="container">
    <img class="logo" src="assets/images/logo.svg" alt="logo" />
    <form [formGroup]="loginForm" class="form-container">
        <div class="title form-container-item">
            <h2>
                <ng-container [ngSwitch]="redirectParam$ | ngrxPush">
                    <span *ngSwitchCase="REDIRECT_REGISTRATION">
                        {{ "droneTowerMobileLibLogin.loginRegisterRedirectTitle" | transloco }}
                    </span>
                    <span *ngSwitchCase="REDIRECT_RESET_PASSWORD">
                        {{ "droneTowerMobileLibLogin.loginResetPasswordRedirectTitle" | transloco }}
                    </span>
                    <span *ngSwitchDefault>
                        {{ "droneTowerMobileLibLogin.loginTitle" | transloco }}
                    </span>
                </ng-container>
            </h2>
            <dtm-ui-icon class="tooltip-icon" name="information" (click)="toggleTooltip()"></dtm-ui-icon>
        </div>
        <div *ngIf="isTooltipOpen$ | ngrxPush" class="tooltip">
            <div class="tooltip-item">
                {{ "droneTowerMobileLibLogin.loginTooltip" | transloco }}
            </div>
        </div>
        <dtm-ui-input-field class="form-container-item">
            <label>{{ "droneTowerMobileLibLogin.emailLabel" | transloco }}</label>
            <input formControlName="email" matInput type="email" autocomplete="username" />
            <div class="field-error" *dtmUiFieldHasError="loginForm.controls.email; name: 'requiredTouched'">
                {{ "droneTowerMobileLibLogin.requiredErrorMessage" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="loginForm.controls.email; name: 'email'">
                {{ "droneTowerMobileLibLogin.emailErrorMessage" | transloco }}
            </div>
            <div class="field-error" *ngIf="isUnauthorized$ | ngrxPush">
                {{ "droneTowerMobileLibLogin.emailOrPasswordErrorMessage" | transloco }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-input-field [isClearable]="false" class="form-container-item">
            <label>{{ "droneTowerMobileLibLogin.passwordLabel" | transloco }}</label>
            <input formControlName="password" #passwordInput matInput type="password" autocomplete="current-password" />
            <dtm-ui-password-visibility-switch [inputParent]="passwordInput" class="field-suffix"></dtm-ui-password-visibility-switch>
            <div class="field-error" *dtmUiFieldHasError="loginForm.controls.password; name: 'requiredTouched'">
                {{ "droneTowerMobileLibLogin.requiredErrorMessage" | transloco }}
            </div>
        </dtm-ui-input-field>
        <div class="form-container-item" *ngIf="isResetPasswordFeatureAvailable$ | ngrxPush">
            <div class="reset-password">
                <button type="button" class="button-tertiary" routerLink="/reset-password">
                    {{ "droneTowerMobileLibLogin.resetPasswordLabel" | transloco }}
                </button>
            </div>
        </div>
        <div *ngrxLet="{ isLoading: isLoading$, retryCount: retryCount$ } as vm" class="form-container-item">
            <button type="button" class="button-primary" (click)="submit()" [disabled]="vm.isLoading">
                <mat-spinner *ngIf="vm.isLoading" [diameter]="20" color="accent"></mat-spinner>
                <ng-container *ngIf="!vm.isLoading">
                    {{ "droneTowerMobileLibLogin.loginButtonLabel" | transloco }}
                </ng-container>
            </button>
        </div>
        <p class="form-container-item redirect">
            {{ "droneTowerMobileLibLogin.noAccountLabel" | transloco }}
            <a routerLink="/register" *ngIf="isEnrollmentFeatureAvailable$ | ngrxPush; else pansaUtmRegister">
                {{ "droneTowerMobileLibLogin.registerLabel" | transloco }}
            </a>
            <ng-template #pansaUtmRegister>
                <a [href]="pansaUtmRegisterUrl" target="_blank"> {{ "droneTowerMobileLibLogin.registerLabel" | transloco }}</a>
            </ng-template>
        </p>
    </form>
</div>
