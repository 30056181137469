<ng-container *ngrxLet="{ date: date$, validDate: validDate$ } as vm">
    <div class="geozone-date-item">
        <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
        <span *ngIf="vm.validDate">{{ vm.date | date : "dd.MM" }}</span>
    </div>
    <div class="geozone-date-item">
        <dtm-ui-icon name="time"></dtm-ui-icon>
        <span *ngIf="vm.validDate">{{ vm.date | localizeDate : { hour: "numeric", minute: "numeric" } }}</span>
    </div>
</ng-container>
