import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { TranslocoModule } from "@jsverse/transloco";
import { PushModule } from "@ngrx/component";

@Component({
    standalone: true,
    imports: [CommonModule, MatDialogModule, SharedI18nModule, SharedUiModule, TranslocoModule, PushModule],
    templateUrl: "./delete-operator-dialog.component.html",
    styleUrls: ["./delete-operator-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteOperatorDialogComponent {
    private readonly dialogRef = inject(MatDialogRef<DeleteOperatorDialogComponent>);
    protected readonly matDialogData = inject(MAT_DIALOG_DATA);

    protected confirm() {
        this.dialogRef.close(this.matDialogData);
    }
}
