<drone-tower-mobile-lib-header
    [headerLabel]="'droneTowerMobileLib.checkinMissionsList.headerLabel' | transloco"
    icon="arrow-go-back"
></drone-tower-mobile-lib-header>
<div
    class="missions-list"
    *ngrxLet="{ todayMissions: todayMissions$, tomorrowMissions: tomorrowMissions$, otherMissions: otherMissions$ } as vm"
>
    <h2 *ngIf="vm.todayMissions.length">{{ "droneTowerMobileLib.checkinMissionsList.todayLabel" | transloco }}</h2>
    <ng-container *ngTemplateOutlet="missionTemplate; context: { $implicit: vm.todayMissions }"> </ng-container>
    <h2 *ngIf="vm.tomorrowMissions.length">{{ "droneTowerMobileLib.checkinMissionsList.tomorrowLabel" | transloco }}</h2>
    <ng-container *ngTemplateOutlet="missionTemplate; context: { $implicit: vm.tomorrowMissions }"> </ng-container>
    <h2 *ngIf="(vm.todayMissions.length || vm.tomorrowMissions.length) && vm.otherMissions.length">
        {{ "droneTowerMobileLib.checkinMissionsList.otherLabel" | transloco }}
    </h2>
    <ng-container *ngTemplateOutlet="missionTemplate; context: { $implicit: vm.otherMissions }"> </ng-container>
    <ng-template #missionTemplate let-missions>
        <div
            class="mission"
            [class.selected]="mission.id === (selectedMissionId$ | ngrxPush)"
            (click)="selectMission(mission)"
            *ngFor="let mission of missions"
        >
            <div class="title"><dtm-ui-icon name="checkbox-circle-fill"></dtm-ui-icon> {{ mission.name }}</div>
            <div class="details">
                <div>
                    <dtm-ui-icon [name]="mission.flightType === MissionType.VLOS ? 'eye' : 'eye-off'"></dtm-ui-icon>
                    {{ mission.flightType }}
                </div>
                <div>
                    <dtm-ui-icon name="calendar-event"></dtm-ui-icon> {{ mission.startDateTime | localizeDate : { dateStyle: "short" } }}
                </div>
                <div>
                    <dtm-ui-icon name="flight-takeoff"></dtm-ui-icon>
                    <span class="time">{{ mission.startDateTime | localizeDate : { hour: "numeric", minute: "numeric" } }}</span>
                </div>
                <div>
                    <dtm-ui-icon name="flight-land"></dtm-ui-icon>
                    <span class="time">{{ mission.endDateTime | localizeDate : { hour: "numeric", minute: "numeric" } }}</span>
                </div>
            </div>
        </div>
    </ng-template>
</div>
<div class="buttons">
    <button type="button" class="button-secondary" routerLink="/">
        {{ "droneTowerMobileLib.checkinMissionsList.cancelButtonLabel" | transloco }}
    </button>
    <button type="button" class="button-primary" (click)="saveMission()">
        {{ "droneTowerMobileLib.checkinMissionsList.saveButtonLabel" | transloco }}
    </button>
</div>
