import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { TranslocoModule } from "@jsverse/transloco";

@Component({
    selector: "drone-tower-mobile-lib-acknowledge-check-in-error-dialog",
    standalone: true,
    imports: [CommonModule, MatDialogModule, SharedI18nModule, SharedUiModule, TranslocoModule],
    templateUrl: "./acknowledge-check-in-error.component.html",
    styleUrls: ["../../../styles/simple-check-in-dialog.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcknowledgeCheckInErrorDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: { error?: string }) {}
}
