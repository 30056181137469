import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { RouterLink } from "@angular/router";
import { IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoModule, TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { first, switchMap } from "rxjs";
import { ErrorHandlingService } from "../../../services/error-handling-service/error-handling-service";
import { PilotOperator } from "../../../services/pilot-profile-api/pilot-operator.model";
import { HeaderComponent } from "../../../shared/components/header/header.component";
import { PilotOperatorsActions } from "../../../state/pilot-operators/pilot-operators.actions";
import { PilotOperatorsState } from "../../../state/pilot-operators/pilot-operators.state";
import { DeleteOperatorDialogComponent } from "../delete-operator-dialog/delete-operator-dialog.component";

@UntilDestroy()
@Component({
    standalone: true,
    imports: [CommonModule, RouterLink, SharedUiModule, HeaderComponent, TranslocoModule, IconDirective, LetModule],
    templateUrl: "./operators-list.component.html",
    styleUrls: ["./operators-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperatorsListComponent {
    private readonly dialog = inject(MatDialog);
    private readonly store = inject(Store);
    private readonly translocoService = inject(TranslocoService);
    private readonly errorHandlingService = inject(ErrorHandlingService);

    protected readonly pilotOperators$ = this.store.select(PilotOperatorsState.pilotOperators);
    protected readonly pilotOperatorsError$ = this.store.select(PilotOperatorsState.pilotOperatorsError);
    protected readonly isProcessing$ = this.store.select(PilotOperatorsState.isProcessing);

    public ionViewWillEnter() {
        this.refreshPilotOperators();
    }

    protected toggleFavoriteOperator({ name, isFavorite }: PilotOperator) {
        const action = isFavorite ? new PilotOperatorsActions.UnmarkPilotOperator(name) : new PilotOperatorsActions.MarkPilotOperator(name);
        this.store
            .dispatch(action)
            .pipe(
                switchMap(() => this.store.select(PilotOperatorsState.markPilotOperatorsError)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((httpErrorResponse) => {
                if (httpErrorResponse) {
                    const errorMessage = this.translocoService.translate("droneTowerMobile.genericErrorMessage");
                    this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
                } else {
                    this.refreshPilotOperators();
                }
            });
    }

    protected deleteOperator(number: string) {
        this.dialog
            .open(DeleteOperatorDialogComponent, { data: number })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new PilotOperatorsActions.DeletePilotOperator(number))),
                switchMap(() => this.store.select(PilotOperatorsState.deletePilotOperatorsError).pipe(first())),
                untilDestroyed(this)
            )
            .subscribe((httpErrorResponse) => {
                if (httpErrorResponse) {
                    const errorMessage = this.translocoService.translate("droneTowerMobile.genericErrorMessage");
                    this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
                } else {
                    this.refreshPilotOperators();
                }
            });
    }

    private refreshPilotOperators() {
        this.store
            .dispatch(PilotOperatorsActions.GetPilotOperators)
            .pipe(
                switchMap(() => this.store.select(PilotOperatorsState.pilotOperatorsError)),
                first(),
                RxjsUtils.filterFalsy(),
                untilDestroyed(this)
            )
            .subscribe((httpErrorResponse) => {
                const errorMessage = this.translocoService.translate("droneTowerMobile.genericErrorMessage");
                this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
            });
    }
}
