import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { Capabilities } from "../../state/drone-tower-mobile.state.model";
import { transformCapabilitiesResponseToState } from "./capabilities-api.converter";
import { CapabilitiesResponseBody } from "./capabilities-api.model";
import { CAPABILITIES_ENDPOINTS_API_TOKENS } from "./capabilities-api.tokens";

@Injectable({ providedIn: "root" })
export class CapabilitiesApiService {
    private readonly http = inject(HttpClient);
    private readonly apiToken = inject(CAPABILITIES_ENDPOINTS_API_TOKENS);

    public getCapabilities(): Observable<Capabilities> {
        return this.http
            .get<CapabilitiesResponseBody>(this.apiToken.getCapabilities)
            .pipe(map((flightCategories) => transformCapabilitiesResponseToState(flightCategories)));
    }
}
