<dtm-ui-expandable-panel [isExpanded]="true" [hasHeaderSeparator]="false" [isArrowIconReversed]="true">
    <h2 headerSlot>{{ "droneTowerMobileLib.checkIn.headerLabel" | transloco }}</h2>
    <div
        class="content"
        *ngrxLet="{
            flightStatusParams: flightStatusParams$,
            missionName: selectedMissionName$,
            isOperatorsFeatureAvailable: isOperatorsFeatureAvailable$,
            checkinParams: checkinParams$
        } as vm"
    >
        <div class="wide-column tile" (click)="navigateToMissionsList()">
            <dtm-ui-icon name="road-map"></dtm-ui-icon>
            <span class="list-label">{{
                vm.missionName ? vm.missionName : ("droneTowerMobileLib.checkIn.missionsListLabel" | transloco)
            }}</span>
            <button type="button" class="button-icon" (click)="resetSelectedMission(); $event.stopPropagation()" *ngIf="vm.missionName">
                <dtm-ui-icon name="close"></dtm-ui-icon>
            </button>
        </div>
        <div class="wide-column tile" routerLink="/check-in-operator" *ngIf="vm.isOperatorsFeatureAvailable; else operatorsNotAvailable">
            <dtm-ui-icon name="community"></dtm-ui-icon>
            <span class="list-label">
                <ng-container *ngIf="vm.checkinParams.operatorName; else myOperatorNumber">
                    {{ vm.checkinParams.operatorName }} -
                </ng-container>
                <ng-template #myOperatorNumber>
                    <ng-container *ngIf="!vm.checkinParams.isOneTimeOperatorNumber && vm.checkinParams.operatorNumber">
                        {{ "droneTowerMobileLib.operators.myOperatorNumber" | transloco }} -
                    </ng-container>
                </ng-template>
                {{
                    vm.checkinParams.operatorNumber
                        ? vm.checkinParams.operatorNumber
                        : ("droneTowerMobileLib.checkIn.operatorListLabel" | transloco)
                }}
            </span>
        </div>
        <ng-template #operatorsNotAvailable>
            <div
                class="wide-column tile"
                [class.disabled]="vm.missionName"
                [matTooltip]="'droneTowerMobileLib.checkIn.changeOperatorNumberForbiddenPopoverText' | transloco"
                [matTooltipDisabled]="!vm.missionName"
            >
                <dtm-ui-icon name="community"></dtm-ui-icon>
                <input
                    *ngIf="!vm.missionName; else accordingToMissionTextTemplate"
                    matInput
                    type="text"
                    [formControl]="operatorNumberFormControl"
                    [placeholder]="'droneTowerMobileLib.checkIn.operatorNumberPlaceholder' | transloco"
                />

                <ng-template #accordingToMissionTextTemplate>
                    {{ "droneTowerMobileLib.checkIn.accordingToMissionLabel" | transloco }}
                </ng-template>
            </div>
        </ng-template>
        <button class="tile" type="button" (click)="navigateToFlightConditions(FlightConditionCategory.UAVCategory)">
            <dtm-ui-icon name="drone"></dtm-ui-icon>
            <!--          The value is mapped from Weight.Light (0.9) to 1 because  ICU message format is not handling it well. -->
            {{
                "droneTowerMobileLib.weightValueLabel"
                    | transloco : { value: vm.flightStatusParams.uavWeight === Weight.Light ? 1 : vm.flightStatusParams.uavWeight }
            }}
        </button>
        <button
            type="button"
            class="tile"
            [disabled]="vm.missionName"
            (click)="navigateToFlightConditions(FlightConditionCategory.UAVCategory)"
            [class.disabled]="vm.missionName"
            [matTooltip]="'droneTowerMobileLib.checkIn.changeUAVCategoryForbiddenPopoverText' | transloco"
            [matTooltipDisabled]="!vm.missionName"
        >
            <dtm-ui-icon name="draft"></dtm-ui-icon>
            {{
                vm.missionName
                    ? ("droneTowerMobileLib.checkIn.accordingToMissionLabel" | transloco)
                    : (vm.flightStatusParams.flightSubcategoryTranslationKey | systemTranslation)
            }}
        </button>
        <button type="button" class="tile" (click)="navigateToFlightConditions(FlightConditionCategory.FlightAreaCategory)">
            <dtm-ui-icon name="complex-top-height"></dtm-ui-icon
            >{{ "droneTowerMobileLib.checkIn.metersAglLabel" | transloco : { flightHeight: vm.flightStatusParams.flightHeight } }}
        </button>
        <button
            type="button"
            class="tile"
            [disabled]="vm.missionName"
            (click)="navigateToFlightConditions(FlightConditionCategory.FlightAreaCategory)"
            [class.disabled]="vm.missionName"
            [matTooltip]="'droneTowerMobileLib.checkIn.changeRadiusForbiddenPopoverText' | transloco"
            [matTooltipDisabled]="!vm.missionName"
        >
            <dtm-ui-icon name="complex-radius"></dtm-ui-icon
            >{{
                vm.missionName
                    ? ("droneTowerMobileLib.checkIn.accordingToMissionLabel" | transloco)
                    : ("droneTowerMobileLib.checkIn.flyingRadiusLabel" | transloco : { radius: vm.flightStatusParams.radius })
            }}
        </button>
        <button type="button" class="tile" (click)="navigateToFlightConditions(FlightConditionCategory.TimeCategory)">
            <dtm-ui-icon name="flight-takeoff"></dtm-ui-icon>
            <span *ngIf="vm.flightStatusParams.startTime === 0">{{ "droneTowerMobileLib.checkIn.departureTimeLabel" | transloco }}</span>
            <span *ngIf="vm.flightStatusParams.startTime > 0">
                {{ "droneTowerMobileLib.checkIn.inTimeLabel" | transloco : { minutes: vm.flightStatusParams.startTime } }}
            </span>
        </button>
        <button type="button" class="tile" (click)="navigateToFlightConditions(FlightConditionCategory.TimeCategory)">
            <dtm-ui-icon name="time"></dtm-ui-icon>
            <span> {{ "droneTowerMobileLib.checkIn.minutesLabel" | transloco : { minutes: vm.flightStatusParams.duration } }}</span>
        </button>
    </div>
    <div class="buttons">
        <button type="button" class="button-secondary" (click)="closeCheckIn()">
            {{ "droneTowerMobileLib.checkIn.cancelButtonLabel" | transloco }}
        </button>
        <ng-container *ngrxLet="isUserPosition$ as isUserPosition">
            <button type="button" class="button-primary" (click)="createCheckIn()">
                <mat-spinner *ngIf="!isUserPosition" [diameter]="20" color="accent"></mat-spinner>
                {{
                    (isUserPosition
                        ? "droneTowerMobileLib.checkIn.submitCheckInButtonLabel"
                        : "droneTowerMobileLib.checkIn.loadingLocationButtonLabel"
                    ) | transloco
                }}
            </button>
        </ng-container>
    </div>
</dtm-ui-expandable-panel>
