<dtm-ui-input-field
    class="form-container-item"
    *ngrxLet="{ label: label$, control: control$, isClearable: isClearable$ } as vm"
    [isClearable]="vm.isClearable"
>
    <label>{{ vm.label }}</label>
    <input [formControl]="vm.control" type="text" />
    <div class="field-error" *dtmUiFieldHasError="vm.control; name: 'requiredTouched'">
        {{ "droneTowerMobileLib.shared.form.requiredErrorMessage" | transloco }}
    </div>
    <div class="field-error" *dtmUiFieldHasError="vm.control; name: 'minlength'; error as error">
        {{ "droneTowerMobileLib.shared.form.minLengthErrorMessage" | transloco : { minLength: error.requiredLength } }}
    </div>
    <div class="field-error" *dtmUiFieldHasError="vm.control; name: 'maxlength'; error as error">
        {{ "droneTowerMobileLib.shared.form.maxLengthErrorMessage" | transloco : { maxLength: error.requiredLength } }}
    </div>
    <div class="field-error" *dtmUiFieldHasError="vm.control; name: 'pattern'">
        {{ "droneTowerMobileLib.shared.form.patternErrorMessage" | transloco }}
    </div>
</dtm-ui-input-field>
