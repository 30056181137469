<ng-container *ngIf="('droneTowerMobileLib.kpIndex.kpIndexHeaderLabel' | transloco) && translationsReady$ | ngrxPush as headerLabel">
    <div mat-dialog-content>
        <h2>{{ headerLabel }} {{ kpIndexDetails.kpIndex | localizeNumber }}</h2>
        <span
            [innerHTML]="
                (kpIndexDetails.kpIndex < KP_INDEX_DANGER_THRESHOLD
                    ? 'droneTowerMobileLib.kpIndex.warningMessage'
                    : 'droneTowerMobileLib.kpIndex.dangerMessage'
                ) | transloco
            "
        ></span>
        <!-- TODO: DTOWER-128 replace popover by new component -->
        <dtm-ui-popover [popoverText]="'droneTowerMobileLib.kpIndex.popoverMessage' | transloco"></dtm-ui-popover>
    </div>
    <div mat-dialog-actions>
        <button
            mat-dialog-close
            type="button"
            [class.button-primary]="kpIndexDetails.kpIndex < KP_INDEX_DANGER_THRESHOLD"
            [class.button-warn]="kpIndexDetails.kpIndex >= KP_INDEX_DANGER_THRESHOLD"
            class="button-primary"
        >
            {{ "droneTowerMobileLib.kpIndex.buttonLabel" | transloco }}
        </button>
    </div>
</ng-container>
