import {
    AirspaceElement,
    CollidingAirspaceElement,
    FlightConditionsStatus,
    FlightConditionsStatusDetails,
    FlightStatus,
    MinMaxRange,
    Mission,
    MissionStatus,
} from "../../models/flight-conditions.model";

interface KpIndexDetailsResponseBody {
    kpIndex: number;
    stale: boolean;
}

export interface FlightConditionsStatusResponseBody {
    status: FlightStatus;
    allowedToCheckin: boolean;
    missionRequired: boolean;
    kpIndexDetails: KpIndexDetailsResponseBody;
    elevationDetails: { elevation: MinMaxRange | null };
}

export interface FlightConditionsStatusDetailsResponseBody {
    status: FlightStatus;
    allowedToCheckin: boolean;
    missionRequired: boolean;
    kpIndexDetails: KpIndexDetailsResponseBody;
    weatherDetails: {
        totalCloudCover: number;
        mslPressureHpa: number;
        airTemperature: number;
        dewPointTemperature: number;
        windSpeed: number;
        windDirection: number;
    };
    elevationDetails: {
        elevation: MinMaxRange | null;
    };
    collidingAirspaceElements: CollidingAirspaceElementResponseBody[];
    collidingInactiveAirspaceElements: CollidingAirspaceElementResponseBody[];
    collidingMissions: Mission[];
}

export interface AirspaceElementResponseBody {
    airspaceElementId: string;
    geozone: string;
    designator: string;
    description: string;
    contact: string;
    plannedActivities: {
        lowerLimit: number;
        upperLimit: number;
        startDateTime: Date;
        endDateTime: Date;
        active: boolean;
    }[];
    source: string;
    alertZone: boolean;
    h24: boolean;
    active: boolean;
    staticElement: true;
}

export interface CollidingAirspaceElementResponseBody {
    airspaceElementId: string;
    airspaceReservationId: string;
    status: FlightStatus;
    allowedToCheckin: boolean;
    missionRequired: boolean;
    geozone: string;
    designator: string;
    startDateTime: Date;
    endDateTime: Date;
    lowerLimit: number;
    upperLimit: number;
    alertZone: boolean;
    h24: boolean;
    active: boolean;
    staticElement: boolean;
}

export function transformFlightConditionsResponseToState(flightStatus: FlightConditionsStatusResponseBody): FlightConditionsStatus {
    return {
        status: flightStatus.status,
        kpIndexDetails: {
            kpIndex: flightStatus.kpIndexDetails.kpIndex,
            isStale: flightStatus.kpIndexDetails.stale,
        },
        elevationDetails: {
            elevation: flightStatus.elevationDetails.elevation
                ? {
                      min: flightStatus.elevationDetails.elevation.min,
                      max: flightStatus.elevationDetails.elevation.max,
                  }
                : null,
        },
    };
}

function getMissionStatus(isRequired: boolean, status: FlightStatus) {
    if (isRequired) {
        return MissionStatus.Required;
    }
    if (status === FlightStatus.Prohibited) {
        return MissionStatus.NotApplicable;
    }

    return MissionStatus.NotRequired;
}

export function convertCollidingAirspaceElementResponseBodyToCollidingAirspaceElement(
    airspaceElementsResponseBody: CollidingAirspaceElementResponseBody[]
): CollidingAirspaceElement[] {
    return airspaceElementsResponseBody
        .map((airspaceElementResponseBody) => ({
            airspaceElementId: airspaceElementResponseBody.airspaceElementId,
            airspaceReservationId: airspaceElementResponseBody.airspaceReservationId,
            status: airspaceElementResponseBody.status,
            isAllowedToCheckin: airspaceElementResponseBody.allowedToCheckin,
            isMissionRequired: airspaceElementResponseBody.missionRequired,
            geozone: airspaceElementResponseBody.geozone,
            designator: airspaceElementResponseBody.designator,
            startDateTime: airspaceElementResponseBody.startDateTime,
            endDateTime: airspaceElementResponseBody.endDateTime,
            lowerLimit: airspaceElementResponseBody.lowerLimit,
            upperLimit: airspaceElementResponseBody.upperLimit,
            isAlertZone: airspaceElementResponseBody.alertZone,
            missionStatus: getMissionStatus(airspaceElementResponseBody.missionRequired, airspaceElementResponseBody.status),
            isH24: airspaceElementResponseBody.h24,
            isStaticElement: airspaceElementResponseBody.staticElement,
            isActive: airspaceElementResponseBody.active,
            plannedActivities: airspaceElementsResponseBody
                .filter((collidingAirspaceElement) => collidingAirspaceElement.designator === airspaceElementResponseBody.designator)
                .map((collidingAirspaceElement) => ({
                    lowerLimit: collidingAirspaceElement.lowerLimit,
                    upperLimit: collidingAirspaceElement.upperLimit,
                    startDateTime: new Date(collidingAirspaceElement.startDateTime),
                    endDateTime: new Date(collidingAirspaceElement.endDateTime),
                    isActive: airspaceElementResponseBody.active,
                })),
        }))
        .filter(
            (element, index, collidingAirspaceElements) =>
                index === collidingAirspaceElements.findIndex((arrayElement) => element.designator === arrayElement.designator)
        )
        .sort((firstElement, secondElement): number => {
            const statusOrder = {
                [FlightStatus.Prohibited]: 0,
                [FlightStatus.Conditional]: 1,
                [FlightStatus.NoRestrictionInfo]: 2,
                [FlightStatus.NoRestriction]: 3,
            };

            return statusOrder[firstElement.status] - statusOrder[secondElement.status];
        });
}

export function convertFlightConditionsStatusDetailsResponseBodyToFlightConditionsStatusDetails(
    flightConditionsStatusDetailsResponseBody: FlightConditionsStatusDetailsResponseBody
): FlightConditionsStatusDetails {
    return {
        isAllowedToCheckin: flightConditionsStatusDetailsResponseBody.allowedToCheckin,
        isMissionRequired: flightConditionsStatusDetailsResponseBody.missionRequired,
        kpIndexDetails: {
            kpIndex: flightConditionsStatusDetailsResponseBody.kpIndexDetails.kpIndex,
            isStale: flightConditionsStatusDetailsResponseBody.kpIndexDetails.stale,
        },
        status: flightConditionsStatusDetailsResponseBody.status,
        missionStatus: getMissionStatus(
            flightConditionsStatusDetailsResponseBody.missionRequired,
            flightConditionsStatusDetailsResponseBody.status
        ),
        collidingAirspaceElements: convertCollidingAirspaceElementResponseBodyToCollidingAirspaceElement(
            flightConditionsStatusDetailsResponseBody.collidingAirspaceElements
        ),
        collidingInactiveAirspaceElements: convertCollidingAirspaceElementResponseBodyToCollidingAirspaceElement(
            flightConditionsStatusDetailsResponseBody.collidingInactiveAirspaceElements
        ),
        elevationDetails: flightConditionsStatusDetailsResponseBody.elevationDetails,
        weatherDetails: flightConditionsStatusDetailsResponseBody.weatherDetails,
        collidingMissions: flightConditionsStatusDetailsResponseBody.collidingMissions,
    };
}

export function convertAirspaceElementResponseBodyToAirspaceElement(
    airspaceElementResponseBody: AirspaceElementResponseBody
): AirspaceElement {
    return {
        airspaceElementId: airspaceElementResponseBody.airspaceElementId,
        contact: airspaceElementResponseBody.contact,
        description: airspaceElementResponseBody.description,
        designator: airspaceElementResponseBody.designator,
        geozone: airspaceElementResponseBody.geozone,
        isAlertZone: airspaceElementResponseBody.alertZone,
        isH24: airspaceElementResponseBody.h24,
        plannedActivities: airspaceElementResponseBody.plannedActivities.map((plannedActivity) => ({
            endDateTime: plannedActivity.endDateTime,
            isActive: plannedActivity.active,
            lowerLimit: plannedActivity.lowerLimit,
            startDateTime: plannedActivity.startDateTime,
            upperLimit: plannedActivity.upperLimit,
        })),
        source: airspaceElementResponseBody.source,
        isStaticElement: airspaceElementResponseBody.staticElement,
        isActive: airspaceElementResponseBody.active,
    };
}
