<ng-container *ngrxLet="checkinHistoryItem$ as checkinHistoryItem">
    <div class="row">
        <div class="icon-with-details">
            <dtm-ui-icon name="flight-takeoff"></dtm-ui-icon>
            <div class="details">
                <span class="hour">{{ checkinHistoryItem.startDateTime | localizeDate : { hour: "numeric", minute: "numeric" } }}</span>
                {{ checkinHistoryItem.startDateTime | localizeDate }}
            </div>
        </div>
        <div class="icon-with-details">
            <dtm-ui-icon name="flight-land"></dtm-ui-icon>
            <div class="details">
                <span class="hour">{{ checkinHistoryItem.endDateTime | localizeDate : { hour: "numeric", minute: "numeric" } }}</span>
                {{ checkinHistoryItem.endDateTime | localizeDate }}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="icon-with-details">
            <dtm-ui-icon name="complex-top-height"></dtm-ui-icon>
            <div class="details">
                {{ "droneTowerMobileLib.checkinsHistory.metersAglLabel" | transloco : { maxHeight: checkinHistoryItem.maxHeight } }}
            </div>
        </div>
        <div class="icon-with-details" *ngIf="checkinHistoryItem.radius">
            <dtm-ui-icon name="complex-radius"></dtm-ui-icon>
            <div class="details">
                {{ "droneTowerMobileLib.checkinsHistory.radiusLabel" | transloco : { radius: checkinHistoryItem.radius } }}
            </div>
        </div>
        <div class="icon-with-details">
            <dtm-ui-icon name="treasure-map"></dtm-ui-icon>
            <div class="details">
                {{ checkinHistoryItem.coordinates | invoke : displayCoordinatesValue }}
                <button
                    type="button"
                    (click)="$event.stopPropagation()"
                    class="button-icon-small"
                    [cdkCopyToClipboard]="checkinHistoryItem.coordinates | invoke : displayCoordinatesValue"
                >
                    <dtm-ui-icon name="file-copy"></dtm-ui-icon>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="checkinHistoryItem.missionName" class="icon-with-details">
        <dtm-ui-icon name="road-map"></dtm-ui-icon>
        <div class="details">
            {{ checkinHistoryItem.missionName }}
        </div>
    </div>
    <div *ngIf="isUserNoteFieldVisible$ | ngrxPush; else noteValueTemplate">
        <p class="note-label"><dtm-ui-icon name="draft"></dtm-ui-icon>{{ "droneTowerMobileLib.checkinsHistory.noteLabel" | transloco }}</p>
        <dtm-ui-textarea-field [maxLength]="MAX_NOTE_LENGTH">
            <textarea [formControl]="noteControl" matInput [rows]="4"></textarea>
            <div class="field-error" *dtmUiFieldHasError="noteControl; name: 'maxlength'; error as error">
                {{ "droneTowerMobileLib.checkinsHistory.maxNoteLengthError" | transloco : { maxLength: error.requiredLength } }}
            </div>
        </dtm-ui-textarea-field>

        <div class="note-field-actions">
            <button type="button" class="button-secondary" (click)="cancelNoteEdition()">
                {{ "droneTowerMobileLib.checkinsHistory.cancelLabel" | transloco }}
            </button>
            <button type="button" class="button-primary" (click)="saveNote(checkinHistoryItem)">
                {{ "droneTowerMobileLib.checkinsHistory.saveLabel" | transloco }}
            </button>
        </div>
    </div>
    <ng-template #noteValueTemplate>
        <div class="note">
            <div class="note-label-value">
                <p class="note-label">
                    <dtm-ui-icon name="draft"></dtm-ui-icon>{{ "droneTowerMobileLib.checkinsHistory.noteLabel" | transloco }}
                </p>
                <p class="value" *ngIf="checkinHistoryItem.userNote as userNote">{{ userNote }}</p>
            </div>
            <button class="button-secondary" type="button" (click)="showUserNoteField(checkinHistoryItem.userNote)">
                <ng-container *ngIf="!checkinHistoryItem.userNote; else editUserNoteButtonTemplate">
                    <dtm-ui-icon name="add"></dtm-ui-icon>{{ "droneTowerMobileLib.checkinsHistory.addLabel" | transloco }}
                </ng-container>
                <ng-template #editUserNoteButtonTemplate>
                    <dtm-ui-icon name="edit"></dtm-ui-icon>{{ "droneTowerMobileLib.checkinsHistory.editLabel" | transloco }}
                </ng-template>
            </button>
        </div>
        <dtm-ui-info-message [type]="checkinHistoryItem.overdueInMinutes ? 'warning' : 'info'">
            <ng-container *ngIf="checkinHistoryItem.overdueInMinutes as overdueInMinutes; else checkinOnTimeInfoTemplate">
                {{
                    "droneTowerMobileLib.checkinsHistory.checkinOverdueInfo"
                        | transloco
                            : {
                                  overdueInMinutes,
                                  checkinFinishActualTime:
                                      checkinHistoryItem | invoke : getOverdueTime | localizeDate : { timeStyle: "short" }
                              }
                }}
            </ng-container>
            <ng-template #checkinOnTimeInfoTemplate>
                {{ "droneTowerMobileLib.checkinsHistory.checkinOnTimeInfo" | transloco }}
            </ng-template>
        </dtm-ui-info-message>
        <button type="button" class="button-secondary" (click)="mapRedirect.emit(checkinHistoryItem.coordinates)">
            {{ "droneTowerMobileLib.checkinsHistory.redirectToMapLabel" | transloco }}
            <dtm-ui-icon name="arrow-go"></dtm-ui-icon>
        </button>
    </ng-template>
</ng-container>
