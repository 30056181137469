import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { Router } from "@angular/router";
import { GeolocationService } from "@dtm-frontend/shared/map";
import { IconDirective, SharedUiModule, UIActions, UIState } from "@dtm-frontend/shared/ui";
import { LANGUAGE_CONFIGURATION, LanguageDefinition } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, Logger } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { UntilDestroy } from "@ngneat/until-destroy";
import { LetModule, PushModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { DroneTowerMobileLibModule } from "../../drone-tower-mobile-lib.module";
import { IntroWalkthroughService } from "../../services/intro-walkthrough/intro-walkthrough.service";
import { LanguageStorageService } from "../../services/language-storage/language-storage.service";
import { VERSION } from "../../services/mobile-app-version/mobile-app-version.tokens";
import { PushNotificationsService } from "../../services/push-notifications/push-notifications.service";
import { IntroWalkthroughStepComponent } from "../intro-walkthrough-step/intro-walkthrough-step.component";
import { RulesContentComponent } from "../rules-content/rules-content.component";

const NUMBER_OF_STEPS = 6;

interface IntroWalkthroughComponentState {
    step: number;
}

@UntilDestroy()
@Component({
    standalone: true,
    imports: [
        CommonModule,
        IconDirective,
        PushModule,
        LetModule,
        IntroWalkthroughStepComponent,
        TranslocoModule,
        DroneTowerMobileLibModule,
        RulesContentComponent,
        SharedUiModule,
    ],
    templateUrl: "./intro-walkthrough.component.html",
    styleUrls: ["./intro-walkthrough.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class IntroWalkthroughComponent {
    private readonly storage = inject(IntroWalkthroughService);
    private readonly router = inject(Router);
    private readonly pushNotificationsService = inject(PushNotificationsService);
    private readonly store = inject(Store);
    private readonly languageStorageService = inject(LanguageStorageService);
    private readonly version = inject(VERSION);

    protected readonly step$ = this.localStore.selectByKey("step");
    protected readonly NUMBER_OF_STEPS = NUMBER_OF_STEPS;
    protected readonly steps = Array(NUMBER_OF_STEPS);
    protected readonly languageDefinitions = LANGUAGE_CONFIGURATION.languageDefinitions;
    protected readonly activeLanguage$ = this.store.select(UIState.activeLanguage);

    constructor(
        private readonly localStore: LocalComponentStore<IntroWalkthroughComponentState>,
        private readonly geolocationService: GeolocationService
    ) {
        this.localStore.setState({
            step: 0,
        });
    }

    protected confirm() {
        this.storage.setIsIntroDone(true);
        this.router.navigateByUrl("/login", { replaceUrl: true });
    }

    protected setPreviousStep() {
        const step = this.localStore.selectSnapshotByKey("step");
        this.setStep(step - 1);
    }

    protected setNextStep() {
        const step = this.localStore.selectSnapshotByKey("step");
        this.setStep(step + 1);
    }

    protected setStep(value: number) {
        if (value < 0 || value > NUMBER_OF_STEPS - 1) {
            return;
        }
        const step = this.localStore.selectSnapshotByKey("step");

        if (step === 1) {
            this.askUserForLocationPermissions();
        }

        this.localStore.patchState({ step: value });
    }

    protected handleLanguageChange(language: LanguageDefinition) {
        this.store.dispatch(new UIActions.SetActiveLanguage(language.alpha2Code));
        this.languageStorageService.setLanguage(language.alpha2Code);
    }

    private async askUserForLocationPermissions() {
        if (await this.geolocationService.canTryToGetUserPosition()) {
            try {
                await this.geolocationService.getUserPosition();
            } catch (error) {
                Logger.captureMessage("locationDenied", {
                    extra: { version: this.version, entity: "IntroWalkthroughComponent", error },
                });
            }
            this.pushNotificationsService.initializePushNotifications();
        }
    }
}
