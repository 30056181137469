import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage-angular";

@Injectable({
    providedIn: "root",
})
export class StorageService<T> {
    constructor(private storage: Storage) {
        this.init();
    }

    private async init() {
        await this.storage.create();
    }

    public setKey(key: string, value: T) {
        return this.storage.set(key, value);
    }

    public getKey(key: string) {
        return this.storage.get(key);
    }

    public removeKey(key: string) {
        return this.storage.remove(key);
    }

    public clear() {
        return this.storage.clear();
    }
}
