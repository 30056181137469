import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MatTooltip, MatTooltipModule } from "@angular/material/tooltip";
import { IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { LetModule, PushModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { map } from "rxjs";
import { MissionType } from "../../models/flight-conditions.model";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { DroneTowerMobileState } from "../../state/drone-tower-mobile.state";
import { GeozoneDateComponent } from "../geozone-date/geozone-date.component";

@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-lib-location-info-missions",
    standalone: true,
    imports: [
        CommonModule,
        HeaderComponent,
        SharedI18nModule,
        IconDirective,
        PushModule,
        LetModule,
        GeozoneDateComponent,
        SharedUiModule,
        MatTooltipModule,
    ],
    templateUrl: "./location-info-missions.component.html",
    styleUrls: ["./location-info-missions.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class LocationInfoMissionsComponent {
    private readonly store = inject(Store);

    protected readonly MissionType = MissionType;
    protected readonly flightConditionStatusDetails$ = this.store.select(DroneTowerMobileState.flightConditionStatusDetails);
    protected readonly isEmpty$ = this.flightConditionStatusDetails$.pipe(
        map(
            (flightConditionStatusDetails) =>
                !flightConditionStatusDetails?.collidingMissions || flightConditionStatusDetails?.collidingMissions.length === 0
        )
    );

    protected toggleTooltip(event: MouseEvent, tooltip: MatTooltip) {
        tooltip.toggle();
        event.stopPropagation();
    }
}
