<ng-container *ngIf="airspaceElement$ | ngrxPush as airspaceElement">
    <drone-tower-mobile-lib-header
        icon="arrow-go-back"
        link="/location-info"
        [headerLabel]="
            'droneTowerMobileLib.airspaceDetails.headerLabel'
                | alert : airspaceElement.isAlertZone
                | transloco : { geozone: airspaceElement.geozone, designator: airspaceElement.designator }
                | geoZone
        "
    ></drone-tower-mobile-lib-header>
    <div class="main-container" *ngIf="airspaceElementDetails$ | ngrxPush as airspaceDetails">
        <div class="flight-allowed-status" [ngSwitch]="airspaceDetails.status">
            <ng-container *ngSwitchCase="FlightStatus.NoRestriction">
                <dtm-ui-icon name="checkbox-circle-fill" class="success"></dtm-ui-icon>
                <span class="success">{{ "droneTowerMobileLib.airspaceDetails.flightAllowedLabel" | transloco }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="FlightStatus.NoRestrictionInfo">
                <dtm-ui-icon name="error-warning-fill" class="success"></dtm-ui-icon>
                <span class="success">{{ "droneTowerMobileLib.airspaceDetails.flightAllowedLabel" | transloco }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="FlightStatus.Conditional">
                <dtm-ui-icon name="error-warning-fill" class="warning"></dtm-ui-icon>
                <span class="warning">{{ "droneTowerMobileLib.airspaceDetails.flightRestrictedLabel" | transloco }}</span>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <dtm-ui-icon name="sign-prohibition" class="error"></dtm-ui-icon>
                <span class="error">{{ "droneTowerMobileLib.airspaceDetails.flightForbiddenLabel" | transloco }}</span>
            </ng-container>
        </div>
        <div class="designator">
            {{
                "droneTowerMobileLib.airspaceDetails.designatorLabel"
                    | alert : airspaceDetails.isAlertZone
                    | transloco : { designator: airspaceElement.designator, status: airspaceDetails.status }
            }}
        </div>
        <div class="description">
            <span [innerHTML]="airspaceElement.description | phoneNumbersToClickable | emailToClickable | urlToClickable"></span>
        </div>
        <dtm-ui-label-value [label]="'droneTowerMobileLib.airspaceDetails.missionRequiredLabel' | transloco">
            {{ "droneTowerMobileLib.airspaceDetails.missionRequiredValueLabel" | transloco : { value: airspaceDetails.missionStatus } }}
        </dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'droneTowerMobileLib.airspaceDetails.verticalTimeLabel' | transloco"
            *ngIf="airspaceElement.isStaticElement"
        >
            <div class="activity-values">
                {{
                    "droneTowerMobileLib.airspaceDetails.activityTimeHeightAMSLLabel"
                        | transloco : { value: airspaceDetails.lowerLimit, maxHeight: airspaceDetails.upperLimit }
                }}
                <drone-tower-mobile-lib-h24 [isH24]="airspaceElement.isH24">
                    <span class="time-range">
                        <drone-tower-mobile-lib-geozone-date [date]="airspaceDetails.startDateTime"></drone-tower-mobile-lib-geozone-date>
                        -
                        <drone-tower-mobile-lib-geozone-date [date]="airspaceDetails.endDateTime"></drone-tower-mobile-lib-geozone-date>
                    </span>
                </drone-tower-mobile-lib-h24>
            </div>
        </dtm-ui-label-value>
        <dtm-ui-label-value [label]="'droneTowerMobileLib.airspaceDetails.contactDetailsLabel' | transloco">
            <span *ngIf="airspaceElement.contact as contact; else noInfoTemplate">
                <span [innerHTML]="contact | phoneNumbersToClickable | emailToClickable | urlToClickable"></span>
            </span>
        </dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'droneTowerMobileLib.airspaceDetails.activityTableLabel' | transloco"
            *ngIf="airspaceElement.isStaticElement; else plannedActivitiesElasticZone"
        >
            <drone-tower-mobile-lib-h24 [isH24]="airspaceElement.isH24">
                <ng-container *ngIf="airspaceElement.plannedActivities as plannedActivities; else noInfoTemplate">
                    <drone-tower-mobile-lib-planned-activities
                        [plannedActivities]="airspaceElement.plannedActivities"
                    ></drone-tower-mobile-lib-planned-activities>
                </ng-container>
            </drone-tower-mobile-lib-h24>
        </dtm-ui-label-value>
        <ng-template #plannedActivitiesElasticZone>
            <drone-tower-mobile-lib-planned-activities-elastic-zone
                [plannedActivities]="airspaceElement.plannedActivities"
            ></drone-tower-mobile-lib-planned-activities-elastic-zone>
        </ng-template>
    </div>
</ng-container>
<ng-template #noInfoTemplate>
    {{ "droneTowerMobileLib.airspaceDetails.noInfoText" | transloco }}
</ng-template>
