import { Inject, Injectable } from "@angular/core";
import { LEAFLET_MAP_CONFIG, LeafletMapConfig } from "@dtm-frontend/shared/map/leaflet";
import { LatLngTuple, Map } from "leaflet";

const CHECKIN_OFFSET = 0.004;

@Injectable({
    providedIn: "root",
})
export class UserPositionMarkerService {
    constructor(@Inject(LEAFLET_MAP_CONFIG) public readonly config: LeafletMapConfig) {}

    public centerMap(map: Map | null, coords: LatLngTuple, isCheckinOpen = false) {
        if (!map || !coords) {
            return;
        }
        const [latitude, longitude] = coords;
        map?.flyTo([latitude - (isCheckinOpen ? CHECKIN_OFFSET : 0), longitude], this.config.zoom.userPosition, {
            animate: true,
        });
    }
}
