<!-- TODO: DTOWER-383 Global Translation Issue-->
<ng-container *ngIf="('droneTowerMobile.appNotResponding.headerLabel' | transloco) && translationsReady$ | ngrxPush as headerLabel">
    <h2 mat-dialog-title>{{ headerLabel }}</h2>
    <div class="content-container">
        <p class="content" [innerHtml]="'droneTowerMobile.appNotResponding.informationText' | transloco"></p>
        <div mat-dialog-actions>
            <button type="button" class="button-primary" (click)="refreshApp()">
                {{ "droneTowerMobile.appNotResponding.buttonLabel" | transloco }}
            </button>
        </div>
    </div>
</ng-container>
