import { createDynamicEnvironment } from "@dtm-frontend/shared/utils";
import type DynamicConfiguration from "../assets/dynamic.config.json";
import firebaseConfig from "../firebase.config.json";
import { DroneTowerMobileEnvironment } from "./environment.model";
import { staticConfig } from "./static-config";

export const dynamicEnvironment = createDynamicEnvironment<DroneTowerMobileEnvironment, typeof DynamicConfiguration>(
    fetch("../assets/dynamic.config.json").then((config) => config.json()),
    (dynamicConfig) => {
        const { apiDomain, environment, pansaUtmRegisterUrl, pansaUtmLoginUrl } = dynamicConfig;
        const apiUrl = `https://${apiDomain}/api`;
        const websocketEndpoint = `wss://${apiDomain}/ws`;

        return {
            production: environment !== "dev",
            name: environment,
            version: "1.1.2",
            authenticationEndpoint: `${apiUrl}/auth`,
            enrollmentEndpoints: {
                createEnrollment: `${apiUrl}/enrollment`,
                checkUsernameAvailability: `${apiUrl}/enrollment/username-availability`,
                checkPhoneNumberAvailability: `${apiUrl}/enrollment/phone-number-availability`,
                checkEmailAvailability: `${apiUrl}/enrollment/email-availability`,
                verifyEmail: `${apiUrl}/enrollment/{{id}}/email-verification`,
                repeatEmailVerification: `${apiUrl}/enrollment/{{id}}/repeat-email-verification`,
                verifyPhone: `${apiUrl}/enrollment/{{id}}/phone-verification`,
                repeatPhoneVerification: `${apiUrl}/enrollment/{{id}}/repeat-phone-verification`,
                enroll: `${apiUrl}/enrollment/{{id}}/enroll`,
            },
            resetPasswordEndpoints: {
                resetPassword: `${apiUrl}/pilot/password-recovery`,
                validateEmail: `${apiUrl}/pilot/password-recovery/{{id}}/validate`,
                confirmPassword: `${apiUrl}/pilot/password-recovery/{{id}}/confirm`,
            },
            pansaUtmRegisterUrl,
            pansaUtmLoginUrl,
            leafletMapConfig: {
                defaultPosition: {
                    lat: 52.115,
                    lng: 19.424,
                },
                zoom: {
                    min: 3,
                    max: 18,
                    initial: 6,
                    userPosition: 15,
                },
            },
            azureMapsSubscriptionKey: "HS9JYfhTrM0Ud2smxePEEpkueCl81_SAD2T-VU-ZNFk",
            flightConditionsEndpoints: {
                getStatus: `${apiUrl}/flight-conditions/status`,
                getDetails: `${apiUrl}/flight-conditions/details`,
                getAirspaceElement: `${apiUrl}/flight-conditions/airspace-elements/reservations`,
            },
            capabilitiesEndpoints: {
                getCapabilities: `${apiUrl}/capabilities`,
            },
            translationsEndpoint: {
                getSystemTranslations: `${apiUrl}/system/translations/{{locale}}`,
            },
            pilotProfileEndpoints: {
                getPilotData: `${apiUrl}/pilot`,
                setPilotDetails: `${apiUrl}/pilot/details-update`,
                verifyPhoneNumber: `${apiUrl}/pilot/phone-number-verification-code`,
                requestPhoneNumberChange: `${apiUrl}/pilot/phone-number`,
                missingInfoUpdate: `${apiUrl}/pilot/missing-info-update`,
                confirmMissingInfoUpdate: `${apiUrl}/pilot/missing-info-update/{{id}}/confirm`,
                pilotOperators: `${apiUrl}/pilot/pilot-operators`,
                managePilotOperators: `${apiUrl}/pilot/pilot-operators/{{operatorName}}`,
                unmarkPilotOperators: `${apiUrl}/pilot/pilot-operators/{{operatorName}}/unmark-favorite`,
                markPilotOperators: `${apiUrl}/pilot/pilot-operators/{{operatorName}}/mark-favorite`,
            },
            checkinsEndpoints: {
                getActive: `${apiUrl}/checkins`,
                createCheckIn: `${apiUrl}/checkins/`,
                finishCheckIn: `${apiUrl}/checkins/{{checkinId}}`,
                createMissionCheckIn: `${apiUrl}/missions/{{missionId}}/checkins`,
                acknowledgeCheckInModification: `${apiUrl}/checkins/{{id}}/atc-modified-acknowledge`,
                acknowledgeLandNowCheckIn: `${apiUrl}/checkins/{{id}}/land-now-acknowledge`,
                acknowledgeCheckInAccepted: `${apiUrl}/checkins/{{id}}/accepted-acknowledge`,
                acknowledgeCheckInRejected: `${apiUrl}/checkins/{{id}}/rejected-acknowledge`,
                checkInLostControl: `${apiUrl}/checkins/{{checkinId}}/lost-control`,
                getCheckinMissions: `${apiUrl}/missions/`,
            },
            checkinsHistoryEndpoints: {
                getCheckins: `${apiUrl}/checkin-archives`,
                manageCheckinHistoryNote: `${apiUrl}/checkin-archives/{{checkinId}}/user-note`,
            },
            websocketEndpoint,
            alertTopicName: "/websocket/topic/drone-tower-queue/drone-tower-geospace-topic/Receivers",
            firebaseConfig,
            dynamicConfig,
            ...staticConfig,
        };
    }
);
