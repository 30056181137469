import { Injectable } from "@angular/core";
import { StorageService } from "../storage/storage.service";

const INTRO_DONE_STORAGE_KEY = "isIntroDone";

@Injectable({
    providedIn: "root",
})
export class IntroWalkthroughService {
    constructor(private readonly storageService: StorageService<boolean>) {}

    public async setIsIntroDone(value: boolean) {
        return await this.storageService.setKey(INTRO_DONE_STORAGE_KEY, value);
    }

    public async getIsIntroDone() {
        return await this.storageService.getKey(INTRO_DONE_STORAGE_KEY);
    }
}
