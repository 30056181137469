import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Store } from "@ngxs/store";
import { Observable, map } from "rxjs";
import { AirspaceElement, FlightConditionsStatus, FlightConditionsStatusDetails } from "../../models/flight-conditions.model";
import { DroneTowerMobileState } from "../../state/drone-tower-mobile.state";
import {
    AirspaceElementResponseBody,
    FlightConditionsStatusDetailsResponseBody,
    FlightConditionsStatusResponseBody,
    convertAirspaceElementResponseBodyToAirspaceElement,
    convertFlightConditionsStatusDetailsResponseBodyToFlightConditionsStatusDetails,
    transformFlightConditionsResponseToState,
} from "./flight-conditions-api.converter";
import { FLIGHT_CONDITIONS_ENDPOINTS_API_TOKENS } from "./flight-conditions-api.tokens";

@Injectable({
    providedIn: "root",
})
export class FlightConditionsApiService {
    private readonly http = inject(HttpClient);
    private readonly apiToken = inject(FLIGHT_CONDITIONS_ENDPOINTS_API_TOKENS);
    private readonly store = inject(Store);

    public getFlightStatus(): Observable<FlightConditionsStatus> {
        const payload = this.createFlightConditionsRequestPayload();

        return this.http
            .post<FlightConditionsStatusResponseBody>(this.apiToken.getStatus, payload)
            .pipe(map((flightStatus) => transformFlightConditionsResponseToState(flightStatus)));
    }

    public getFlightConditionsStatusDetails(): Observable<FlightConditionsStatusDetails> {
        const payload = this.createFlightConditionsRequestPayload();

        return this.http
            .post<FlightConditionsStatusDetailsResponseBody>(this.apiToken.getDetails, payload)
            .pipe(
                map((flightStatusDetails) =>
                    convertFlightConditionsStatusDetailsResponseBodyToFlightConditionsStatusDetails(flightStatusDetails)
                )
            );
    }

    public getAirspaceElement(designator: string): Observable<AirspaceElement> {
        const params = new HttpParams().set("designator", designator);

        return this.http
            .get<AirspaceElementResponseBody>(this.apiToken.getAirspaceElement, { params })
            .pipe(map((airspaceElement) => convertAirspaceElementResponseBodyToAirspaceElement(airspaceElement)));
    }

    private createFlightConditionsRequestPayload() {
        const markerPosition = this.store.selectSnapshot(DroneTowerMobileState.markerPosition);
        const flightStatusParams = this.store.selectSnapshot(DroneTowerMobileState.flightStatusParams);
        const userCheckin = this.store.selectSnapshot(DroneTowerMobileState.userCheckin);
        const selectedCheckinMission = this.store.selectSnapshot(DroneTowerMobileState.selectedCheckinMission);
        const startDate = new Date();
        startDate.setMinutes(startDate.getMinutes() + flightStatusParams.startTime);
        const endDate = new Date(startDate);
        endDate.setMinutes(endDate.getMinutes() + flightStatusParams.duration);

        return {
            latitude: markerPosition?.latitude ?? 0,
            longitude: markerPosition?.longitude ?? 0,
            radius: userCheckin?.properties.radius ? userCheckin.properties.radius : flightStatusParams.radius,
            flightCategory: flightStatusParams.flightCategory,
            flightSubcategory: flightStatusParams.flightSubcategory,
            flightHeight: userCheckin ? userCheckin.properties.maxHeight : flightStatusParams.flightHeight,
            uavWeight: flightStatusParams.uavWeight,
            startTimestamp: startDate.toISOString(),
            endTimestamp: userCheckin ? userCheckin.properties.stopDate.toISOString() : endDate.toISOString(),
            geometry: selectedCheckinMission?.geometry,
        };
    }
}
