<drone-tower-mobile-lib-header headerLabel="Info"></drone-tower-mobile-lib-header>

<div class="wrapper scroll-shadows">
    <dtm-ui-label-value label="Environment">
        <span class="selectable">{{ config.environment }}</span>
    </dtm-ui-label-value>
    <dtm-ui-label-value label="Version">
        <span class="selectable">{{ config.version }}</span>
    </dtm-ui-label-value>
    <dtm-ui-label-value label="API Domain">
        <span class="selectable">{{ config.apiDomain }}</span>
    </dtm-ui-label-value>
    <dtm-ui-label-value label="Geoserver Endpoint">
        <span class="selectable">{{ geoserverUrl$ | ngrxPush }}</span>
    </dtm-ui-label-value>
    <dtm-ui-label-value label="Firebase Token">
        <span class="selectable">{{ token$ | ngrxPush }}</span>
    </dtm-ui-label-value>
</div>
