<ng-container
    *ngrxLet="{
        pilotOperators: pilotOperators$,
        pilotOperatorsError: pilotOperatorsError$,
        mode: mode$,
        isProcessing: isProcessing$,
    } as vm"
>
    <drone-tower-mobile-lib-header
        [headerLabel]="'droneTowerMobileLib.operators.operatorsListLabel' | transloco"
        icon="arrow-go-back"
        link="/operators"
    ></drone-tower-mobile-lib-header>
    <form class="wrapper" [formGroup]="operatorForm">
        <dtm-ui-input-field class="form-container-item">
            <label>{{ "droneTowerMobileLib.operators.operatorNumber" | transloco }}</label>
            <input formControlName="number" matInput type="text" />
            <div class="field-error" *dtmUiFieldHasError="operatorForm.controls.number; name: 'requiredTouched'">
                {{ "droneTowerMobileLib.shared.form.requiredErrorMessage" | transloco }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-input-field class="form-container-item">
            <label>{{ "droneTowerMobileLib.operators.operatorName" | transloco }}</label>
            <input formControlName="name" matInput type="text" />
            <div class="field-error" *dtmUiFieldHasError="operatorForm.controls.name; name: 'requiredTouched'">
                {{ "droneTowerMobileLib.shared.form.requiredErrorMessage" | transloco }}
            </div>
        </dtm-ui-input-field>
    </form>
    <ng-template #errorTemplate>
        <div class="wrapper-error">{{ "droneTowerMobileLib.operators.getPilotOperatorsError" | transloco }}</div>
    </ng-template>
    <div class="action-wrapper">
        <button type="button" class="button-secondary" routerLink="/operators">
            {{ "droneTowerMobileLib.operators.cancelLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" (click)="submitOperator()" [disabled]="vm.isProcessing">
            {{ "droneTowerMobileLib.operators.saveLabel" | transloco }}
        </button>
    </div>
</ng-container>
