import { Injectable } from "@angular/core";
import { AirspaceParams } from "../../models/airspace-params.model";
import { StorageService } from "../storage/storage.service";

const AIRSPACE_PARAMS_DATA_STORAGE_KEY = "airspaceParamsData";

@Injectable({
    providedIn: "root",
})
export class AirspaceParamsStorageService {
    constructor(private readonly storageService: StorageService<AirspaceParams>) {}

    public async patchAirspaceParams(airspaceParams: AirspaceParams) {
        await this.storageService.setKey(AIRSPACE_PARAMS_DATA_STORAGE_KEY, airspaceParams);
    }

    public async getAirspaceParams(): Promise<AirspaceParams | undefined> {
        return await this.storageService.getKey(AIRSPACE_PARAMS_DATA_STORAGE_KEY);
    }
}
