<div class="container" *ngrxLet="{ isProcessing: isProcessing$, retryCount: retryCount$, currentStep: currentStep$ } as vm">
    <img class="logo" src="assets/images/logo.svg" alt="logo" />
    <form
        class="form-container"
        dtmUiInvalidFormScrollable
        [formGroup]="registrationForm"
        (ngSubmit)="onSubmit()"
        *ngIf="vm.currentStep === RegisterSteps.Form"
    >
        <div class="title form-container-item">
            <h2>{{ "droneTowerMobileLibLogin.register.registerTitle" | transloco }}</h2>
        </div>

        <dtm-ui-input-field class="form-container-item">
            <label>{{ "droneTowerMobileLibLogin.register.emailLabel" | transloco }}</label>
            <input formControlName="email" matInput type="email" autocomplete="username" />
            <div class="field-error" *dtmUiFieldHasError="registrationForm.controls.email; name: 'requiredTouched'">
                {{ "droneTowerMobileLibLogin.requiredErrorMessage" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="registrationForm.controls.email; name: 'email'">
                {{ "droneTowerMobileLibLogin.emailErrorMessage" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="registrationForm.controls.email; name: 'unique'">
                {{ "droneTowerMobileLibLogin.register.uniqueEmailErrorMessage" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="registrationForm.controls.email; name: 'uniqueServerError'">
                {{ "droneTowerMobileLibLogin.register.genericErrorMessage" | transloco }}
            </div>
        </dtm-ui-input-field>

        <dtm-ui-input-field class="form-container-item form-container-item-password" [isClearable]="false">
            <label>{{ "droneTowerMobileLibLogin.passwordLabel" | transloco }}</label>
            <input formControlName="password" matInput type="password" autocomplete="new-password" #password />
            <dtm-ui-password-visibility-switch [inputParent]="password" class="field-suffix"> </dtm-ui-password-visibility-switch>
            <div class="field-error" *dtmUiFieldHasError="registrationForm.controls.password; name: 'requiredTouched'">
                {{ "droneTowerMobileLibLogin.requiredErrorMessage" | transloco }}
            </div>
            <div class="field-hint">
                {{ "droneTowerMobileLibLogin.passwordHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="registrationForm.controls.password; name: 'pattern'">
                {{ "droneTowerMobileLibLogin.passwordHint" | transloco }}
            </div>
        </dtm-ui-input-field>

        <dtm-ui-input-field class="form-container-item" [isClearable]="false">
            <label>{{ "droneTowerMobileLibLogin.confirmPasswordLabel" | transloco }}</label>
            <input formControlName="confirmPassword" matInput type="password" autocomplete="new-password" #confirmPassword />
            <dtm-ui-password-visibility-switch [inputParent]="confirmPassword" class="field-suffix"> </dtm-ui-password-visibility-switch>
            <div class="field-error" *dtmUiFieldHasError="registrationForm.controls.confirmPassword; name: 'requiredTouched'">
                {{ "droneTowerMobileLibLogin.requiredErrorMessage" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="registrationForm.controls.confirmPassword; name: 'mustMatch'">
                {{ "droneTowerMobileLibLogin.mustMatchErrorMessage" | transloco }}
            </div>
        </dtm-ui-input-field>

        <dtm-ui-phone-number-field [formControl]="registrationForm.controls.phoneNumber" class="form-container-item">
            <div class="field-error" *dtmUiFieldHasError="registrationForm.controls.phoneNumber; name: 'required'">
                {{ "droneTowerMobileLibLogin.requiredErrorMessage" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="registrationForm.controls.phoneNumber; name: 'unique'">
                {{ "droneTowerMobileLibLogin.uniquePhoneNumberErrorMessage" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="registrationForm.controls.phoneNumber; name: 'uniqueServerError'">
                {{ "droneTowerMobileLibLogin.register.genericErrorMessage" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="registrationForm.controls.phoneNumber; name: 'invalidNumber'">
                {{ "droneTowerMobileLibLogin.invalidPhoneNumberErrorMessage" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="registrationForm.controls.phoneNumber; name: 'invalidNumberType'">
                {{ "droneTowerMobileLibLogin.invalidNumberTypeErrorMessage" | transloco }}
            </div>
        </dtm-ui-phone-number-field>

        <drone-tower-mobile-lib-name-input-field
            class="form-container-item"
            [label]="'droneTowerMobileLibLogin.firstNameLabel' | transloco"
            [control]="registrationForm.controls.firstName"
        ></drone-tower-mobile-lib-name-input-field>
        <drone-tower-mobile-lib-name-input-field
            class="form-container-item"
            [label]="'droneTowerMobileLibLogin.lastNameLabel' | transloco"
            [control]="registrationForm.controls.lastName"
        ></drone-tower-mobile-lib-name-input-field>

        <div class="form-container-item rodo">
            {{ "droneTowerMobileLibLogin.register.rodoLabel" | transloco }}
            <a [href]="'droneTowerMobileLibLogin.register.rodoLink' | transloco">
                <dtm-ui-icon name="download" class="download"></dtm-ui-icon
            ></a>
        </div>
        <div class="form-container-item">
            <button type="submit" class="button-primary" [disabled]="vm.isProcessing">
                <mat-spinner *ngIf="vm.isProcessing" [diameter]="20" color="accent"></mat-spinner>
                <ng-container *ngIf="!vm.isProcessing">
                    {{ "droneTowerMobileLibLogin.register.registerLabel" | transloco }}
                </ng-container>
            </button>
        </div>
        <p class="form-container-item redirect">
            {{ "droneTowerMobileLibLogin.register.loginLabel" | transloco }}
            <a routerLink="/login">
                {{ "droneTowerMobileLibLogin.register.loginRedirect" | transloco }}
            </a>
        </p>
    </form>
    <drone-tower-mobile-lib-email-verification
        [email]="registrationForm.controls.email.value"
        *ngIf="vm.currentStep === RegisterSteps.EmailVerification"
        (registerRedirect)="redirectToStep(RegisterSteps.Form)"
        (verify)="redirectToStep(RegisterSteps.PhoneVerification)"
    ></drone-tower-mobile-lib-email-verification>
    <drone-tower-mobile-lib-phone-verification
        [phoneNumber]="registrationForm.controls.phoneNumber.value"
        *ngIf="vm.currentStep === RegisterSteps.PhoneVerification"
        (registerRedirect)="redirectToStep(RegisterSteps.Form)"
        (verify)="enroll()"
    ></drone-tower-mobile-lib-phone-verification>
</div>
