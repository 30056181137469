import { inject, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { switchMap } from "rxjs";
import { AcknowledgeCheckInAcceptedDialogComponent } from "../../components/acknowledge-check-in-accepted-dialog/acknowledge-check-in-accepted-dialog.component";
import { AcknowledgeCheckInModificationDialogComponent } from "../../components/acknowledge-check-in-modification-dialog/acknowledge-check-in-modification-dialog.component";
import { AcknowledgeCheckInRejectedDialogComponent } from "../../components/acknowledge-check-in-rejected-dialog/acknowledge-check-in-rejected-dialog.component";
import { AcknowledgeLandNowCheckInDialogComponent } from "../../components/acknowledge-land-now-check-in-dialog/acknowledge-land-now-check-in-dialog.component";
import { AcknowledgeSeenByFisCheckInDialogComponent } from "../../components/acknowledge-seen-by-fis-check-in-dialog/acknowledge-seen-by-fis-check-in-dialog.component";
import { CheckInEndsSoonDialogComponent } from "../../components/check-in-ends-soon-dialog/check-in-ends-soon-dialog.component";
import { CheckInTimeExceededDialogComponent } from "../../components/check-in-time-exceeded-dialog/check-in-time-exceeded-dialog.component";
import { CheckInTimeoutDialogComponent } from "../../components/check-in-timeout-dialog/check-in-timeout-dialog.component";
import { ActiveCheckinProperties } from "../../models/checkins.model";
import { DroneTowerMobileActions } from "../../state/drone-tower-mobile.actions";
import { DroneTowerMobileState } from "../../state/drone-tower-mobile.state";
import { ErrorHandlingService } from "../error-handling-service/error-handling-service";

export enum NotificationCheckinStatus {
    AtcModified = "atcModified",
    LandNow = "landNow",
    Accepted = "accepted",
    Rejected = "rejected",
    Seen = "seen",
    Unknown = "unknown",
    Overdue = "OVERDUE",
    Active = "ACTIVE",
}

export enum NotificationEventName {
    CheckinPreOverdueEvent = "CheckinPreOverdueEvent",
    CheckinOverdueEvent = "CheckinOverdueEvent",
    CheckinAfterOverdueEvent = "CheckinAfterOverdueEvent",
    CheckinOverdueReminderEvent = "CheckinOverdueReminderEvent",
}

export interface Notification {
    title?: string;
    body?: string;
}

@UntilDestroy()
@Injectable({
    providedIn: "root",
})
export class PushNotificationHandlerService {
    private readonly dialog = inject(MatDialog);
    private readonly store = inject(Store);
    private readonly translocoService = inject(TranslocoService);
    private readonly errorHandlingService = inject(ErrorHandlingService);

    public handleNotification(
        status: NotificationCheckinStatus,
        properties: Partial<ActiveCheckinProperties>,
        eventName?: NotificationEventName
    ) {
        if (eventName) {
            this.handleNotificationByEvent(eventName);
        } else {
            this.handleNotificationByStatus(status, properties);
        }
    }

    public convertNotificationToActiveCheckinProperties(notification?: Notification) {
        const modificationDetailsRegex = /: (\d+) m AGL, Start: ([^\n]+), Stop: ([^\n]+)/;
        const [, maxHeight, startDate, stopDate] = notification?.body?.match(modificationDetailsRegex) || [];

        return {
            startDate: new Date(startDate),
            stopDate: new Date(stopDate),
            maxHeight: +maxHeight,
        };
    }

    public convertMessageDataToStatus(data?: { [key: string]: string }): NotificationCheckinStatus {
        try {
            return data?.status || (data && JSON.parse(data.alert || data.operation).status);
        } catch (e) {
            return NotificationCheckinStatus.Unknown;
        }
    }

    private handleNotificationByEvent(event: NotificationEventName) {
        this.closeOverdueDialogs();

        switch (event) {
            case NotificationEventName.CheckinPreOverdueEvent: {
                this.dialog.open(CheckInEndsSoonDialogComponent, {
                    disableClose: true,
                });
                break;
            }
            case NotificationEventName.CheckinOverdueEvent: {
                this.dialog.open(CheckInTimeoutDialogComponent, {
                    disableClose: true,
                });
                break;
            }
            case NotificationEventName.CheckinAfterOverdueEvent:
            case NotificationEventName.CheckinOverdueReminderEvent: {
                this.dialog.open(CheckInTimeExceededDialogComponent, {
                    disableClose: true,
                });
                break;
            }
            default: {
                break;
            }
        }
    }

    private handleNotificationByStatus(status: NotificationCheckinStatus, properties: Partial<ActiveCheckinProperties>) {
        switch (status) {
            case NotificationCheckinStatus.AtcModified: {
                this.handleCheckinModifiedStatusChange(properties);
                break;
            }
            case NotificationCheckinStatus.LandNow: {
                this.handleLandNowCheckinStatusChange();
                break;
            }
            case NotificationCheckinStatus.Accepted: {
                this.handleCheckinStatusAcceptedChange();
                break;
            }
            case NotificationCheckinStatus.Rejected: {
                this.handleCheckinStatusRejectedChange();
                break;
            }
            case NotificationCheckinStatus.Seen: {
                this.handleSeenByFisCheckinStatusChange();
                break;
            }
            case NotificationCheckinStatus.Overdue: {
                this.handleOverdueStatusChange();
                break;
            }
            default: {
                break;
            }
        }
    }

    private handleCheckinModifiedStatusChange(properties: Partial<ActiveCheckinProperties>) {
        this.store.dispatch(
            new DroneTowerMobileActions.PatchCheckIn({
                startDate: properties.startDate,
                stopDate: properties.stopDate,
                maxHeight: properties.maxHeight,
            })
        );

        this.closeAcknowledgeDialogs();
        this.dialog
            .open(AcknowledgeCheckInModificationDialogComponent, {
                disableClose: true,
                data: properties,
            })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new DroneTowerMobileActions.AcknowledgeCheckinModification())),
                untilDestroyed(this)
            )
            .subscribe(() => this.handleAcknowledgeCheckinStatusChangeError());
    }

    private handleLandNowCheckinStatusChange() {
        this.closeAcknowledgeDialogs();
        this.dialog
            .open(AcknowledgeLandNowCheckInDialogComponent, {
                disableClose: true,
            })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new DroneTowerMobileActions.AcknowledgeLandNowCheckIn())),
                untilDestroyed(this)
            )
            .subscribe(() => this.handleAcknowledgeCheckinStatusChangeError());
    }

    private handleCheckinStatusAcceptedChange() {
        this.closeAcknowledgeDialogs();
        this.dialog
            .open(AcknowledgeCheckInAcceptedDialogComponent, {
                disableClose: true,
            })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new DroneTowerMobileActions.AcknowledgeCheckInAccepted())),
                untilDestroyed(this)
            )
            .subscribe(() => this.handleAcknowledgeCheckinStatusChangeError());
    }

    private handleCheckinStatusRejectedChange() {
        this.closeAcknowledgeDialogs();
        this.dialog
            .open(AcknowledgeCheckInRejectedDialogComponent, {
                disableClose: true,
            })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new DroneTowerMobileActions.AcknowledgeCheckInRejected())),
                untilDestroyed(this)
            )
            .subscribe(() => this.handleAcknowledgeCheckinStatusChangeError());
    }

    private handleSeenByFisCheckinStatusChange() {
        this.dialog.open(AcknowledgeSeenByFisCheckInDialogComponent, {
            disableClose: true,
        });
    }

    private handleOverdueStatusChange() {
        this.store.dispatch(new DroneTowerMobileActions.SetActiveCheckinOverdue());
        this.dialog.open(CheckInTimeoutDialogComponent, {
            disableClose: true,
        });
    }

    private closeAcknowledgeDialogs() {
        this.dialog.openDialogs.forEach((dialog) => {
            const openDialogName = dialog.componentInstance?.constructor?.name;
            if (
                [
                    AcknowledgeCheckInAcceptedDialogComponent.name,
                    AcknowledgeCheckInModificationDialogComponent.name,
                    AcknowledgeCheckInRejectedDialogComponent.name,
                    AcknowledgeLandNowCheckInDialogComponent.name,
                ].includes(openDialogName)
            ) {
                dialog.close();
            }
        });
    }

    private closeOverdueDialogs() {
        this.dialog.openDialogs.forEach((dialog) => {
            const openDialogName = dialog.componentInstance?.constructor?.name;
            if (
                [CheckInEndsSoonDialogComponent.name, CheckInTimeoutDialogComponent.name, CheckInTimeExceededDialogComponent.name].includes(
                    openDialogName
                )
            ) {
                dialog.close();
            }
        });
    }

    private handleAcknowledgeCheckinStatusChangeError() {
        const httpErrorResponse = this.store.selectSnapshot(DroneTowerMobileState.acknowledgeCheckinError);
        if (httpErrorResponse) {
            const errorMessage = this.translocoService.translate("droneTowerMobileLib.acknowledgeCheckinStatusChangeError");
            this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
        }
    }
}
