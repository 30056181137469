import {
    EnrollmentRequestPayload,
    EnrollRequestPayload,
    VerifyEmailRequestPayload,
    VerifyPhoneRequestPayload,
} from "../../services/enrollment/enrollment.model";
import { ConfirmPilotDataRequestPayload, SetPilotDataRequestPayload } from "../../services/pilot-profile-api/pilot-profile-api.model";

export namespace EnrollmentActions {
    export class CreateEnrollment {
        public static readonly type = "[Enrollment] Create enrollment";
        constructor(public enrollmentFormRequestPayload: EnrollmentRequestPayload) {}
    }

    export class VerifyEmail {
        public static readonly type = "[Enrollment] Verify Email";
        constructor(public verifyEmailRequestPayload: VerifyEmailRequestPayload) {}
    }

    export class RepeatEmailVerification {
        public static readonly type = "[Enrollment] Repeat Email verification";
    }

    export class VerifyPhone {
        public static readonly type = "[Enrollment] Verify Phone";
        constructor(public verifyEmailRequestPayload: VerifyPhoneRequestPayload) {}
    }

    export class RepeatPhoneVerification {
        public static readonly type = "[Enrollment] Repeat Phone verification";
    }

    export class Enroll {
        public static readonly type = "[Enrollment] Enroll";
        constructor(public enrollRequestPayload: EnrollRequestPayload) {}
    }

    export class SetPilotData {
        public static readonly type = "[Enrollment] Set Pilot data";
        constructor(public setPilotDataRequestPayload: SetPilotDataRequestPayload) {}
    }

    export class ConfirmPilotData {
        public static readonly type = "[Enrollment] Confirm Pilot data";
        constructor(public confirmPilotData: ConfirmPilotDataRequestPayload) {}
    }
}
