import { HttpErrorResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { catchError, EMPTY, tap } from "rxjs";
import { EnrollmentService } from "../../services/enrollment/enrollment.service";
import { PilotProfileApiService } from "../../services/pilot-profile-api/pilot-profile-api.service";
import { EnrollmentActions } from "./enrollment.actions";

export interface EnrollmentStateModel {
    userId: string | null;
    isEmailVerified: boolean;
    isPhoneVerified: boolean;
    isProcessing: boolean;
    enrollmentError: HttpErrorResponse | null;
    updateId: string | null;
    setPilotDataError: HttpErrorResponse | null;
    confirmPilotDataError: HttpErrorResponse | null;
}

const DEFAULT_STATE: EnrollmentStateModel = {
    userId: null,
    enrollmentError: null,
    isEmailVerified: false,
    isProcessing: false,
    isPhoneVerified: false,
    updateId: null,
    setPilotDataError: null,
    confirmPilotDataError: null,
};

@State<EnrollmentStateModel>({
    name: "enrollment",
    defaults: DEFAULT_STATE,
})
@Injectable()
export class EnrollmentState {
    private readonly enrollmentService = inject(EnrollmentService);
    private readonly pilotProfileApiService = inject(PilotProfileApiService);

    @Selector()
    public static isProcessing(state: EnrollmentStateModel) {
        return state.isProcessing;
    }

    @Selector()
    public static enrollmentError(state: EnrollmentStateModel) {
        return state.enrollmentError;
    }

    @Selector()
    public static setPilotDataError(state: EnrollmentStateModel) {
        return state.setPilotDataError;
    }

    @Selector()
    public static confirmPilotDataError(state: EnrollmentStateModel) {
        return state.confirmPilotDataError;
    }

    @Action(EnrollmentActions.SetPilotData, { cancelUncompleted: true })
    public setPilotData(context: StateContext<EnrollmentStateModel>, action: EnrollmentActions.SetPilotData) {
        context.patchState({
            isProcessing: true,
            updateId: null,
            setPilotDataError: null,
        });

        return this.pilotProfileApiService.setPilotData(action.setPilotDataRequestPayload).pipe(
            tap((setPilotDataResponseBody) => {
                context.patchState({ isProcessing: false, updateId: setPilotDataResponseBody.updateId });
            }),
            catchError((setPilotDataError) => {
                context.patchState({
                    setPilotDataError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(EnrollmentActions.ConfirmPilotData, { cancelUncompleted: true })
    public confirmPilotData(context: StateContext<EnrollmentStateModel>, action: EnrollmentActions.ConfirmPilotData) {
        const updateId = context.getState().updateId;

        if (!updateId) {
            return EMPTY;
        }
        context.patchState({
            isProcessing: true,
            confirmPilotDataError: null,
        });

        return this.pilotProfileApiService.confirmPilotData(updateId, action.confirmPilotData).pipe(
            tap(() => {
                context.patchState(DEFAULT_STATE);
            }),
            catchError((confirmPilotDataError) => {
                context.patchState({
                    confirmPilotDataError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(EnrollmentActions.CreateEnrollment, { cancelUncompleted: true })
    public createEnrollment(context: StateContext<EnrollmentStateModel>, action: EnrollmentActions.CreateEnrollment) {
        context.patchState({
            isProcessing: true,
        });

        return this.enrollmentService.createEnrollment(action.enrollmentFormRequestPayload).pipe(
            tap((enrollmentResponseBody) => {
                context.patchState({
                    userId: enrollmentResponseBody.id,
                    isProcessing: false,
                    enrollmentError: undefined,
                });
            }),
            catchError((enrollmentError) => {
                context.patchState({
                    enrollmentError,
                    userId: undefined,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(EnrollmentActions.VerifyEmail, { cancelUncompleted: true })
    public verifyEmail(context: StateContext<EnrollmentStateModel>, action: EnrollmentActions.VerifyEmail) {
        const userId = context.getState().userId;

        if (!userId) {
            return EMPTY;
        }
        context.patchState({
            isProcessing: true,
        });

        return this.enrollmentService.verifyEmail(userId, action.verifyEmailRequestPayload).pipe(
            tap(() => {
                context.patchState({
                    isEmailVerified: true,
                    enrollmentError: undefined,
                    isProcessing: false,
                });
            }),
            catchError((enrollmentError) => {
                context.patchState({
                    enrollmentError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(EnrollmentActions.RepeatEmailVerification, { cancelUncompleted: true })
    public repeatEmailVerification(context: StateContext<EnrollmentStateModel>) {
        const userId = context.getState().userId;

        if (!userId) {
            return EMPTY;
        }

        return this.enrollmentService.repeatEmailVerification(userId).pipe(
            tap(() => {
                context.patchState({
                    enrollmentError: undefined,
                });
            }),
            catchError((enrollmentError) => {
                context.patchState({
                    enrollmentError,
                });

                return EMPTY;
            })
        );
    }

    @Action(EnrollmentActions.VerifyPhone, { cancelUncompleted: true })
    public verifyPhone(context: StateContext<EnrollmentStateModel>, action: EnrollmentActions.VerifyPhone) {
        const userId = context.getState().userId;

        if (!userId) {
            return EMPTY;
        }
        context.patchState({
            isProcessing: true,
        });

        return this.enrollmentService.verifyPhone(userId, action.verifyEmailRequestPayload).pipe(
            tap(() => {
                context.patchState({
                    isPhoneVerified: true,
                    isProcessing: false,
                    enrollmentError: undefined,
                });
            }),
            catchError((enrollmentError) => {
                context.patchState({
                    enrollmentError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(EnrollmentActions.RepeatPhoneVerification, { cancelUncompleted: true })
    public repeatPhoneVerification(context: StateContext<EnrollmentStateModel>) {
        const userId = context.getState().userId;

        if (!userId) {
            return EMPTY;
        }

        return this.enrollmentService.repeatPhoneVerification(userId).pipe(
            tap(() => {
                context.patchState({
                    enrollmentError: undefined,
                });
            }),
            catchError((enrollmentError) => {
                context.patchState({
                    enrollmentError,
                });

                return EMPTY;
            })
        );
    }

    @Action(EnrollmentActions.Enroll, { cancelUncompleted: true })
    public completeEnrollment(context: StateContext<EnrollmentStateModel>, action: EnrollmentActions.Enroll) {
        const userId = context.getState().userId;

        if (!userId) {
            return EMPTY;
        }
        context.patchState({
            isProcessing: true,
        });

        return this.enrollmentService.enroll(userId, action.enrollRequestPayload).pipe(
            tap(() => {
                context.patchState(DEFAULT_STATE);
            }),
            catchError((enrollmentError) => {
                context.patchState({
                    enrollmentError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }
}
