import { PhoneNumber } from "@dtm-frontend/shared/ui";
import { PilotData } from "./pilot-profile-api.model";

export interface PilotDataResponseBody {
    name: string;
    surname: string;
    phoneNumber: PhoneNumber;
    verified: boolean;
}
export function transformPilotDataResponseBodyToPilotData(response: PilotDataResponseBody): PilotData {
    return {
        phoneNumber: response.phoneNumber,
        isVerified: response.verified,
        name: response.name,
        surname: response.surname,
    };
}
