import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { ArrayUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { AirspaceElementPlannedActivity } from "../../models/flight-conditions.model";
import { AlertPipe } from "../../pipes/alert.pipe";
import { GeoZonePipe } from "../../pipes/geo-zone.pipe";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { GeozoneDateComponent } from "../geozone-date/geozone-date.component";
import { H24Component } from "../h24/h24.component";
import { PlannedActivitiesComponent } from "../planned-activities/planned-activities.component";

interface PlannedActivitiesElasticZoneComponentState {
    activePlannedActivities: AirspaceElementPlannedActivity[];
    inactivePlannedActivities: AirspaceElementPlannedActivity[];
    today: Date;
    tomorrow: Date;
}

const PLANNED_ACTIVITIES_START_HOUR = 6;

@Component({
    selector: "drone-tower-mobile-lib-planned-activities-elastic-zone",
    standalone: true,
    imports: [
        CommonModule,
        HeaderComponent,
        SharedUiModule,
        TranslocoModule,
        SharedI18nModule,
        PushModule,
        LetModule,
        AlertPipe,
        GeozoneDateComponent,
        GeoZonePipe,
        H24Component,
        PlannedActivitiesComponent,
    ],
    templateUrl: "./planned-activities-elastic-zone.component.html",
    styleUrls: ["./planned-activities-elastic-zone.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlannedActivitiesElasticZoneComponent {
    private readonly localStore = inject(LocalComponentStore<PlannedActivitiesElasticZoneComponentState>);

    @Input() public set plannedActivities(value: AirspaceElementPlannedActivity[]) {
        const [activePlannedActivities, inactivePlannedActivities] = ArrayUtils.partition(
            value,
            (plannedActivities) => plannedActivities.isActive
        );
        this.localStore.patchState({ activePlannedActivities, inactivePlannedActivities });
    }

    protected readonly activePlannedActivities$ = this.localStore.selectByKey("activePlannedActivities");
    protected readonly inactivePlannedActivities$ = this.localStore.selectByKey("inactivePlannedActivities");
    protected readonly today$ = this.localStore.selectByKey("today");
    protected readonly tomorrow$ = this.localStore.selectByKey("tomorrow");
    constructor() {
        const today = new Date();
        today.setUTCHours(PLANNED_ACTIVITIES_START_HOUR, 0, 0, 0);
        const tomorrow = new Date(today);
        tomorrow.setUTCDate(tomorrow.getUTCDate() + 1);
        this.localStore.setState({ activePlannedActivities: [], inactivePlannedActivities: [], today, tomorrow });
    }
}
