import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { Router } from "@angular/router";
import { IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetModule, PushModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { CollidingAirspaceElement, FlightStatus, ZoneType } from "../../models/flight-conditions.model";
import { AlertPipe } from "../../pipes/alert.pipe";
import { GeoZonePipe } from "../../pipes/geo-zone.pipe";
import { ErrorHandlingService } from "../../services/error-handling-service/error-handling-service";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { DroneTowerMobileActions } from "../../state/drone-tower-mobile.actions";
import { DroneTowerMobileState } from "../../state/drone-tower-mobile.state";
import { GeozoneDateComponent } from "../geozone-date/geozone-date.component";
import { H24Component } from "../h24/h24.component";

interface LocationInfoZonesComponentState {
    isLoading: boolean;
    collidingAirspaceElements: CollidingAirspaceElement[];
    type: ZoneType;
}

@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-lib-location-info-zones",
    standalone: true,
    imports: [
        CommonModule,
        HeaderComponent,
        SharedI18nModule,
        IconDirective,
        PushModule,
        LetModule,
        GeozoneDateComponent,
        SharedUiModule,
        MatTooltipModule,
        H24Component,
        AlertPipe,
        GeoZonePipe,
    ],
    templateUrl: "./location-info-zones.component.html",
    styleUrls: ["./location-info-zones.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class LocationInfoZonesComponent {
    private readonly store = inject(Store);
    private readonly localStore = inject(LocalComponentStore<LocationInfoZonesComponentState>);
    private readonly translocoService = inject(TranslocoService);
    private readonly errorHandlingService = inject(ErrorHandlingService);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly router = inject(Router);

    @Input() public set collidingAirspaceElements(value: CollidingAirspaceElement[]) {
        this.localStore.patchState({ collidingAirspaceElements: value });
    }
    @Input() public set type(value: ZoneType) {
        this.localStore.patchState({ type: value });
    }

    protected readonly FlightStatus = FlightStatus;
    protected readonly ZoneType = ZoneType;
    protected readonly collidingAirspaceElements$ = this.localStore.selectByKey("collidingAirspaceElements");
    protected readonly type$ = this.localStore.selectByKey("type");

    constructor() {
        this.localStore.setState({ collidingAirspaceElements: [], isLoading: false, type: ZoneType.Active });
    }

    protected openDetails(designator: string) {
        this.localStore.patchState({ isLoading: true });
        this.store
            .dispatch(new DroneTowerMobileActions.GetAirspaceElement(designator))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.localStore.patchState({ isLoading: false });
                const httpErrorResponse = this.store.selectSnapshot(DroneTowerMobileState.airspaceElementError);
                if (httpErrorResponse) {
                    const errorMessage = this.translocoService.translate("droneTowerMobileLib.locationInfo.geozoneErrorMessage");
                    this.errorHandlingService.displayMessage({
                        httpErrorResponse,
                        errorMessage,
                    });

                    return;
                }
                // note: before redirect make sure that component is in the proper state, when ionic router will handle back button
                this.changeDetectorRef.detectChanges();
                this.router.navigateByUrl("airspace-details");
            });
    }
}
