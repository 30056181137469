<header>
    <button type="button" class="close-button button-icon" (click)="filtersClose.emit()">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
    <h2>{{ "droneTowerMobileLib.checkinsHistoryFilters.header" | transloco }}</h2>
    <button type="button" class="button-secondary" (click)="clearFilters()">
        {{ "droneTowerMobileLib.checkinsHistoryFilters.clearFiltersLabel" | transloco }}
    </button>
</header>
<dtm-ui-loader-container *ngrxLet="{ isProcessing: isProcessing$ } as vm" [isShown]="vm.isProcessing">
    <div>
        <form [formGroup]="filtersForm" class="content" *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder">
            <dtm-ui-date-field (valueClear)="applyFilter()">
                <label>{{ "droneTowerMobileLib.checkinsHistoryFilters.fromDateLabel" | transloco }}</label>
                <input
                    matInput
                    formControlName="dateFrom"
                    [matDatepicker]="pickerFrom"
                    [placeholder]="datePickerPlaceholder"
                    [max]="maxFromDate"
                    (dateChange)="applyFilter()"
                />
                <mat-datepicker #pickerFrom></mat-datepicker>
                <div class="field-error" *ngIf="filtersForm.controls.dateFrom.errors?.matDatepickerMax">
                    {{
                        "droneTowerMobileLib.checkinsHistoryFilters.maxDateHint"
                            | transloco
                                : {
                                      maxDate: filtersForm.controls.dateFrom.errors?.matDatepickerMax.max | localizeDate
                                  }
                    }}
                </div>
            </dtm-ui-date-field>

            <dtm-ui-date-field (valueClear)="applyFilter()">
                <label>{{ "droneTowerMobileLib.checkinsHistoryFilters.toDateLabel" | transloco }}</label>
                <input
                    matInput
                    formControlName="dateTo"
                    [matDatepicker]="pickerTo"
                    [placeholder]="datePickerPlaceholder"
                    [min]="filtersForm.controls.dateFrom.value"
                    [max]="maxFromDate"
                    (dateChange)="applyFilter()"
                />
                <mat-datepicker #pickerTo></mat-datepicker>
                <div class="field-error" *ngIf="filtersForm.controls.dateTo.errors?.matDatepickerMin">
                    {{
                        "droneTowerMobileLib.checkinsHistoryFilters.minDateHint"
                            | transloco : { minDate: filtersForm.controls.dateTo.errors?.matDatepickerMin.min | localizeDate }
                    }}
                </div>
                <div class="field-error" *ngIf="filtersForm.controls.dateTo.errors?.matDatepickerMax">
                    {{
                        "droneTowerMobileLib.checkinsHistoryFilters.maxDateHint"
                            | transloco
                                : {
                                      maxDate: filtersForm.controls.dateTo.errors?.matDatepickerMax.max | localizeDate
                                  }
                    }}
                </div>
            </dtm-ui-date-field>
        </form>
        <dtm-ui-filter-chips-display
            selectedFiltersDisplaySlot
            *ngIf="filtersForm.valid"
            [filtersMap]="FILTERS_MAP"
            [filtersCount]="appliedFiltersLength$ | ngrxPush"
            [formGroup]="filtersForm"
            [filtersValue]="filtersForm.value"
        >
        </dtm-ui-filter-chips-display>
    </div>
    <div class="actions">
        <button
            type="button"
            class="button-primary"
            (click)="filtersClose.emit()"
            *ngrxLet="checkinsHistoryItemsNumber$ as checkinsHistoryItemsNumber"
            [disabled]="filtersForm.invalid"
        >
            {{ "droneTowerMobileLib.checkinsHistoryFilters.showResultsLabel" | transloco : { resultCount: checkinsHistoryItemsNumber } }}
        </button>
    </div>
</dtm-ui-loader-container>
