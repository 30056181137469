<ng-container *ngrxLet="plannedActivities$ as plannedActivities">
    <div class="row" *ngFor="let activity of plannedActivities" [ngClass]="{ 'row-inactive': !activity.isInFlightRange }">
        <div class="tile">
            <div class="tile-label">{{ "droneTowerMobileLib.airspaceDetails.startLabel" | transloco }}</div>
            <div class="tile-value">
                {{ activity.startDateTime | localizeDate : { dateStyle: "short", timeStyle: "short" } }}
            </div>
        </div>
        <div class="divider"></div>
        <div class="tile">
            <div class="tile-label">{{ "droneTowerMobileLib.airspaceDetails.stopLabel" | transloco }}</div>
            <div class="tile-value">
                {{ activity.endDateTime | localizeDate : { dateStyle: "short", timeStyle: "short" } }}
            </div>
        </div>
        <div class="divider"></div>
        <div class="tile">
            <div class="tile-label">{{ "droneTowerMobileLib.airspaceDetails.minLabel" | transloco }}</div>
            <div class="tile-value">
                {{ "droneTowerMobileLib.airspaceDetails.heightAMSLLabel" | transloco : { value: activity.lowerLimit } }}
            </div>
        </div>
        <div class="divider"></div>
        <div class="tile">
            <div class="tile-label">{{ "droneTowerMobileLib.airspaceDetails.maxLabel" | transloco }}</div>
            <div class="tile-value">
                {{ "droneTowerMobileLib.airspaceDetails.heightAMSLLabel" | transloco : { value: activity.upperLimit } }}
            </div>
        </div>
    </div>
</ng-container>
