import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { AppUpdate } from "@capawesome/capacitor-app-update";
import { Platform } from "@ionic/angular";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { AuthState } from "../../state/auth/auth.state";
import { DroneTowerMobileActions } from "../../state/drone-tower-mobile.actions";

@UntilDestroy()
@Injectable({
    providedIn: "root",
})
export class AppStateService {
    private readonly store = inject(Store);
    private readonly platform = inject(Platform);
    private isAlreadyRegistered = false;

    constructor(private router: Router) {
        if (Capacitor.isNativePlatform()) {
            this.platform.backButton.pipe(untilDestroyed(this)).subscribe(() => {
                if (this.router.url === "/map") {
                    App.exitApp();
                }
            });
        }
    }

    public registerAppStateChange() {
        if (this.isAlreadyRegistered) {
            return;
        }
        this.isAlreadyRegistered = true;
        App.addListener("appStateChange", ({ isActive }) => {
            if (!isActive || !this.store.selectSnapshot(AuthState.isLoggedIn)) {
                return;
            }
            this.store.dispatch(DroneTowerMobileActions.RefreshAppState);
        });
    }

    public async openAppStore() {
        if (!Capacitor.isNativePlatform()) {
            return;
        }
        await AppUpdate.openAppStore();
    }
}
