import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { AirspaceElementPlannedActivity } from "../../models/flight-conditions.model";
import { AlertPipe } from "../../pipes/alert.pipe";
import { GeoZonePipe } from "../../pipes/geo-zone.pipe";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { GeozoneDateComponent } from "../geozone-date/geozone-date.component";
import { H24Component } from "../h24/h24.component";

interface PlannedActivitiesComponentState {
    plannedActivities: AirspaceElementPlannedActivity[];
}

@Component({
    selector: "drone-tower-mobile-lib-planned-activities",
    standalone: true,
    imports: [
        CommonModule,
        HeaderComponent,
        SharedUiModule,
        TranslocoModule,
        SharedI18nModule,
        PushModule,
        LetModule,
        AlertPipe,
        GeozoneDateComponent,
        GeoZonePipe,
        H24Component,
    ],
    templateUrl: "./planned-activities.component.html",
    styleUrls: ["./planned-activities.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlannedActivitiesComponent {
    private readonly localStore = inject(LocalComponentStore<PlannedActivitiesComponentState>);

    @Input() public set plannedActivities(value: AirspaceElementPlannedActivity[]) {
        this.localStore.patchState({ plannedActivities: value });
    }

    protected readonly plannedActivities$ = this.localStore.selectByKey("plannedActivities");
    constructor() {
        this.localStore.setState({ plannedActivities: [] });
    }
}
