import { inject, Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { PermissionStatus, PushNotifications, PushNotificationSchema, RegistrationError, Token } from "@capacitor/push-notifications";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { Logger, MILLISECONDS_IN_MINUTE } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom, Subject } from "rxjs";
import { ActiveCheckinStatus } from "../../models/checkins.model";
import { DroneTowerMobileState } from "../../state/drone-tower-mobile.state";
import { AppInfoService } from "../app-info/app-info.service";
import { VERSION } from "../mobile-app-version/mobile-app-version.tokens";
import { NotificationCheckinStatus, NotificationEventName, PushNotificationHandlerService } from "./push-notifications-handler.service";
import { DEFAULT_TOKEN, PushNotificationPermissions } from "./push-notifications.service";

const CHECKIN_ENDS_SOON_DIALOG_DISPLAY_ADVANCE_MINUTES = 3;

@UntilDestroy()
@Injectable({
    providedIn: "root",
})
export class MobileNotificationsService {
    private readonly toastrService = inject(ToastrService);
    private readonly translocoService = inject(TranslocoService);
    private readonly translationHelperService = inject(TranslationHelperService);
    private readonly store = inject(Store);
    private readonly pushNotificationHandlerService = inject(PushNotificationHandlerService);
    private readonly token = new Subject<string>();
    private readonly appInfoService = inject(AppInfoService);
    public readonly token$ = this.token.asObservable();
    private readonly version = inject(VERSION);

    public initializeNotifications() {
        if (Capacitor.isPluginAvailable("PushNotifications")) {
            this.registerNotifications();
            this.addListeners();
        }
    }

    public async handleUserCheckinToNotification() {
        if (!Capacitor.isPluginAvailable("PushNotifications")) {
            return;
        }
        const userCheckin = this.store.selectSnapshot(DroneTowerMobileState.userCheckin);
        if (!userCheckin) {
            return;
        }
        if (userCheckin.properties.isAcknowledgeRequired) {
            this.removeAllDeliveredNotifications();
            this.pushNotificationHandlerService.handleNotification(
                this.convertActiveCheckinStatusToNotificationStatus(userCheckin.properties.status),
                userCheckin.properties
            );

            return;
        }
        const deliveredNotifications = await PushNotifications.getDeliveredNotifications();
        if (!deliveredNotifications.notifications.length) {
            return;
        }

        const endsSoonDialogDisplayTime = new Date(
            userCheckin.properties.stopDate.getTime() - CHECKIN_ENDS_SOON_DIALOG_DISPLAY_ADVANCE_MINUTES * MILLISECONDS_IN_MINUTE
        );
        const overdueDialogDisplayTime = new Date(userCheckin.properties.stopDate.getTime());
        const timeoutExceededDialogDisplayTime = new Date(userCheckin.properties.stopDate.getTime() + MILLISECONDS_IN_MINUTE);
        const currentTime = new Date();
        let eventName: NotificationEventName | undefined;
        if (currentTime > timeoutExceededDialogDisplayTime) {
            eventName = NotificationEventName.CheckinAfterOverdueEvent;
        } else if (currentTime > overdueDialogDisplayTime) {
            eventName = NotificationEventName.CheckinOverdueEvent;
        } else if (currentTime > endsSoonDialogDisplayTime) {
            eventName = NotificationEventName.CheckinPreOverdueEvent;
        }
        this.removeAllDeliveredNotifications();
        this.pushNotificationHandlerService.handleNotification(
            this.convertActiveCheckinStatusToNotificationStatus(userCheckin.properties.status),
            userCheckin.properties,
            eventName
        );
    }

    private async registerNotifications() {
        let permStatus: PermissionStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === PushNotificationPermissions.Prompt) {
            permStatus = await PushNotifications.requestPermissions();
        }
        if (permStatus.receive !== PushNotificationPermissions.Granted) {
            const deniedMessage = await firstValueFrom(
                this.translationHelperService.waitForTranslation("droneTowerMobileLib.pushNotificationDeniedErrorMessage")
            );
            this.toastrService
                .error(deniedMessage)
                .onTap.pipe(untilDestroyed(this))
                .subscribe(() => this.appInfoService.openAppSettings());
        }

        await PushNotifications.register();
    }

    private async addListeners() {
        await PushNotifications.addListener("pushNotificationReceived", (notification: PushNotificationSchema) => {
            const data = notification.data;
            const status = this.pushNotificationHandlerService.convertMessageDataToStatus(data);
            const eventName = data.eventName;
            const properties = this.pushNotificationHandlerService.convertNotificationToActiveCheckinProperties(notification);
            this.pushNotificationHandlerService.handleNotification(status, properties, eventName);
        });

        await PushNotifications.addListener("registration", (token: Token) => {
            this.token.next(token.value);
        });

        await PushNotifications.addListener("registrationError", (error: RegistrationError) => {
            const errorMessage = this.translocoService.translate("droneTowerMobileLib.pushNotificationRegistrationErrorMessage");
            this.toastrService.error(errorMessage);
            Logger.captureException(error, { extra: { version: this.version, entity: "registrationError" } });
        });
    }

    private convertActiveCheckinStatusToNotificationStatus(status: ActiveCheckinStatus) {
        switch (status) {
            case ActiveCheckinStatus.AtcModified: {
                return NotificationCheckinStatus.AtcModified;
            }
            case ActiveCheckinStatus.LandNow: {
                return NotificationCheckinStatus.LandNow;
            }
            case ActiveCheckinStatus.Accepted: {
                return NotificationCheckinStatus.Accepted;
            }
            case ActiveCheckinStatus.Rejected: {
                return NotificationCheckinStatus.Rejected;
            }
            case ActiveCheckinStatus.Seen: {
                return NotificationCheckinStatus.Seen;
            }
            case ActiveCheckinStatus.Overdue: {
                return NotificationCheckinStatus.Overdue;
            }
            case ActiveCheckinStatus.Active: {
                return NotificationCheckinStatus.Active;
            }
            default: {
                return NotificationCheckinStatus.Unknown;
            }
        }
    }

    private async removeAllDeliveredNotifications() {
        try {
            const isNotificationRegistered = (await firstValueFrom(this.token)) !== DEFAULT_TOKEN;
            if (isNotificationRegistered) {
                await PushNotifications.removeAllDeliveredNotifications();
            }
        } catch (error) {
            Logger.captureException(error, { extra: { version: this.version, entity: "removeAllDeliveredNotifications" } });
        }
    }
}
