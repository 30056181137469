import { inject, Injectable } from "@angular/core";
import { Device, DeviceId, DeviceInfo, GetLanguageCodeResult } from "@capacitor/device";
import { combineLatest, from, map, Observable } from "rxjs";
import { AppInfoService } from "../app-info/app-info.service";
import { DeviceInfoRequest } from "../auth-api/auth-api.model";
import { PushNotificationsService } from "../push-notifications/push-notifications.service";

@Injectable({
    providedIn: "root",
})
export class DeviceInfoService {
    private readonly appInfoService = inject(AppInfoService);
    private readonly pushNotificationsService = inject(PushNotificationsService);
    private getDeviceInfo(): Observable<DeviceInfo> {
        return from(Device.getInfo());
    }

    private getDeviceId(): Observable<DeviceId> {
        return from(Device.getId());
    }

    private getDeviceLanguageCode(): Observable<GetLanguageCodeResult> {
        return from(Device.getLanguageCode());
    }

    public createDeviceInfoRequest(): Observable<DeviceInfoRequest> {
        return combineLatest([
            this.getDeviceId(),
            this.getDeviceInfo(),
            this.getDeviceLanguageCode(),
            this.appInfoService.getAppInfo(),
            this.pushNotificationsService.token$,
        ]).pipe(
            map(([deviceId, deviceInfo, deviceLanguage, appInfo, token]) => {
                const request: DeviceInfoRequest = {
                    brand: deviceInfo.manufacturer,
                    buildId: appInfo.build,
                    buildNumber: +appInfo.version,
                    bundleId: appInfo.id,
                    deviceCountry: deviceLanguage.value,
                    deviceId: deviceId.identifier,
                    deviceLocale: deviceLanguage.value,
                    deviceType: deviceInfo.platform,
                    emulator: deviceInfo.isVirtual,
                    firebaseToken: token,
                    manufacturer: deviceInfo.manufacturer,
                    model: deviceInfo.model,
                    systemName: deviceInfo.operatingSystem,
                    systemVersion: deviceInfo.osVersion,
                };

                return request;
            })
        );
    }
}
