<!-- TODO: DTOWER-383 Global Translation Issue-->
<ng-container *ngIf="('droneTowerMobileLib.checkInTimeoutDialog.headerLabel' | transloco) && translationsReady$ | ngrxPush as headerLabel">
    <h2 mat-dialog-title>{{ headerLabel }}</h2>
    <div class="content-container">
        <span [innerHTML]="'droneTowerMobileLib.checkInTimeoutDialog.informationText' | transloco"></span>
        <div mat-dialog-actions>
            <button [mat-dialog-close]="true" type="button" class="button-warn">
                {{ "droneTowerMobileLib.checkInTimeoutDialog.confirmButton" | transloco }}
            </button>
        </div>
    </div>
</ng-container>
