import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageDefinition, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { PushModule } from "@ngrx/component";

interface LanguageDisplayComponentState {
    language: LanguageDefinition | undefined;
}

@Component({
    selector: "drone-tower-mobile-lib-language-display",
    standalone: true,
    imports: [CommonModule, SharedUiModule, PushModule, SharedI18nModule],
    providers: [LocalComponentStore],
    templateUrl: "./language-display.component.html",
    styleUrls: ["./language-display.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageDisplayComponent {
    protected readonly language$ = this.localStore.selectByKey("language");

    @Input() public set language(value: LanguageDefinition | undefined) {
        this.localStore.patchState({ language: value });
    }

    constructor(private readonly localStore: LocalComponentStore<LanguageDisplayComponentState>) {
        this.localStore.setState({
            language: undefined,
        });
    }
}
