<dtm-ui-expandable-panel
    [hasHeaderSeparator]="false"
    *ngrxLet="isEmpty$ as isEmpty"
    [isExpanded]="!isEmpty"
    [isDisabled]="isEmpty"
    [ngClass]="{ disabled: isEmpty }"
>
    <div headerSlot>
        <span class="mission-header">
            <span class="mission-header-title">
                {{ (isEmpty ? "droneTowerMobileLib.locationInfo.missionsEmpty" : "droneTowerMobileLib.locationInfo.missions") | transloco }}
            </span>
            <dtm-ui-icon
                name="information-fill"
                [matTooltip]="'droneTowerMobileLib.locationInfo.missionTooltip' | transloco"
                #tooltip="matTooltip"
                (click)="toggleTooltip($event, tooltip)"
            >
            </dtm-ui-icon>
        </span>
    </div>
    <div class="mission" *ngFor="let mission of (flightConditionStatusDetails$ | ngrxPush)?.collidingMissions">
        <div class="title">{{ mission.name }}</div>
        <div class="mission-row">
            <div class="mission-row-item">
                <dtm-ui-icon [name]="mission.flightType === MissionType.BVLOS ? 'eye-off' : 'eye'"></dtm-ui-icon>{{ mission.flightType }}
            </div>
            <div class="mission-row-item">
                <dtm-ui-icon name="complex-altitude-range"></dtm-ui-icon>
                {{
                    "droneTowerMobileLib.locationInfo.heightAGLLabel"
                        | transloco : { minHeight: mission.minAltitude, maxHeight: mission.maxAltitude }
                }}
            </div>
        </div>
        <div class="mission-row">
            <div class="mission-row-item">
                <drone-tower-mobile-lib-geozone-date [date]="mission.startDateTime"></drone-tower-mobile-lib-geozone-date>
                <div class="separator">-</div>
            </div>
            <drone-tower-mobile-lib-geozone-date [date]="mission.endDateTime"></drone-tower-mobile-lib-geozone-date>
        </div>
        <div class="mission-row">
            <div class="mission-row-item" *ngIf="mission.operatorNumber">
                <dtm-ui-icon name="community"></dtm-ui-icon>{{ mission.operatorNumber }}
            </div>
            <div class="mission-row-item">
                <a [href]="'tel:' + (mission.pilotPhoneNumber | formatPhoneNumber)" class="phone clickable">
                    <dtm-ui-icon name="phone"></dtm-ui-icon> {{ mission.pilotPhoneNumber | formatPhoneNumber }}</a
                >
            </div>
        </div>
    </div>
</dtm-ui-expandable-panel>
