import { inject, Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject } from "rxjs";
import { IntroWalkthroughService } from "../intro-walkthrough/intro-walkthrough.service";
import { MobileNotificationsService } from "./mobile-notifications.service";
import { WebNotificationsService } from "./web-notifications.service";

export enum PushNotificationPermissions {
    Prompt = "prompt",
    PromptWithRationale = "prompt-with-rationale",
    Granted = "granted",
    Denied = "denied",
    Default = "default",
}

// NOTE: For avoid issue with 401 in PansaUTM we can't send empty string
export const DEFAULT_TOKEN = "default-token";

@UntilDestroy()
@Injectable({
    providedIn: "root",
})
export class PushNotificationsService {
    private readonly introWalkthroughService = inject(IntroWalkthroughService);
    private readonly webNotificationService = inject(WebNotificationsService);
    private readonly mobileNotificationsService = inject(MobileNotificationsService);
    private readonly token = new BehaviorSubject<string>(DEFAULT_TOKEN);
    public readonly token$ = this.token.asObservable();

    constructor() {
        this.introWalkthroughService.getIsIntroDone().then((isIntroDone) => {
            if (isIntroDone) {
                this.initializePushNotifications();
            }
        });
    }

    public initializePushNotifications() {
        if (Capacitor.isPluginAvailable("PushNotifications")) {
            this.mobileNotificationsService.initializeNotifications();
            this.mobileNotificationsService.token$.pipe(untilDestroyed(this)).subscribe((token) => this.token.next(token));
        } else {
            this.webNotificationService.initWebNotifications();
            this.webNotificationService.token$.pipe(untilDestroyed(this)).subscribe((token) => this.token.next(token));
        }
    }

    public handleUserCheckinToNotification() {
        this.mobileNotificationsService.handleUserCheckinToNotification();
    }

    public async checkPermissions() {
        return Capacitor.isPluginAvailable("PushNotifications")
            ? (await PushNotifications.checkPermissions()).receive
            : Notification.permission;
    }
}
