import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { Router, RouterModule } from "@angular/router";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { FormUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetModule, PushModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { first, switchMap } from "rxjs";
import { ErrorHandlingService } from "../../../../services/error-handling-service/error-handling-service";
import { ResetPasswordActions } from "../../../../state/reset-password/reset-password.actions";
import { ResetPasswordState } from "../../../../state/reset-password/reset-password.state";
import { PANSA_UTM_PASSWORD_PATTERN, REDIRECT_PARAM, REDIRECT_RESET_PASSWORD } from "../../../../utils/defaults";

export interface RegisterForm {
    password: FormControl<string>;
    confirmPassword: FormControl<string>;
}

interface ResetPasswordEmailVerificationComponentState {
    isTimeout: boolean;
    code: string;
}
@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-lib-reset-password-confirm[code]",
    standalone: true,
    imports: [
        CommonModule,
        SharedUiModule,
        SharedI18nModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        LetModule,
        PushModule,
        RouterModule,
    ],
    templateUrl: "./reset-password-confirm.component.html",
    styleUrls: ["../../shared/onboarding-layout.scss", "./reset-password-confirm.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ResetPasswordConfirmComponent {
    private readonly store = inject(Store);
    private readonly localStore = inject(LocalComponentStore<ResetPasswordEmailVerificationComponentState>);
    private readonly translocoService = inject(TranslocoService);
    private readonly errorHandlingService = inject(ErrorHandlingService);
    private readonly router = inject(Router);

    @Input() public set code(value: string) {
        this.localStore.patchState({ code: value });
    }

    protected readonly isProcessing$ = this.store.select(ResetPasswordState.isProcessing);
    protected readonly confirmNewPassword = new FormGroup<RegisterForm>(
        {
            password: new FormControl<string>("", {
                validators: [Validators.required, Validators.pattern(PANSA_UTM_PASSWORD_PATTERN)],
                nonNullable: true,
            }),
            confirmPassword: new FormControl<string>("", { validators: Validators.required, nonNullable: true }),
        },
        {
            validators: FormUtils.mustMatchValidator("password", "confirmPassword"),
        }
    );

    constructor() {
        this.localStore.setState({
            isTimeout: false,
            code: "",
        });
    }

    protected onSubmit() {
        if (this.confirmNewPassword.invalid) {
            this.confirmNewPassword.markAsTouched();

            return;
        }
        this.store
            .dispatch(
                new ResetPasswordActions.ConfirmPassword({
                    code: this.localStore.selectSnapshotByKey("code"),
                    password: this.confirmNewPassword.controls.password.value,
                })
            )
            .pipe(
                switchMap(() => this.store.select(ResetPasswordState.confirmPasswordError)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((httpErrorResponse) => {
                if (httpErrorResponse) {
                    const errorMessage = this.translocoService.translate("droneTowerMobileLibLogin.apiErrorMessage");
                    this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
                } else {
                    this.router.navigate(["/login"], { queryParams: { [REDIRECT_PARAM]: REDIRECT_RESET_PASSWORD } });
                }
            });
    }
}
