import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Logger } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { catchError } from "rxjs";
import { VERSION } from "../services/mobile-app-version/mobile-app-version.tokens";
import { AuthActions } from "../state/auth/auth.actions";
import { AuthState } from "../state/auth/auth.state";
import { ERROR_MESSAGE_EMAIL_ALREADY_REGISTERED, ERROR_MESSAGE_PHONE_NUMBER_ALREADY_REGISTERED } from "../utils/defaults";

const API_HEADER = "application/vnd.pansa.bff-drone-tower.v1+json";
const CHECKINS_URL_SUBSTRING = "checkins";
const CHECKIN_ARCHIVES_URL_SUBSTRING = "checkin-archives";
const PILOT_URL_SUBSTRING = "pilot";
const PASSWORD_RECOVERY_SUBSTRING = "password-recovery";
const BUSINESS_LOGIC_ERRORS = [
    "drone-tower.checkin.checkin-not-allowed",
    ERROR_MESSAGE_PHONE_NUMBER_ALREADY_REGISTERED,
    ERROR_MESSAGE_EMAIL_ALREADY_REGISTERED,
    "drone-tower.pansa-utm.pilot-data-incomplete",
];
const MISSIONS_SUBSTRING = "missions";

@Injectable()
export class DroneTowerInterceptor implements HttpInterceptor {
    private readonly store = inject(Store);
    private readonly toastrService = inject(ToastrService);
    private readonly translocoService = inject(TranslocoService);
    private readonly version = inject(VERSION);

    public intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler) {
        let headers = httpRequest.headers;
        headers = headers.set("content-type", API_HEADER);

        if (this.isAuthRequiredInRequest(httpRequest)) {
            const accessToken = this.store.selectSnapshot(AuthState.accessToken);
            headers = headers.set("Authorization", `Bearer ${accessToken}`);
        }

        const modifiedReq = httpRequest.clone({
            headers,
        });

        return next.handle(modifiedReq).pipe(
            catchError((error) => {
                if (error.status === HttpStatusCode.Unauthorized && this.isAuthRequiredInRequest(httpRequest)) {
                    const errorMessage = this.translocoService.translate("droneTowerMobile.unauthorizedErrorMessage");
                    this.toastrService.clear();
                    this.toastrService.warning(errorMessage, undefined, {
                        positionClass: "toast-bottom-center",
                    });
                    this.store.dispatch(new AuthActions.Logout());
                } else if (error.status === HttpStatusCode.TooManyRequests) {
                    const errorMessage = this.translocoService.translate("droneTowerMobile.tooManyRequestsErrorMessage");
                    this.toastrService.warning(errorMessage, undefined, {
                        positionClass: "toast-bottom-center",
                    });
                }
                if (!this.isBusinessLogicError(error)) {
                    Logger.captureException(error, { extra: { version: this.version, entity: "DroneTowerInterceptor" } });
                }
                throw error;
            })
        );
    }

    private isBusinessLogicError(httpErrorResponse: HttpErrorResponse) {
        return (
            BUSINESS_LOGIC_ERRORS.includes(httpErrorResponse?.error?.message) ||
            httpErrorResponse.status === HttpStatusCode.Unauthorized ||
            httpErrorResponse.status === HttpStatusCode.TooManyRequests
        );
    }

    private isAuthRequiredInRequest({ url }: HttpRequest<unknown>) {
        return (
            url.includes(CHECKINS_URL_SUBSTRING) ||
            url.includes(CHECKIN_ARCHIVES_URL_SUBSTRING) ||
            (url.includes(PILOT_URL_SUBSTRING) && !url.includes(PASSWORD_RECOVERY_SUBSTRING)) ||
            url.includes(MISSIONS_SUBSTRING)
        );
    }
}
