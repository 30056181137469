import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { Router, RouterModule } from "@angular/router";
import { PhoneNumber, SharedUiModule, requiredValidForSmsPhoneNumberValidator } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { DEFAULT_PHONE_COUNTRY_CODE, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetModule, PushModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { first, switchMap } from "rxjs";
import { ErrorHandlingService } from "../../../../services/error-handling-service/error-handling-service";
import { NameInputFieldComponent } from "../../../../shared/components/name-input-field/name-input-field.component";
import { EnrollmentActions } from "../../../../state/enrollment/enrollment.actions";
import { EnrollmentState } from "../../../../state/enrollment/enrollment.state";
import { NAME_MAX_LENGTH_VALIDATION, NAME_MIN_LENGTH_VALIDATION, NAME_PATTERN_VALIDATION } from "../../../../utils/defaults";
import { PilotDataPhoneVerificationComponent } from "../pilot-data-phone-verification/pilot-data-phone-verification.component";

interface PilotDataComponentState {
    isTooltipOpen: boolean;
    currentStep: PilotDataSteps;
}

interface PilotDataModel {
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    phoneNumber: FormControl<PhoneNumber>;
}

enum PilotDataSteps {
    Form = "Form",
    PhoneVerification = "PhoneVerification",
}

@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-lib-login",
    standalone: true,
    imports: [
        CommonModule,
        SharedUiModule,
        SharedI18nModule,
        FormsModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        LetModule,
        PushModule,
        RouterModule,
        PilotDataPhoneVerificationComponent,
        NameInputFieldComponent,
    ],
    templateUrl: "./pilot-data.component.html",
    styleUrls: ["../../shared/onboarding-layout.scss", "./pilot-data.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PilotDataComponent {
    private readonly store = inject(Store);
    private readonly localStore = inject(LocalComponentStore<PilotDataComponentState>);
    private readonly translocoService = inject(TranslocoService);
    private readonly errorHandlingService = inject(ErrorHandlingService);
    private readonly router = inject(Router);

    protected readonly PilotDataSteps = PilotDataSteps;
    protected readonly pilotDataForm: FormGroup<PilotDataModel> = new FormGroup({
        firstName: new FormControl<string>("", {
            validators: [
                Validators.required,
                Validators.minLength(NAME_MIN_LENGTH_VALIDATION),
                Validators.maxLength(NAME_MAX_LENGTH_VALIDATION),
                Validators.pattern(NAME_PATTERN_VALIDATION),
            ],
            nonNullable: true,
        }),
        lastName: new FormControl<string>("", {
            validators: [
                Validators.required,
                Validators.minLength(NAME_MIN_LENGTH_VALIDATION),
                Validators.maxLength(NAME_MAX_LENGTH_VALIDATION),
                Validators.pattern(NAME_PATTERN_VALIDATION),
            ],
            nonNullable: true,
        }),
        phoneNumber: new FormControl<PhoneNumber>(
            {
                countryCode: DEFAULT_PHONE_COUNTRY_CODE,
                number: "",
            },
            {
                validators: requiredValidForSmsPhoneNumberValidator,
                nonNullable: true,
            }
        ),
    });
    protected readonly isProcessing$ = this.store.select(EnrollmentState.isProcessing);
    protected readonly currentStep$ = this.localStore.selectByKey("currentStep");
    protected readonly isTooltipOpen$ = this.localStore.selectByKey("isTooltipOpen");

    constructor() {
        this.localStore.setState({
            isTooltipOpen: false,
            currentStep: PilotDataSteps.Form,
        });
    }

    protected onSubmit(): void {
        if (this.pilotDataForm.invalid) {
            this.pilotDataForm.markAllAsTouched();

            return;
        }

        this.store
            .dispatch(
                new EnrollmentActions.SetPilotData({
                    phoneNumber: this.pilotDataForm.controls.phoneNumber.value,
                })
            )
            .pipe(
                switchMap(() => this.store.select(EnrollmentState.setPilotDataError)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((httpErrorResponse) => {
                if (httpErrorResponse) {
                    const errorMessage = this.translocoService.translate("droneTowerMobileLibLogin.genericErrorMessage");
                    this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
                } else {
                    this.redirectToStep(PilotDataSteps.PhoneVerification);
                }
            });
    }

    protected redirectToStep(step: PilotDataSteps) {
        this.localStore.patchState({ currentStep: step });
    }

    protected toggleTooltip() {
        this.localStore.patchState(({ isTooltipOpen }) => ({ isTooltipOpen: !isTooltipOpen }));
    }

    protected verify(code: string) {
        this.store
            .dispatch(
                new EnrollmentActions.ConfirmPilotData({
                    phone: this.pilotDataForm.controls.phoneNumber.value,
                    name: this.pilotDataForm.controls.firstName.value,
                    surname: this.pilotDataForm.controls.lastName.value,
                    code: code,
                })
            )
            .pipe(
                switchMap(() => this.store.select(EnrollmentState.confirmPilotDataError)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((httpErrorResponse) => {
                if (httpErrorResponse) {
                    const errorMessage = this.translocoService.translate("droneTowerMobileLibLogin.genericErrorMessage");
                    this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
                } else {
                    this.router.navigate(["/map"], { replaceUrl: true });
                }
            });
    }
}
