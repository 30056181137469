<div class="easter-egg-container" *ngIf="isEasterEggActive$ | ngrxPush">
    <div class="spinner-drone">
        <svg width="500" height="500" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M202.829 197.172a4 4 0 1 1-5.658 5.656L128 133.658l-69.171 69.17a4 4 0 0 1-5.658-5.656L122.343 128 53.171 58.828a4 4 0 0 1 5.658-5.656L128 122.342l69.171-69.17a4 4 0 0 1 5.658 5.656L133.657 128Z"
            />
        </svg>
        <div class="spinner-drone-propeller spinner-drone-top-left">
            <div class="spinner-drone-body">
                <div></div>
            </div>
        </div>
        <div class="spinner-drone-propeller spinner-drone-top-right">
            <div class="spinner-drone-body">
                <div></div>
            </div>
        </div>
        <div class="spinner-drone-propeller spinner-drone-bottom-left">
            <div class="spinner-drone-body">
                <div></div>
            </div>
        </div>
        <div class="spinner-drone-propeller spinner-drone-bottom-right">
            <div class="spinner-drone-body">
                <div></div>
            </div>
        </div>
    </div>
</div>
