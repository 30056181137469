import { DateRange } from "../../models/date-range.model";
import { FlightStatusParams } from "../../state/drone-tower-mobile.state.model";

export function convertFlightStatusParamsTimeToDateRange(flightStatusParams: FlightStatusParams): DateRange {
    const startTime = new Date();
    startTime.setMinutes(startTime.getMinutes() + flightStatusParams.startTime);
    const endTime = new Date(startTime);
    endTime.setMinutes(endTime.getMinutes() + flightStatusParams.duration);

    return {
        startDate: startTime,
        endDate: endTime,
    };
}
