<div class="form-container">
    <h2 class="form-container-item title">{{ "droneTowerMobileLibLogin.phoneVerification.title" | transloco }}</h2>
    <p class="form-container-item">{{ "droneTowerMobileLibLogin.phoneVerification.description" | transloco }}</p>
    <div class="form-container-item">
        <label class="label">{{ "droneTowerMobileLibLogin.phoneVerification.phoneNumberLabel" | transloco }}</label>
        <p class="phone" *ngIf="phoneNumber$ | ngrxPush as phoneNumber">
            <dtm-ui-country-flag-display [country]="phoneNumber.countryCode ?? undefined"></dtm-ui-country-flag-display>
            {{ phoneNumber | formatPhoneNumber }}
        </p>
    </div>
    <div class="form-container-item">
        <dtm-ui-verification-code
            [formControl]="verificationCodeFormControl"
            (verificationCodeResend)="resendCode()"
        ></dtm-ui-verification-code>
    </div>
    <div *ngrxLet="isProcessing$ as isProcessing" class="form-container-item">
        <button type="button" class="button-primary" [disabled]="isProcessing" (click)="onSubmit()">
            <mat-spinner *ngIf="isProcessing" [diameter]="20" color="accent"></mat-spinner>
            <ng-container *ngIf="!isProcessing">
                {{ "droneTowerMobileLibLogin.phoneVerification.verify" | transloco }}
            </ng-container>
        </button>
    </div>
    <p class="form-container-item redirect">
        <button type="button" class="link" (click)="backRedirect.emit()">
            {{ "droneTowerMobileLibLogin.phoneVerification.backToForm" | transloco }}
        </button>
    </p>
</div>
