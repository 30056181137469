import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { CommonModule } from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    Output,
} from "@angular/core";
import { IconDirective } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, MILLISECONDS_IN_SECOND } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetModule, PushModule } from "@ngrx/component";
import { timer } from "rxjs";

const UNLOCK_BUTTON_SAFE_ZONE = 100;
const UNLOCK_TIME_FALLBACK = 5;

interface IntroWalkthroughStepComponentState {
    title: string;
    actionLabel: string;
    isActionDisabled: boolean;
    isDisabledOnScrollTop: boolean;
}

@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-lib-intro-walkthrough-step",
    standalone: true,
    imports: [CommonModule, IconDirective, PushModule, LetModule, TranslocoModule],
    templateUrl: "./intro-walkthrough-step.component.html",
    styleUrls: ["./intro-walkthrough-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class IntroWalkthroughStepComponent implements AfterViewInit {
    @ContentChild("content", { static: false })
    protected contentChildDemoProperty?: ElementRef;

    @Input() public set title(value: string) {
        this.localStore.patchState({ title: value });
    }

    @Input() public set actionLabel(value: string) {
        this.localStore.patchState({ actionLabel: value });
    }

    @Input() public set isDisabledOnScrollTop(value: BooleanInput) {
        this.localStore.patchState({ isDisabledOnScrollTop: coerceBooleanProperty(value) });
    }

    @Output()
    public readonly action = new EventEmitter<void>();

    protected readonly title$ = this.localStore.selectByKey("title");
    protected readonly actionLabel$ = this.localStore.selectByKey("actionLabel");
    protected readonly isActionDisabled$ = this.localStore.selectByKey("isActionDisabled");

    constructor(private readonly localStore: LocalComponentStore<IntroWalkthroughStepComponentState>, private cdr: ChangeDetectorRef) {
        localStore.setState({
            title: "",
            actionLabel: "",
            isActionDisabled: false,
            isDisabledOnScrollTop: false,
        });
    }

    public ngAfterViewInit() {
        if (this.contentChildDemoProperty) {
            const isDisabledOnScrollTop = this.localStore.selectSnapshotByKey("isDisabledOnScrollTop");
            const element = this.contentChildDemoProperty.nativeElement.parentElement as HTMLElement;
            if (isDisabledOnScrollTop && element.scrollHeight !== element.clientHeight) {
                this.localStore.patchState({
                    isActionDisabled: true,
                });
                this.cdr.detectChanges();
            }
        }
        timer(UNLOCK_TIME_FALLBACK * MILLISECONDS_IN_SECOND)
            .pipe(untilDestroyed(this))
            .subscribe(() =>
                this.localStore.patchState({
                    isActionDisabled: false,
                })
            );
    }

    protected enableActionButtonWhenContainerBottomReached(event: Event) {
        const container = event.target as HTMLElement;
        if (container.scrollHeight - container.scrollTop - UNLOCK_BUTTON_SAFE_ZONE <= container.clientHeight) {
            this.localStore.patchState({
                isActionDisabled: false,
            });
        }
    }
}
