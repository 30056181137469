import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterLink } from "@angular/router";
import { IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { IonicModule } from "@ionic/angular";
import { TranslocoModule } from "@jsverse/transloco";
import { LetModule } from "@ngrx/component";
import { CheckinHistoryFilterComponent } from "../checkin-history-filter/checkin-history-filter.component";
import { CheckinHistoryItemComponent } from "../checkin-history-item/checkin-history-item.component";
import { CheckinsHistoryComponent } from "./checkins-history.component";

@NgModule({
    declarations: [CheckinsHistoryComponent],
    imports: [
        CommonModule,
        IconDirective,
        TranslocoModule,
        LetModule,
        RouterLink,
        SharedUiModule,
        SharedI18nModule,
        CheckinHistoryItemComponent,
        IonicModule,
        CheckinHistoryFilterComponent,
    ],
    exports: [CheckinsHistoryComponent],
})
// NOTE: created to avoid issue with default translations in datepicker
export class CheckinsHistoryModule {}
