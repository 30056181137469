<div class="container" *ngrxLet="step$ as stepNumber">
    <img class="drone-tower-logo" alt="logo" src="/assets/images/logo.svg" />
    <drone-tower-mobile-lib-intro-walkthrough-step
        [title]="'droneTowerMobileLib.introWalkthrough.stepLanguage.title' | transloco"
        *ngIf="stepNumber === 0"
        [actionLabel]="'droneTowerMobileLib.introWalkthrough.nextLabel' | transloco"
        (action)="setNextStep()"
    >
        <p [innerHTML]="'droneTowerMobileLib.introWalkthrough.stepLanguage.content' | transloco"></p>
        <ul *ngrxLet="activeLanguage$ as activeLanguage">
            <li
                *ngFor="let languageDefinition of languageDefinitions"
                class="language-item"
                [ngClass]="{ 'language-item-active': activeLanguage == languageDefinition.alpha2Code }"
                (click)="handleLanguageChange(languageDefinition)"
            >
                <dtm-ui-country-flag-display [country]="languageDefinition.flagName"></dtm-ui-country-flag-display>
                <span>{{ languageDefinition.displayName }}</span>
            </li>
        </ul>
    </drone-tower-mobile-lib-intro-walkthrough-step>
    <drone-tower-mobile-lib-intro-walkthrough-step
        [title]="'droneTowerMobileLib.introWalkthrough.stepWelcome.title' | transloco"
        *ngIf="stepNumber === 1"
        [actionLabel]="'droneTowerMobileLib.introWalkthrough.nextLabel' | transloco"
        (action)="setNextStep()"
    >
        <p [innerHTML]="'droneTowerMobileLib.introWalkthrough.stepWelcome.content' | transloco"></p>
    </drone-tower-mobile-lib-intro-walkthrough-step>

    <drone-tower-mobile-lib-intro-walkthrough-step
        [title]="'droneTowerMobileLib.introWalkthrough.stepInterface.title' | transloco"
        *ngIf="stepNumber === 2"
        [actionLabel]="'droneTowerMobileLib.introWalkthrough.nextLabel' | transloco"
        (action)="setNextStep()"
    >
        <p [innerHTML]="'droneTowerMobileLib.introWalkthrough.stepInterface.content' | transloco"></p>
        <ul>
            <li>
                <dtm-ui-icon name="map-pin-fill"></dtm-ui-icon
                >{{ "droneTowerMobileLib.introWalkthrough.stepInterface.checkinLabel" | transloco }}
            </li>
            <li>
                <dtm-ui-icon name="edit-fill"></dtm-ui-icon
                >{{ "droneTowerMobileLib.introWalkthrough.stepInterface.flightStatusLabel" | transloco }}
            </li>
            <li>
                <dtm-ui-icon name="information-fill"></dtm-ui-icon
                >{{ "droneTowerMobileLib.introWalkthrough.stepInterface.locationInfoLabel" | transloco }}
            </li>
        </ul>
    </drone-tower-mobile-lib-intro-walkthrough-step>

    <drone-tower-mobile-lib-intro-walkthrough-step
        [title]="'droneTowerMobileLib.introWalkthrough.stepFlightParameters.title' | transloco"
        *ngIf="stepNumber === 3"
        [actionLabel]="'droneTowerMobileLib.introWalkthrough.nextLabel' | transloco"
        (action)="setNextStep()"
    >
        <p [innerHTML]="'droneTowerMobileLib.introWalkthrough.stepFlightParameters.content' | transloco"></p>
        <ul>
            <li>
                <dtm-ui-icon name="drone"></dtm-ui-icon
                >{{ "droneTowerMobileLib.introWalkthrough.stepFlightParameters.weightLabel" | transloco }}
            </li>
            <li>
                <dtm-ui-icon name="complex-top-height"></dtm-ui-icon>
                {{ "droneTowerMobileLib.introWalkthrough.stepFlightParameters.heightLabel" | transloco }}
            </li>
            <li>
                <dtm-ui-icon name="road-map"></dtm-ui-icon
                >{{ "droneTowerMobileLib.introWalkthrough.stepFlightParameters.areaLabel" | transloco }}
            </li>
            <li>
                <dtm-ui-icon name="draft"></dtm-ui-icon
                >{{ "droneTowerMobileLib.introWalkthrough.stepFlightParameters.categoryLabel" | transloco }}
            </li>
            <li>
                <dtm-ui-icon name="time"></dtm-ui-icon
                >{{ "droneTowerMobileLib.introWalkthrough.stepFlightParameters.timeLabel" | transloco }}
            </li>
        </ul>
    </drone-tower-mobile-lib-intro-walkthrough-step>

    <drone-tower-mobile-lib-intro-walkthrough-step
        [title]="'droneTowerMobileLib.introWalkthrough.stepGeozone.title' | transloco"
        *ngIf="stepNumber === 4"
        [actionLabel]="'droneTowerMobileLib.introWalkthrough.nextLabel' | transloco"
        (action)="setNextStep()"
    >
        <p [innerHTML]="'droneTowerMobileLib.introWalkthrough.stepGeozone.content' | transloco"></p>
        <ul>
            <li>
                <dtm-ui-icon name="checkbox-circle-fill" class="success"></dtm-ui-icon>
                {{ "droneTowerMobileLib.introWalkthrough.stepGeozone.noZonesLabel" | transloco }}
            </li>
            <li>
                <dtm-ui-icon name="error-warning-fill" class="success"></dtm-ui-icon>
                {{ "droneTowerMobileLib.introWalkthrough.stepGeozone.zoneWithoutRestrictionLabel" | transloco }}
            </li>
            <li>
                <dtm-ui-icon name="error-warning-fill" class="warning"></dtm-ui-icon>
                {{ "droneTowerMobileLib.introWalkthrough.stepGeozone.flightRestrictedLabel" | transloco }}
            </li>
            <li>
                <dtm-ui-icon name="sign-prohibition" class="error"></dtm-ui-icon>
                {{ "droneTowerMobileLib.introWalkthrough.stepGeozone.flightForbiddenLabel" | transloco }}
            </li>
        </ul>
        <p [innerHTML]="'droneTowerMobileLib.introWalkthrough.stepGeozone.learnMore' | transloco"></p>
    </drone-tower-mobile-lib-intro-walkthrough-step>

    <drone-tower-mobile-lib-intro-walkthrough-step
        [title]="'droneTowerMobileLib.introWalkthrough.stepRules.title' | transloco"
        *ngIf="stepNumber === 5"
        [actionLabel]="'droneTowerMobileLib.introWalkthrough.stepRules.confirmLabel' | transloco"
        (action)="confirm()"
        isDisabledOnScrollTop
    >
        <drone-tower-mobile-lib-rules-content #content></drone-tower-mobile-lib-rules-content>
    </drone-tower-mobile-lib-intro-walkthrough-step>
    <div class="stepper">
        <span
            *ngFor="let step of steps; let index = index"
            [ngClass]="{ active: stepNumber === index, clickable: stepNumber > index }"
            (click)="stepNumber > index && setStep(index)"
            class="dot"
        >
        </span>
    </div>
</div>
