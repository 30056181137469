<ng-container *ngIf="!(isFloatingMenuHidden$ | ngrxPush)">
    <div class="container" *ngrxLet="{ isOpen: isOpen$, isCheckinActive: isCheckinActive$, isLoading: isLoading$ } as vm">
        <button
            type="button"
            class="button-icon option"
            [class.open]="vm.isOpen"
            (click)="
                menuOptionActionClickedHandler(vm.isCheckinActive ? FloatingMenuActionName.FinishCheckin : FloatingMenuActionName.Checkin)
            "
        >
            <dtm-ui-icon *ngIf="!vm.isCheckinActive" slot="start" name="flight-takeoff"></dtm-ui-icon>
            <dtm-ui-icon *ngIf="vm.isCheckinActive" slot="start" name="flight-land"></dtm-ui-icon>
        </button>
        <button
            type="button"
            class="button-icon option"
            [class.open]="vm.isOpen"
            (click)="
                menuOptionActionClickedHandler(
                    vm.isCheckinActive ? FloatingMenuActionName.LostControl : FloatingMenuActionName.OpenStatusForm
                )
            "
        >
            <dtm-ui-icon *ngIf="!vm.isCheckinActive" slot="start" name="equalizer-fill"></dtm-ui-icon>
            <dtm-ui-icon *ngIf="vm.isCheckinActive" slot="start" name="alarm-warning-fill" class="land-now-icon"></dtm-ui-icon>
        </button>
        <button type="button" class="button-icon option" [class.open]="vm.isOpen" (click)="navigateTo('/location-info')">
            <dtm-ui-icon slot="start" name="map-info"></dtm-ui-icon>
        </button>
        <button type="button" class="button-icon close" [class.open]="vm.isOpen" (click)="toggleOpen()" [disabled]="vm.isLoading">
            <dtm-ui-icon slot="start" [name]="vm.isOpen ? 'close' : 'more'" *ngIf="!vm.isLoading"></dtm-ui-icon>
            <mat-spinner *ngIf="vm.isLoading" [diameter]="30"></mat-spinner>
        </button>
    </div>
</ng-container>
