import { Injectable } from "@angular/core";
import { Map } from "leaflet";

@Injectable()
export class MapService {
    private map: Map | null = null;

    public get mapInstance() {
        return this.map;
    }

    public init(mapInstance: Map) {
        this.map = mapInstance;
    }
}
