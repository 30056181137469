import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LetModule, PushModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { map } from "rxjs";
import { ConfigService } from "../../services/config/config.service";
import { PushNotificationsService } from "../../services/push-notifications/push-notifications.service";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { DroneTowerMobileState } from "../../state/drone-tower-mobile.state";

@Component({
    selector: "drone-tower-mobile-lib-info",
    standalone: true,
    imports: [CommonModule, PushModule, LetModule, HeaderComponent, SharedUiModule],
    templateUrl: "./info.component.html",
    styleUrls: ["./info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoComponent {
    private readonly store = inject(Store);
    private readonly pushNotificationsService = inject(PushNotificationsService);
    private readonly configService = inject(ConfigService);
    protected readonly geoserverUrl$ = this.store
        .select(DroneTowerMobileState.capabilities)
        .pipe(map((capabilities) => capabilities.geoserverUrl));
    protected readonly token$ = this.pushNotificationsService.token$;
    protected readonly config = this.configService.config;
}
