<div class="form-container">
    <h2 class="form-container-item title">{{ "droneTowerMobileLibLogin.emailVerification.title" | transloco }}</h2>
    <p class="form-container-item">{{ "droneTowerMobileLibLogin.emailVerification.description" | transloco }}</p>
    <div class="form-container-item" *ngIf="email$ | ngrxPush as email">
        <label class="label">{{ "droneTowerMobileLibLogin.emailVerification.emailLabel" | transloco }}</label>
        <p>{{ email }}</p>
    </div>
    <div class="form-container-item">
        <dtm-ui-verification-code
            [formControl]="verificationCodeFormControl"
            (verificationCodeResend)="resendCode()"
        ></dtm-ui-verification-code>
    </div>
    <div *ngrxLet="isProcessing$ as isProcessing" class="form-container-item">
        <button type="button" class="button-primary" [disabled]="isProcessing" (click)="onSubmit()">
            <mat-spinner *ngIf="isProcessing" [diameter]="20" color="accent"></mat-spinner>
            <ng-container *ngIf="!isProcessing">
                {{ "droneTowerMobileLibLogin.emailVerification.verify" | transloco }}
            </ng-container>
        </button>
    </div>
    <p class="form-container-item redirect">
        <button type="button" class="link" (click)="registerRedirect.emit()">
            {{ "droneTowerMobileLibLogin.emailVerification.backToRegister" | transloco }}
        </button>
    </p>
</div>
