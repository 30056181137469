import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoModule } from "@jsverse/transloco";
import { PushModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { Observable, combineLatestWith, map } from "rxjs";
import { CollidingAirspaceElement, FlightStatus } from "../../models/flight-conditions.model";
import { AlertPipe } from "../../pipes/alert.pipe";
import { GeoZonePipe } from "../../pipes/geo-zone.pipe";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { DroneTowerMobileState } from "../../state/drone-tower-mobile.state";
import { GeozoneDateComponent } from "../geozone-date/geozone-date.component";
import { H24Component } from "../h24/h24.component";
import { PlannedActivitiesElasticZoneComponent } from "../planned-activities-elastic-zone/planned-activities-elastic-zone.component";
import { PlannedActivitiesComponent } from "../planned-activities/planned-activities.component";

@Component({
    selector: "drone-tower-mobile-lib-airspace-details",
    standalone: true,
    imports: [
        CommonModule,
        HeaderComponent,
        SharedUiModule,
        TranslocoModule,
        SharedI18nModule,
        PushModule,
        AlertPipe,
        GeozoneDateComponent,
        GeoZonePipe,
        H24Component,
        PlannedActivitiesComponent,
        PlannedActivitiesElasticZoneComponent,
    ],
    templateUrl: "./airspace-details.component.html",
    styleUrls: ["./airspace-details.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AirspaceDetailsComponent {
    private readonly store = inject(Store);

    protected readonly airspaceElement$ = this.store.select(DroneTowerMobileState.airspaceElement).pipe(RxjsUtils.filterFalsy());
    protected readonly airspaceElementDetails$: Observable<CollidingAirspaceElement | undefined> = this.airspaceElement$.pipe(
        combineLatestWith(this.store.select(DroneTowerMobileState.flightConditionStatusDetails).pipe(RxjsUtils.filterFalsy())),
        map(
            ([airspaceElement, flightConditionsStatusDetails]) =>
                flightConditionsStatusDetails.collidingAirspaceElements.find(
                    (collidingAirspaceElement) => collidingAirspaceElement.designator === airspaceElement.designator
                ) ||
                flightConditionsStatusDetails.collidingInactiveAirspaceElements.find(
                    (collidingAirspaceElement) => collidingAirspaceElement.designator === airspaceElement.designator
                )
        )
    );

    protected readonly FlightStatus = FlightStatus;
}
