import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LeafletMapModule } from "@dtm-frontend/shared/map/leaflet";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LetModule, PushModule } from "@ngrx/component";
import { CheckInComponent } from "../../components/check-in/check-in.component";
import { FlightStatusFormComponent } from "../../components/flight-status-form/flight-status-form.component";
import { FloatingMenuComponent } from "../../components/floating-menu/floating-menu.component";
import { KpIndexComponent } from "../../components/kp-index/kp-index.component";
import { LocationInfoComponent } from "../../components/location-info/location-info.component";
import { UserDataComponent } from "../../components/user-data/user-data.component";
import { ActiveCheckinsDirective } from "./components/active-checkins/active-checkins.directive";
import { MapComponent } from "./components/map/map.component";
import { UserPositionMarkerDirective } from "./components/marker/user-position-marker.directive";
import { MissionAreaDirective } from "./components/mission-area/mission-area.directive";
import { MapRoutingModule } from "./map-routing.module";

@NgModule({
    declarations: [MapComponent, UserPositionMarkerDirective, ActiveCheckinsDirective, MissionAreaDirective],
    imports: [
        LeafletMapModule,
        CheckInComponent,
        FlightStatusFormComponent,
        FloatingMenuComponent,
        KpIndexComponent,
        LocationInfoComponent,
        MapRoutingModule,
        PushModule,
        LetModule,
        CommonModule,
        UserDataComponent,
        SharedUiModule,
    ],
    exports: [MapComponent],
})
export class MapModule {}
