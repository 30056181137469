<drone-tower-mobile-lib-header [headerLabel]="'droneTowerMobileLib.userData.headerLabel' | transloco"></drone-tower-mobile-lib-header>
<ng-container
    *ngrxLet="{
        isLoading: isLoading$,
        isError: isError$,
        userCheckin: userCheckin$,
        isPilotDataFeatureAvailable: isPilotDataFeatureAvailable$
    } as vm"
>
    <dtm-ui-loader-container [isShown]="vm.isLoading" class="scroll-shadows">
        <form [formGroup]="userDataForm" *ngIf="!vm.isError; else errorTemplate" (click)="tryDisplayCheckinInProgress()">
            <drone-tower-mobile-lib-name-input-field
                class="form-container-item"
                [label]="'droneTowerMobileLib.userData.firstNameLabel' | transloco"
                [control]="userDataForm.controls.name"
                *ngIf="vm.isPilotDataFeatureAvailable"
            ></drone-tower-mobile-lib-name-input-field>
            <drone-tower-mobile-lib-name-input-field
                class="form-container-item"
                [label]="'droneTowerMobileLib.userData.lastNameLabel' | transloco"
                [control]="userDataForm.controls.surname"
                *ngIf="vm.isPilotDataFeatureAvailable"
            ></drone-tower-mobile-lib-name-input-field>
            <dtm-ui-input-field [isClearable]="false">
                <label>{{ "droneTowerMobileLib.userData.pilotNumberLabel" | transloco }}</label>
                <input formControlName="pilotNumber" matInput type="text" />
                <div class="field-error" *dtmUiFieldHasError="userDataForm.controls.pilotNumber; name: 'maxlength'; error as error">
                    {{ "droneTowerMobileLib.userData.maxLengthError" | transloco : { maxLength: error.requiredLength } }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field [isClearable]="false">
                <label>{{ "droneTowerMobileLib.userData.operatorNumberLabel" | transloco }}</label>
                <input formControlName="operatorNumber" matInput type="text" />
                <div class="field-error" *dtmUiFieldHasError="userDataForm.controls.operatorNumber; name: 'maxlength'; error as error">
                    {{ "droneTowerMobileLib.userData.maxLengthError" | transloco : { maxLength: error.requiredLength } }}
                </div>
            </dtm-ui-input-field>
            <div class="phone-number-wrapper">
                <div class="chip success" *ngIf="isPhoneNumberVerified$ | ngrxPush; else notVerifiedChipTemplate">
                    <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                    {{ "droneTowerMobileLib.userData.phoneNumberVerifiedLabel" | transloco }}
                </div>
                <ng-template #notVerifiedChipTemplate>
                    <div class="chip error">
                        <dtm-ui-icon name="error-warning"></dtm-ui-icon>
                        {{ "droneTowerMobileLib.userData.phoneNumberNotVerifiedLabel" | transloco }}
                    </div>
                </ng-template>
                <dtm-ui-edit-phone-number
                    [phoneNumber]="phoneNumber$ | ngrxPush"
                    [isRequestedPhoneChange]="isRequestedPhoneChange$ | ngrxPush"
                    [hasPhoneNumberConflictError]="hasPhoneNumberConflictError$ | ngrxPush"
                    [verificationCodeMask]="droneTowerVerificationCodeMask"
                    (requestDataChange)="changePhoneNumber($event)"
                    (closePanel)="closeModifyPhoneNumberPanel()"
                    [phoneNumberChangeDescription]="'droneTowerMobileLib.userData.phoneNumberChangeDescription' | transloco"
                    [shouldCheckIfPhoneNumberSameValue]="false"
                    [isDisabled]="!!vm.userCheckin"
                >
                </dtm-ui-edit-phone-number>
            </div>
            <dtm-ui-checkbox-field formControlName="operatorDataCanBePublished">
                {{ "droneTowerMobileLib.userData.operatorDataCanBePublishedLabel" | transloco }}
            </dtm-ui-checkbox-field>
        </form>
        <ng-template #errorTemplate>
            <div class="wrapper-error">{{ "droneTowerMobileLib.userData.getUserDataError" | transloco }}</div>
        </ng-template>
    </dtm-ui-loader-container>
    <section class="buttons-section">
        <button type="button" class="button-secondary" routerLink="/">
            {{ "droneTowerMobileLib.flightStatusForm.cancelButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" (click)="saveUserData()" *ngIf="!vm.isError && !vm.userCheckin">
            {{ "droneTowerMobileLib.flightStatusForm.saveButtonLabel" | transloco }}
        </button>
    </section>
</ng-container>
