<h2 mat-dialog-title>{{ "droneTowerMobileLib.operators.deleteQuestion" | transloco }}</h2>
<div class="content-container">
    <div mat-dialog-actions class="actions">
        <button type="button" class="button-secondary" mat-dialog-close>
            {{ "droneTowerMobileLib.operators.cancelLabel" | transloco }}
        </button>
        <button type="button" class="button-warn" (click)="confirm()">
            {{ "droneTowerMobileLib.operators.deleteLabel" | transloco }}
        </button>
    </div>
</div>
