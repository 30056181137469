import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "alert",
    standalone: true,
})
export class AlertPipe implements PipeTransform {
    public transform(value: string, isAlert: boolean): string {
        return isAlert ? "droneTowerMobileLib.locationInfo.alertLabel" : value;
    }
}
