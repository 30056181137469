import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Directive, EventEmitter, Inject, inject, Input, OnInit, Output } from "@angular/core";
import { GeolocationService, Position } from "@dtm-frontend/shared/map";
import { LEAFLET_MAP_CONFIG, LEAFLET_MAP_PROVIDER, LeafletMapConfig, LeafletMapProvider } from "@dtm-frontend/shared/map/leaflet";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { BaseIconOptions, Circle, Icon, LatLng, LatLngTuple, LayerGroup, LeafletMouseEvent, Map, Marker } from "leaflet";
import { ToastrService } from "ngx-toastr";
import { distinctUntilChanged, map, skip } from "rxjs";
import { AppInfoService } from "../../../../services/app-info/app-info.service";
import { DroneTowerMobileState } from "../../../../state/drone-tower-mobile.state";
import { MarkerPosition } from "../../../../state/drone-tower-mobile.state.model";
import { IS_USER_POSITION_MARKER_MOVABLE } from "../../map.tokens";
import { MapService } from "../map/map.service";
import { UserPositionMarkerService } from "./user-position-marker.service";

interface UserPositionMarkerDirectiveState {
    isCheckinOpen: boolean;
    isOnGPSPosition: boolean;
    mapRedirectPosition: LatLng | undefined;
}

const ICON_OPTIONS: BaseIconOptions = {
    iconUrl: "assets/images/map-marker.svg",
    /* eslint-disable no-magic-numbers */
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    /* eslint-enable */
};

const LOCATION_DISABLED_MESSAGE = "location disabled";

@UntilDestroy()
@Directive({
    selector: "drone-tower-mobile-lib-user-position-marker[isCheckinOpen]",
    providers: [LocalComponentStore],
})
export class UserPositionMarkerDirective implements OnInit {
    private readonly appInfoService = inject(AppInfoService);

    @Input()
    public set isCheckinOpen(value: boolean | undefined) {
        this.localStore.patchState({
            isCheckinOpen: coerceBooleanProperty(value),
        });
    }

    @Input()
    public set isOnGPSPosition(value: boolean | undefined) {
        this.localStore.patchState({
            isOnGPSPosition: coerceBooleanProperty(value),
        });
    }

    @Input()
    public set mapRedirectPosition(value: LatLng | undefined | null) {
        this.localStore.patchState({
            mapRedirectPosition: value ?? undefined,
        });
    }

    @Output() private readonly markerPositionChange: EventEmitter<MarkerPosition | null> = new EventEmitter();

    private position: Position | null = null;
    private marker: Marker | null = null;
    private map: Map | null = null;
    private circle: Circle | null = null;
    private readonly markersGroup = new LayerGroup();
    private readonly icon = new Icon(ICON_OPTIONS);
    private readonly radius$ = this.store
        .select(DroneTowerMobileState.flightStatusParams)
        .pipe(map((flightStatusParams) => flightStatusParams.radius));
    private readonly userCheckin$ = this.store.select(DroneTowerMobileState.userCheckin);
    private readonly isCheckinOpen$ = this.localStore.selectByKey("isCheckinOpen");
    private readonly isOnGpsPosition$ = this.localStore.selectByKey("isOnGPSPosition");
    private readonly mapRedirectPosition$ = this.localStore.selectByKey("mapRedirectPosition");
    private readonly isMissionSelected$ = this.store.select(DroneTowerMobileState.selectedCheckinMission);
    private readonly userPositionMarkerService = inject(UserPositionMarkerService);

    constructor(
        @Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider,
        @Inject(LEAFLET_MAP_CONFIG) public readonly config: LeafletMapConfig,
        @Inject(IS_USER_POSITION_MARKER_MOVABLE) private readonly isUserPositionMarkerMovable: boolean,
        private readonly geolocationService: GeolocationService,
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService,
        private readonly localStore: LocalComponentStore<UserPositionMarkerDirectiveState>,
        private readonly mapService: MapService,
        private readonly store: Store
    ) {
        this.localStore.setState({
            isCheckinOpen: false,
            isOnGPSPosition: false,
            mapRedirectPosition: undefined,
        });
        this.createOrUpdateMarker();
    }

    public ngOnInit() {
        this.radius$.pipe(untilDestroyed(this)).subscribe((radius) => {
            this.circle?.setRadius(radius);
        });
        this.isCheckinOpen$.pipe(untilDestroyed(this)).subscribe((isCheckinOpen) => {
            if (isCheckinOpen) {
                this.setUserPosition();
                this.map?.closePopup();
            }
            const selectedMission = this.store.selectSnapshot(DroneTowerMobileState.selectedCheckinMission);
            const userCheckin = this.store.selectSnapshot(DroneTowerMobileState.userCheckin);
            if ((selectedMission && isCheckinOpen) || userCheckin?.properties.missionArea) {
                this.circle?.remove();

                return;
            }
            const radius = this.store.selectSnapshot(DroneTowerMobileState.flightStatusParams).radius;
            this.circle?.setRadius(radius);
            this.circle?.addTo(this.markersGroup);
        });
        this.isOnGpsPosition$.pipe(RxjsUtils.filterFalsy(), untilDestroyed(this)).subscribe(() => this.setUserPosition(false));

        this.mapRedirectPosition$.pipe(untilDestroyed(this)).subscribe(async (initialPosition) => {
            if (initialPosition) {
                this.setSelectedPosition(initialPosition);
                this.map?.flyTo(initialPosition, this.config.zoom.userPosition, {
                    animate: false,
                });
            }
        });

        this.isMissionSelected$.pipe(untilDestroyed(this)).subscribe((mission) => {
            if (mission) {
                this.circle?.remove();

                return;
            }
            const radius = this.store.selectSnapshot(DroneTowerMobileState.flightStatusParams).radius;
            this.circle?.setRadius(radius);
            this.circle?.addTo(this.markersGroup);
        });
        this.userCheckin$
            .pipe(
                skip(1),
                distinctUntilChanged((left, right) => left?.properties.id === right?.properties.id),
                untilDestroyed(this)
            )
            .subscribe((userCheckin) => {
                if (userCheckin) {
                    this.marker?.remove();
                    this.marker = null;
                    const coordinates = [...userCheckin.geometry.coordinates].reverse() as LatLngTuple;
                    this.map?.flyTo(coordinates, this.config.zoom.userPosition, {
                        animate: false,
                    });
                } else {
                    this.setUserPosition();
                }
                this.circle?.remove();
            });
    }

    private async createOrUpdateMarker() {
        this.map = await this.mapProvider.getMap();
        this.mapService.init(this.map);
        await this.setUserPosition();
        this.map.addLayer(this.markersGroup);
        this.map.on("click", ({ latlng }: LeafletMouseEvent) => {
            const currentMarkerPosition = this.store.selectSnapshot(DroneTowerMobileState.markerPosition);
            if (currentMarkerPosition?.latitude === latlng.lat && currentMarkerPosition?.longitude === latlng.lng) {
                return;
            }

            this.setSelectedPosition(latlng);
        });
    }

    private setSelectedPosition(latlng: LatLng) {
        const userCheckin = this.store.selectSnapshot(DroneTowerMobileState.userCheckin);
        const isCheckinOpen = this.localStore.selectSnapshotByKey("isCheckinOpen");
        if (!userCheckin && !isCheckinOpen) {
            if (!this.marker) {
                this.createMarker(latlng.lat, latlng.lng);
            } else {
                this.marker?.setLatLng(latlng);
                this.circle?.setLatLng(latlng);
            }
            const customPosition: MarkerPosition = {
                latitude: latlng.lat,
                longitude: latlng.lng,
                isUserPosition: this.isUserPositionMarkerMovable,
            };
            this.markerPositionChange.emit(customPosition);
            this.marker?.on("contextmenu", async () => {
                await this.setUserPosition();
            });
        }
    }

    private async setUserPosition(isUserPositionMarkerMovable = this.isUserPositionMarkerMovable) {
        try {
            this.position = await this.geolocationService.getUserPosition();
        } catch (error) {
            const errorMessage = this.translocoService.translate("droneTowerMobile.geolocationError");
            this.toastrService
                .error(errorMessage, undefined, {
                    positionClass: "toast-bottom-center",
                    enableHtml: true,
                    messageClass: "toast-with-actions",
                })
                .onTap.pipe(untilDestroyed(this))
                .subscribe(() =>
                    (error as { message: string })?.message === LOCATION_DISABLED_MESSAGE
                        ? this.appInfoService.openLocationSettings()
                        : this.appInfoService.openAppSettings()
                );
        }

        const userCheckin = this.store.selectSnapshot(DroneTowerMobileState.userCheckin);
        if (this.position && !userCheckin) {
            const initialPosition: MarkerPosition = {
                latitude: this.position.latitude,
                longitude: this.position.longitude,
                isUserPosition: true,
            };
            if (!this.marker) {
                this.createMarker(this.position.latitude, this.position.longitude);
                this.map?.flyTo([this.position.latitude, this.position.longitude], this.config.zoom.userPosition, { animate: false });
                if (isUserPositionMarkerMovable) {
                    this.markerPositionChange.emit(initialPosition);
                }
            } else if (!isUserPositionMarkerMovable) {
                this.marker.setLatLng([this.position.latitude, this.position.longitude]);
                this.circle?.setLatLng([this.position.latitude, this.position.longitude]);
                this.centerMapOnMarker();
            } else {
                this.centerMapOnMarker();
            }
            if (!isUserPositionMarkerMovable) {
                this.markerPositionChange.emit(initialPosition);
            }
        }
    }

    private centerMapOnMarker() {
        const coords = this.marker?.getLatLng();
        if (!coords) {
            return;
        }
        const isCheckinOpen = this.localStore.selectSnapshotByKey("isCheckinOpen");
        this.userPositionMarkerService.centerMap(this.map, [coords.lat, coords.lng], isCheckinOpen);
    }

    private createMarker(latitude: number, longitude: number, withCircle = true) {
        if (this.marker) {
            return;
        }
        this.marker = new Marker([latitude, longitude], { icon: this.icon }).addTo(this.markersGroup);

        if (!withCircle && !this.circle) {
            return;
        }
        const radius = this.store.selectSnapshot(DroneTowerMobileState.flightStatusParams).radius;
        this.circle = new Circle([latitude, longitude], { radius }).addTo(this.markersGroup);
    }
}
