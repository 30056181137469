import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Capacitor } from "@capacitor/core";
import {
    AppNotRespondingDialogComponent,
    DroneTowerMobileState,
    UpdateDialogComponent,
    VERSION,
} from "@dtm-frontend/drone-tower-mobile-lib";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { first, map, tap } from "rxjs";

const VERSION_CHUNKS_COUNT = 3;

@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-root",
    templateUrl: "app.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
    private readonly translocoService = inject(TranslocoService);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly store = inject(Store);
    private readonly dialog = inject(MatDialog);
    private readonly version = inject(VERSION);

    constructor() {
        // TODO: DTOWER-383 Global Translation Issue
        this.translocoService.events$
            .pipe(
                tap(() => setTimeout(() => this.changeDetectorRef.detectChanges())),
                untilDestroyed(this)
            )
            .subscribe();

        this.store
            .select(DroneTowerMobileState.isAppNotResponding)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() =>
                this.dialog.open(AppNotRespondingDialogComponent, {
                    disableClose: true,
                    autoFocus: false,
                })
            );

        if (Capacitor.isNativePlatform()) {
            this.store
                .select(DroneTowerMobileState.capabilities)
                .pipe(
                    map((capabilities) => capabilities.mobileAppVersion),
                    RxjsUtils.filterFalsy(),
                    first(),
                    untilDestroyed(this)
                )
                .subscribe((apiVersion) => {
                    if (this.isVersionGreaterOrEqual(this.version, apiVersion.latestVersion)) {
                        return;
                    }
                    this.dialog.open(UpdateDialogComponent, {
                        disableClose: true,
                        autoFocus: false,
                        data: {
                            isUpdateRequired: !this.isVersionGreaterOrEqual(this.version, apiVersion.minimalVersion),
                        },
                    });
                });
        }
    }

    private isVersionGreaterOrEqual(left: string, right: string): boolean {
        const leftChunks = left.split(".").map(Number);
        const rightChunks = right.split(".").map(Number);

        for (let index = 0; index < VERSION_CHUNKS_COUNT; index++) {
            if (leftChunks[index] > rightChunks[index]) {
                return true;
            }
            if (leftChunks[index] < rightChunks[index]) {
                return false;
            }
        }

        return true;
    }
}
