import { PageResponseBody } from "@dtm-frontend/shared/ui";
import { CheckinHistory, CheckinHistoryListWithPageable } from "../../models/checkins.model";

export type CheckinHistoryResponseBodyWithPages = PageResponseBody<CheckinHistoryResponseBody>;

export interface CheckinHistoryResponseBody {
    id: string;
    startDateTime: string;
    endDateTime: string;
    overdueInMinutes: number;
    maxHeight: number;
    radius: number;
    coordinates: {
        latitude: number;
        longitude: number;
    };
    missionName: string;
    userNote: string;
}

export function convertGetCheckinHistoryResponseBodyToCheckinHistoryListWithPageable(
    response: CheckinHistoryResponseBodyWithPages
): CheckinHistoryListWithPageable {
    return {
        pageNumber: response.number,
        pageSize: response.size,
        totalElements: response.totalElements,
        content: response.content.map((element) => ({
            id: element.id,
            startDateTime: new Date(element.startDateTime),
            endDateTime: new Date(element.endDateTime),
            overdueInMinutes: element.overdueInMinutes,
            maxHeight: element.maxHeight,
            radius: element.radius,
            coordinates: element.coordinates,
            missionName: element.missionName,
            userNote: element.userNote,
        })),
    };
}
export function convertGetCheckinHistoryResponseBodyToCheckinHistory(response: CheckinHistoryResponseBody): CheckinHistory {
    return {
        id: response.id,
        startDateTime: new Date(response.startDateTime),
        endDateTime: new Date(response.endDateTime),
        overdueInMinutes: response.overdueInMinutes,
        maxHeight: response.maxHeight,
        radius: response.radius,
        coordinates: response.coordinates,
        missionName: response.missionName,
        userNote: response.userNote,
    };
}
