import { ChangeDetectionStrategy, Component, Inject, inject, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { LEAFLET_MAP_CONFIG, LeafletMapConfig, LeafletMapLayerConfig } from "@dtm-frontend/shared/map/leaflet";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Platform } from "@ionic/angular";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { LatLngTuple, MapOptions } from "leaflet";
import { combineLatest, first, map, startWith, switchMap, tap } from "rxjs";
import { CheckinDialogComponent, CheckinDialogOptions } from "../../../../components/checkin-dialog/checkin-dialog.component";
import { FloatingMenuActionName } from "../../../../components/floating-menu/floating-menu.component";
import { ActiveCheckinStatus } from "../../../../models/checkins.model";
import { MinMaxRange } from "../../../../models/flight-conditions.model";
import { AlertApiService } from "../../../../services/alert-api/alert-api.service";
import { BUILD_TYPE_PRODUCTION } from "../../../../services/build-type/build-type.tokens";
import { convertFlightStatusParamsTimeToDateRange } from "../../../../services/flight-status/flight-status-converter";
import { DroneTowerMobileActions } from "../../../../state/drone-tower-mobile.actions";
import { DroneTowerFeatures, FlightStatusParams, MarkerPosition } from "../../../../state/drone-tower-mobile.state.model";
import { UserPositionMarkerService } from "../marker/user-position-marker.service";
import { ErrorHandlingService } from "./../../../../services/error-handling-service/error-handling-service";
import { DroneTowerMobileState } from "./../../../../state/drone-tower-mobile.state";
import { ERROR_MESSAGE_PILOT_DATA_INCOMPLETE } from "./../../../../utils/defaults";
import { MapService } from "./map.service";

interface MapComponentState {
    isCheckInOpen: boolean;
    isOnGPSPosition: boolean;
}

const SLIDE_ANIMATION_DURATION = 200;
const IS_CHECK_IN_OPEN_KEY = "isCheckInOpen";

@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-lib-map",
    templateUrl: "./map.component.html",
    styleUrls: ["./map.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation(SLIDE_ANIMATION_DURATION)],
    providers: [LocalComponentStore, MapService],
})
export class MapComponent implements OnInit {
    private readonly isProductionBuild = inject(BUILD_TYPE_PRODUCTION);
    private readonly translocoService = inject(TranslocoService);
    private readonly store = inject(Store);
    private readonly dialog = inject(MatDialog);
    private readonly alertApiService = inject(AlertApiService);
    private readonly router = inject(Router);
    private readonly platform = inject(Platform);
    private readonly userPositionMarkerService = inject(UserPositionMarkerService);
    private readonly errorHandlingService = inject(ErrorHandlingService);

    private readonly flightCategoriesError$ = this.store.select(DroneTowerMobileState.capabilitiesError);
    private readonly genericApiError$ = this.store.select(DroneTowerMobileState.genericApiError);
    protected readonly tilesetStyle$ = this.store.select(DroneTowerMobileState.tilesetStyle);
    protected readonly activeCheckins$ = this.store.select(DroneTowerMobileState.activeCheckins);
    protected readonly userCheckin$ = this.store.select(DroneTowerMobileState.userCheckin);
    protected readonly mapRedirectPosition$ = this.store.select(DroneTowerMobileState.mapRedirectPosition);
    protected readonly areOtherCheckinsVisible$ = this.store.select(DroneTowerMobileState.areOtherCheckinsVisible);
    protected readonly areActiveMissionAreasVisible$ = this.store.select(DroneTowerMobileState.areActiveMissionAreasVisible);
    protected readonly isCheckInOpen$ = this.localStore.selectByKey("isCheckInOpen").pipe(
        tap((isCheckInOpen) => {
            if (isCheckInOpen || this.router.url.includes(IS_CHECK_IN_OPEN_KEY)) {
                this.router.navigate([], {
                    queryParams: { [IS_CHECK_IN_OPEN_KEY]: isCheckInOpen ? true : undefined },
                    queryParamsHandling: "merge",
                });
            }
        })
    );
    protected readonly isOnGPSPosition$ = this.localStore.selectByKey("isOnGPSPosition");
    protected readonly missionGeometry$ = this.store
        .select(DroneTowerMobileState.selectedCheckinMission)
        .pipe(map((mission) => mission?.missionZones));
    protected readonly geoserverLayerConfig$ = combineLatest([
        this.store.select(DroneTowerMobileState.flightStatusParams),
        this.store.select(DroneTowerMobileState.flightStatusElevation),
        this.store.select(DroneTowerMobileState.capabilities).pipe(
            map((capabilities) => capabilities.geoserverUrl),
            RxjsUtils.filterFalsy()
        ),
        this.alertApiService.alertEvents$.pipe(startWith(null)),
    ]).pipe(
        map(([flightStatusParams, flightStatusElevation, geoserverUrl]) =>
            this.createGeolayerConfig(flightStatusParams, flightStatusElevation, geoserverUrl)
        )
    );
    protected readonly isCreateCheckinLoading$ = this.store.select(DroneTowerMobileState.isCreateCheckinLoading);
    protected readonly isAirspaceFeatureAvailable$ = this.store.select(
        DroneTowerMobileState.isFeatureAvailable(DroneTowerFeatures.Airspace)
    );
    protected readonly mapOptions: MapOptions;

    constructor(
        private readonly localStore: LocalComponentStore<MapComponentState>,
        private readonly mapService: MapService,
        @Inject(LEAFLET_MAP_CONFIG) leafletConfig: LeafletMapConfig
    ) {
        this.platform.backButton.pipe(untilDestroyed(this)).subscribe(() => this.localStore.patchState({ isCheckInOpen: false }));
        this.localStore.setState({
            isCheckInOpen: false,
            isOnGPSPosition: false,
        });
        this.mapOptions = {
            center: leafletConfig.defaultPosition,
            zoom: leafletConfig.zoom.initial,
            minZoom: leafletConfig.zoom.min,
            maxZoom: leafletConfig.zoom.max,
            zoomControl: false,
            preferCanvas: true,
        };

        this.mapRedirectPosition$.pipe(RxjsUtils.filterFalsy(), untilDestroyed(this)).subscribe(() => this.closeCheckinPanel());
    }

    public ngOnInit() {
        this.flightCategoriesError$.pipe(RxjsUtils.filterFalsy(), first(), untilDestroyed(this)).subscribe((httpErrorResponse) => {
            const errorMessage = this.translocoService.translate("droneTowerMobile.flightCategoriesErrorMessage");
            this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
        });
        this.genericApiError$.pipe(RxjsUtils.filterFalsy(), untilDestroyed(this)).subscribe((httpErrorResponse) => {
            const errorMessage = this.translocoService.translate("droneTowerMobile.genericErrorMessage");
            this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
        });

        this.store
            .dispatch(new DroneTowerMobileActions.GetPilotProfilePhoneNumber())
            .pipe(
                first(),
                switchMap(() => this.store.select(DroneTowerMobileState.getPilotDataError)),
                RxjsUtils.filterFalsy(),
                untilDestroyed(this)
            )
            .subscribe((httpErrorResponse) => {
                const isPilotDataFeatureAvailable = this.store.selectSnapshot(
                    DroneTowerMobileState.isFeatureAvailable(DroneTowerFeatures.PilotData)
                );
                if (isPilotDataFeatureAvailable && httpErrorResponse.error?.message === ERROR_MESSAGE_PILOT_DATA_INCOMPLETE) {
                    this.router.navigate(["/pilot-data"], { replaceUrl: true });

                    return;
                }

                const errorMessage = this.translocoService.translate("droneTowerMobile.userDataErrorMessage");
                this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
            });
        this.store.dispatch(new DroneTowerMobileActions.ActiveCheckinsWatch());
    }

    protected onMarkerChange(markerPosition: MarkerPosition | null) {
        this.localStore.patchState({ isOnGPSPosition: false });

        if (markerPosition) {
            this.store.dispatch(new DroneTowerMobileActions.SetMarkerPosition(markerPosition));

            return;
        }

        this.store.dispatch(new DroneTowerMobileActions.RemoveMarkerPosition());
    }

    protected closeCheckinPanel() {
        this.localStore.patchState({ isCheckInOpen: false });
    }

    protected menuOptionActionClickedHandler(action: FloatingMenuActionName) {
        if (action === FloatingMenuActionName.Checkin) {
            this.localStore.patchState({ isCheckInOpen: true });

            return;
        }

        const userCheckin = this.store.selectSnapshot(DroneTowerMobileState.userCheckin);
        if (userCheckin) {
            const dialogRef = this.dialog.open(CheckinDialogComponent, {
                data:
                    action === FloatingMenuActionName.LostControl
                        ? CheckinDialogOptions.LostControl
                        : userCheckin.properties.status === ActiveCheckinStatus.LostControl
                        ? CheckinDialogOptions.FinishLostControl
                        : "",
                maxWidth: "95vw",
                disableClose: true,
                autoFocus: false,
            });

            dialogRef
                .afterClosed()
                .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
                .subscribe((data) => {
                    if (data === true) {
                        this.store.dispatch(DroneTowerMobileActions.StopCheckin);

                        return;
                    }

                    this.store.dispatch(new DroneTowerMobileActions.CheckinLostControl(data));
                });
        }
    }

    private createGeolayerConfig(
        flightStatusParams: FlightStatusParams,
        flightStatusElevation: MinMaxRange | null,
        geoserverEndpoint: string
    ): LeafletMapLayerConfig {
        const { startDate, endDate } = convertFlightStatusParamsTimeToDateRange(flightStatusParams);
        const isoStartTime = startDate.toISOString();
        const isoEndTime = endDate.toISOString();
        const flightHeightWithMaxElevation =
            flightStatusElevation?.max !== undefined ? flightStatusParams.flightHeight + flightStatusElevation.max : 0;
        // TODO: DTOWER-610 Revert to bff with auth geoserver call
        // const accessToken = this.store.selectSnapshot(AuthState.accessToken);
        const areBvlosMissionsVisible = flightStatusParams.areBvlosMissionsVisible;

        return {
            type: "WMS",
            baseUrl: geoserverEndpoint,
            options: {
                layers: "drone-tower",
                format: "image/png",
                transparent: true,
                CQL_FILTER: `((end_time >= ${isoStartTime} and end_time <= ${isoEndTime}) or
                    (start_time >= ${isoStartTime} and start_time <= ${isoEndTime}) or
                    (start_time < ${isoStartTime} and end_time > ${isoEndTime})) and
                    lower_limit < ${flightHeightWithMaxElevation}
                    ${areBvlosMissionsVisible ? "" : "and type <> 'BVLOS'"}`,
            },
            // TODO: DTOWER-610 Revert to bff with auth geoserver call
            // authorizationHeader: this.isProductionBuild ? `Bearer ${accessToken}` : "",
        };
    }

    protected goToGPSPosition() {
        const userCheckin = this.store.selectSnapshot(DroneTowerMobileState.userCheckin);

        if (userCheckin) {
            const coordinates = [...userCheckin.geometry.coordinates].reverse() as LatLngTuple;
            this.userPositionMarkerService.centerMap(this.mapService.mapInstance, coordinates);
        } else {
            this.localStore.patchState({ isOnGPSPosition: true });
        }
    }
}
