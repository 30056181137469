<ng-container *ngrxLet="isActionDisabled$ as isActionDisabled">
    <div class="card">
        <div class="card-content scroll-shadows" (scroll)="enableActionButtonWhenContainerBottomReached($event)">
            <h2 class="title">{{ title$ | ngrxPush }}</h2>
            <ng-content></ng-content>
        </div>

        <div class="card-actions">
            <button type="button" (click)="this.action.emit()" class="button-primary" [disabled]="isActionDisabled">
                {{ actionLabel$ | ngrxPush }}<dtm-ui-icon name="arrow-go"></dtm-ui-icon>
            </button>
        </div>
    </div>
</ng-container>
