<div class="container" *ngrxLet="{ isProcessing: isProcessing$, currentStep: currentStep$, code: code$ } as vm">
    <img class="logo" src="assets/images/logo.svg" alt="logo" />
    <div class="form-container" *ngIf="vm.currentStep === ResetPasswordSteps.Email">
        <h2 class="form-container-item title">{{ "droneTowerMobileLibLogin.resetPassword.title" | transloco }}</h2>
        <p class="form-container-item">{{ "droneTowerMobileLibLogin.resetPassword.description" | transloco }}</p>
        <dtm-ui-input-field class="form-container-item">
            <label>{{ "droneTowerMobileLibLogin.emailLabel" | transloco }}</label>
            <input [formControl]="emailFormControl" matInput type="email" autocomplete="username" />
            <div class="field-error" *dtmUiFieldHasError="emailFormControl; name: 'requiredTouched'">
                {{ "droneTowerMobileLibLogin.requiredErrorMessage" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="emailFormControl; name: 'email'">
                {{ "droneTowerMobileLibLogin.emailErrorMessage" | transloco }}
            </div>
        </dtm-ui-input-field>
        <div class="form-container-item">
            <button type="button" class="button-primary" [disabled]="vm.isProcessing" (click)="onSubmit()">
                <mat-spinner *ngIf="vm.isProcessing" [diameter]="20" color="accent"></mat-spinner>
                <ng-container *ngIf="!vm.isProcessing">
                    {{ "droneTowerMobileLibLogin.resetPassword.sendCode" | transloco }}
                </ng-container>
            </button>
        </div>
        <p class="form-container-item redirect">
            <a routerLink="/login">
                {{ "droneTowerMobileLibLogin.resetPassword.backToLogin" | transloco }}
            </a>
        </p>
    </div>
    <drone-tower-mobile-lib-reset-password-email-verification
        [email]="emailFormControl.value"
        *ngIf="vm.currentStep === ResetPasswordSteps.EmailVerification"
        (validate)="saveValidationCode($event)"
    ></drone-tower-mobile-lib-reset-password-email-verification>
    <drone-tower-mobile-lib-reset-password-confirm
        [code]="vm.code"
        *ngIf="vm.currentStep === ResetPasswordSteps.ConfirmPassword"
    ></drone-tower-mobile-lib-reset-password-confirm>
</div>
